export const calendarStart = () => ({type: 'CALENDAR_START'});
export const calendarClear = () => ({type: 'CALENDAR_CLEAR'});
export const calendarSuccess = (itens) => ({type: 'CALENDAR_SUCCESS',itens});
export const calendarFail = () => ({type: 'CALENDAR_FAIL'});
export const searchCalls = (data) => ({type: 'SEARCH_CALLS',data});
export const destroyCall = (data) => ({type: 'DESTROY_CALL',data});
export const setItensCallArray  = (data) => ({type: 'CALENDAR_SET_ITENS_ARRAY',data});
export const updateCall  = (data,location) => ({type: 'UPDATE_CALL',data,location});
export const markoffCall  = (data) => ({type: 'MARKOFF_CALL',data});
export const storeCall  = (data,location) => ({type: 'STORE_CALL',data,location});
export const multiplesDestroy = (calls,user,dayWeek,professional,treatment,url) =>  ({type:'DESTROY_CALL_MULTIPLES',calls,user,dayWeek,professional,treatment,url});
export const removeCallMult = (calls) => ({type:'CALENDAR_DESTROY_MULT',calls});
export const createID = (item) => ({type:'CALENDAR_CREATEID',item});
export const getCallsStartEnd = (data) => ({type:'GET_CALLS_START_END',data});
export const getCalendarDashboardCallsDay = (itens) => ({type:'CALENDAR_DASHBOARD_LIST_CALLSDAY',itens});
export const calendarDashboardCallsDayLoading = (status) => ({type:'CALENDAR_DASHBOARD_LIST_CALLSDAY_LOADING',status});
export const getCallsStatus = (call) => ({type:'CALENDAR_GET_CALL_STATUS',call})
export const calendarForPay = (data) => ({type:'CALENDAR_FOR_PAY',data})
export const calendarForPayLoading = (status) => ({type:'CALENDAR_FOR_PAY_LOADING',status});
export const getCalendarForPay = (data) => ({type:'GET_CALLS_FOR_PAY',data});
export const setProfessionalSelected  = (data) => ({type: 'CALENDAR_PROFESSIONAL_SELECTED',data});
export const setPatientSelected  = (data) => ({type: 'CALENDAR_PATIENT_SELECTED',data});
export const setStartDate = (startDate) => ({type:'SET_START_DATE', startDate});
export const setEndDate = (endDate) => ({type:'SET_END_DATE', endDate});
export const setProfessional = (professional) => ({type:'SET_PROFESSIONAL',professional});
export const setPatient = (patient) => ({type:'SET_PATIENT', patient});
export const setStatuss = (status) => ({type:'SET_STATUSS', status});


/**
 * Novo sistema
 */

export const pendenciesLoading = (status) => ({type:'PENDENCIES_LOADING',status});
export const pendenciesSuccess = (items,lastPage) => ({type:'PENDENCIES_SUCCESS',items,lastPage});
export const alterPendencies = (data) => ({type: 'ALTER_PENDENCIES', data});
export const destroyPendencies = (data) => ({type: 'DESTROY_PENDENCIES', data});
export const clearPendencies = () => ({type: 'CLEAR_PENDENCIES'});

export const pendenciesTherapeuticLoading = (status) => ({type:'PENDENCIES_THERAPEUTIC_LOADING',status});
export const pendenciesTherapeuticSuccess = (items,lastPage) => ({type:'PENDENCIES_THERAPEUTIC_SUCCESS',items,lastPage});
export const clearTherapeuticPendencies = () => ({type: 'CLEAR_PENDENCIES_THERAPEUTIC'});
export const alterPendenciesTherapeutic = (data) => ({type: 'ALTER_PENDENCIES_THERAPEUTIC', data});
export const alterPendenciesCallsNotSigned = (data) => ({type: 'ALTER_PENDENCIES_CALLS_NOT_SIGNED', data});
export const destroyPendenciesTherapeutic = (data) => ({type: 'DESTROY_PENDENCIES_THERAPEUTIC', data});

export const calendarAlterHome = (data) => ({type: 'CALENDAR_ALTER_HOME', data});
export const calendarAlterSchedule = (data) => ({type: 'CALENDAR_ALTER_SCHEDULE', data});


export const calendarStoreSchedule = (data) => ({type: 'CALENDAR_STORE_SCHEDULE', data});
export const calendarStoreHome = (data) => ({type: 'CALENDAR_STORE_HOME', data});

export const calendarDestroySchedule = (data) => ({type: 'CALENDAR_DESTROY_SCHEDULE', data});
export const calendarDestroyHome = (data) => ({type: 'CALENDAR_DESTROY_HOME', data});


export const destroyItem  = (uuid) => ({type: 'CALENDAR_DESTROY_ITEM_ID',uuid});

export const setItem  = (item,uuid) => ({type: 'CALENDAR_SET_ITEM',item,uuid});

export const newUpdateStatus = (data) => ({type:'NEW_UPDATE_STATUS',data});
export const reschedule = (data) => ({type:'RESCHEDULE',data});
export const calendarLoadingCreate = (status) => ({type:'CALENDAR_CREATE',status});
export const getCountPendencies = (data) => ({type:'GET_COUNT_PENDENCIES',data});
export const setCountPendencies = (data) => ({type:'SET_COUNT_PENDENCIES',data});
export const countPendenciesLoading = (status) => ({type:'COUNT_PENDENCIES_LOADING',status});


export const getCountPlanTherapeutic = (data) => ({type:'GET_COUNT_PLAN_THERAPEUTIC',data});
export const setCountPlanTherapeutic = (data) => ({type:'SET_COUNT_PLAN_THERAPEUTIC',data});
export const countPlanTherapeuticLoading = (status) => ({type:'COUNT_PLAN_THERAPEUTIC_LOADING',status});

export const getCountCallsNotSigned = (data) => ({type:'GET_COUNT_CALLS_NOT_SIGNED',data});
export const setCountCallsNotSigned = (data) => ({type:'SET_COUNT_CALLS_NOT_SIGNED',data});
export const countCallsNotSignedLoading = (status) => ({type:'COUNT_CALLS_NOT_SIGNED_LOADING',status});

export const callsNotSignedLoading = (status) => ({type:'CALLS_NOT_SIGNED_LOADING',status});
export const callsNotSignedSuccess = (items,lastPage) => ({type:'CALLS_NOT_SIGNED_SUCCESS',items,lastPage});
