import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {DateTime} from "luxon";
import {
    calcularMoney,
    formatDateNotHour,
    formatHour,
    getName
} from '../../utils';
import api from "../../api";
import {Avatar} from "../Avatar";
import InvoiceBadge from "../finance/InvoiceBadge";
import pdf from "../../assets/img/pdf.png";
import xml from "../../assets/img/xml.png";
import toast from "react-hot-toast";
import Confirm from "../Confirm";


function ListCall ({value,setCall,setOpen}){

    return(
        <tr >
            <td style={{ textAlign: "left" }}><span>{value.professional?.name}</span></td>
            <td style={{ textAlign: "left" }}><span>{value.company_specialization?.specialization?.profession && value.company_specialization?.specialization?.profession?.description} {value.company_specialization?.specialization?.description}</span></td>
            <td style={{ textAlign: "left" }}>
                <span>{formatDateNotHour(DateTime.fromSQL(value.start_date))} {formatHour(DateTime.fromSQL(value.start_date))} - {formatHour(DateTime.fromSQL(value.end_date))}</span>
            </td>
            <td style={{ textAlign: "left" }}>
                <button className="btn btn-link btn-sm" style={{width:50}} onClick={() => {setCall(value);setOpen(true)}}>
                    <span class="svg-icon svg-icon-danger ">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M19.5997 3.52344H2.39639C2.09618 3.53047 1.8003 3.59658 1.52565 3.718C1.25101 3.83941 1.00298 4.01375 0.79573 4.23106C0.588484 4.44837 0.426087 4.70438 0.317815 4.98447C0.209544 5.26456 0.157521 5.56324 0.164719 5.86344C0.157521 6.16364 0.209544 6.46232 0.317815 6.74241C0.426087 7.0225 0.588484 7.27851 0.79573 7.49581C1.00298 7.71312 1.25101 7.88746 1.52565 8.00888C1.8003 8.1303 2.09618 8.19641 2.39639 8.20344H19.5997C19.8999 8.19641 20.1958 8.1303 20.4704 8.00888C20.7451 7.88746 20.9931 7.71312 21.2004 7.49581C21.4076 7.27851 21.57 7.0225 21.6783 6.74241C21.7866 6.46232 21.8386 6.16364 21.8314 5.86344C21.8386 5.56324 21.7866 5.26456 21.6783 4.98447C21.57 4.70438 21.4076 4.44837 21.2004 4.23106C20.9931 4.01375 20.7451 3.83941 20.4704 3.718C20.1958 3.59658 19.8999 3.53047 19.5997 3.52344Z" fill="currentColor" fill-opacity="0.8"/>
                            <path d="M2.39453 8.20361L4.01953 18.3111C4.15644 19.145 4.58173 19.9043 5.22121 20.4567C5.8607 21.009 6.6738 21.3194 7.5187 21.3336H14.5712C15.4215 21.3202 16.2395 21.006 16.8801 20.4468C17.5207 19.8875 17.9424 19.1193 18.0704 18.2786L19.5979 8.20361H2.39453ZM9.28453 16.3178C9.28453 16.5333 9.19893 16.7399 9.04656 16.8923C8.89418 17.0447 8.68752 17.1303 8.47203 17.1303C8.25654 17.1303 8.04988 17.0447 7.89751 16.8923C7.74513 16.7399 7.65953 16.5333 7.65953 16.3178V12.4069C7.65953 12.1915 7.74513 11.9848 7.89751 11.8324C8.04988 11.68 8.25654 11.5944 8.47203 11.5944C8.68752 11.5944 8.89418 11.68 9.04656 11.8324C9.19893 11.9848 9.28453 12.1915 9.28453 12.4069V16.3178ZM14.322 16.3178C14.322 16.5333 14.2364 16.7399 14.0841 16.8923C13.9317 17.0447 13.725 17.1303 13.5095 17.1303C13.294 17.1303 13.0874 17.0447 12.935 16.8923C12.7826 16.7399 12.697 16.5333 12.697 16.3178V12.4069C12.697 12.1915 12.7826 11.9848 12.935 11.8324C13.0874 11.68 13.294 11.5944 13.5095 11.5944C13.725 11.5944 13.9317 11.68 14.0841 11.8324C14.2364 11.9848 14.322 12.1915 14.322 12.4069V16.3178Z" fill="currentColor" fill-opacity="0.8"/>
                            <path d="M17.3895 4.87755C17.2529 4.87776 17.1185 4.84303 16.999 4.77667C16.8796 4.71031 16.7791 4.61452 16.707 4.49839L14.5945 1.24839C14.488 1.07063 14.4544 0.858502 14.5009 0.656521C14.5473 0.45454 14.6702 0.2784 14.8437 0.165055C15.0215 0.0626479 15.2311 0.0303209 15.4315 0.0744071C15.6319 0.118493 15.8086 0.235816 15.927 0.403388L18.0395 3.70755C18.1434 3.88599 18.1755 4.09728 18.1292 4.2985C18.0829 4.49972 17.9618 4.67577 17.7904 4.79089C17.6659 4.85225 17.5282 4.88202 17.3895 4.87755Z" fill="currentColor" fill-opacity="0.8"/>
                            <path d="M4.49988 4.8885C4.34679 4.8928 4.19591 4.85131 4.06655 4.76933C3.89514 4.65422 3.77399 4.47817 3.72771 4.27694C3.68143 4.07572 3.71349 3.86443 3.81738 3.686L5.98405 0.435999C6.09739 0.262485 6.27353 0.13961 6.47551 0.0931545C6.6775 0.0466989 6.88962 0.0802727 7.06738 0.186832C7.23676 0.303623 7.35627 0.479597 7.40239 0.680101C7.4485 0.880606 7.41788 1.09111 7.31655 1.27017L5.20405 4.52017C5.12881 4.63747 5.0243 4.73313 4.90082 4.79773C4.77733 4.86232 4.63914 4.8936 4.49988 4.8885Z" fill="currentColor" fill-opacity="0.8"/>
                        </svg>
                    </span>
                </button>
            </td>
        </tr>
    )
}

function FinanceShowModal({data}) {
    const [calls,setCalls] = useState([]);
    const [call,setCall] = useState({})
    const [open,setOpen] = useState(false)

    useEffect(() => {
        setCalls(data.calls);
    },[data.calls])

    const invoices = data.finance_document?.filter(value => value.status !== 12);
    const invoice = invoices?.length > 0 ?  invoices[invoices?.length - 1] : {};

    const token = useSelector(state => state.auth.token);
    const destroy = (id) => {
        let response = api.delete('/financeCall/'+id, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });

        toast.promise(response,{
            loading: 'Desvinculando atendimento.',
            success: 'O atendimento foi desvinculado com sucesso.'
        }).then((response) => {
            setCalls(data.calls.filter(value => value.id !== id))
        })
    }

    return (
        <div className="d-flex align-items-center mb-6 table-row-dashed" style={{minWidth:400}}>
            <div className="flex-grow-1">
                <div className="text-black-900 fs-5 fw-bold text-bold " style={{display:"flex",fontSize:11}}>
                    <div style={{flex:1}}>
                        {
                            data.type_model === 1 ?
                                <>
                                    {
                                        data.user && data.user.type !== 1  ?
                                            <div
                                                style={{textAlign: 'left',display:"flex",gap:5,alignItems: "flex-start",}}
                                            >
                                                <Avatar w="w-60px" h="h-60px" row={data.user}  /> <h4>{data.user ? data.user.name : ''}</h4>
                                            </div>
                                            :
                                            <>{data.user ? data.user.name : ''}</>
                                    }
                                </>
                                :
                                <h3 style={{marginTop:8,fontWeight:500}}>
                                    {
                                        data.company_user?.email && (data.company_user?.cpf || data.company_user?.cnpj) &&
                                        <span
                                            className="svg-icon svg-icon-success svg-icon-1hx"
                                            style={{marginRight: 10}} title={"Cadastro completo"}
                                        >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.3"
                                                d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                    }
                                    {data.permission === 'pay' ? data.user ? data.user.name : '' : data.company_user ? data.company_user.description : ''}
                                </h3>
                        }
                    </div>
                    <div style={{width:35}}>

                    </div>
                </div>
                <h4 style={{ fontWeight:"400",marginTop:20}}>Data de vencimento</h4>
                <hr style={{ border: 'none', borderTop: '1px solid #aaa', marginTop: '0' }} />
                <div className="text-gray-400 fw-semibold">
                    {formatDateNotHour(DateTime.fromSQL(data.due_date))}
                </div>

                {data.description && <div className="text-gray-400 fw-semibold fs-6"style={{maxWidth:300,textAlign:"left",marginTop:10, marginBottom:10,whiteSpace: "pre-wrap"}}>
                    <h4 style={{ fontWeight:"400",marginTop:20}}>Descrição</h4>
                    <hr style={{ border: 'none', borderTop: '1px solid #aaa', marginTop: '0' }} />
                    {data.description}
                </div>}
                <h4 style={{ fontWeight:"400",marginTop:20}}>Informações</h4>
                <hr style={{ border: 'none', borderTop: '1px solid #aaa', marginTop: '0' }} />
                {
                    data.type_payment ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{maxWidth:300,textAlign:"justify",marginTop:10, marginBottom:5}}>
                            <div className="badge badge-light-primary"  style={{height:22}}>
                                {data.type_payment?.description}
                            </div>
                        </div>
                        :
                        <></>
                }
                {
                    data.company_bank_account ?
                        <div className="text-gray-700 fw-semibold fs-6"style={{maxWidth:300,textAlign:"justify",marginTop:5, marginBottom:5}}>
                            <div style={{display:"flex",flexDirection:"column",gap:5,alignItems: "flex-start"}}>
                                <div className="badge badge-primary"  style={{height:22}} title={"Agência: "+data.company_bank_account?.agency+ ' Conta: '+data.company_bank_account?.account}>
                                    {data.company_bank_account?.bank?.bank}
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

                <InvoiceBadge value={data} />
                {
                    data.nota === 1 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:10, marginBottom:5}}>
                            <div className="badge badge-info" >
                                Nota solicitada
                            </div>
                        </div>
                        :

                        <></>
                }
                {
                    data.nota === 2 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-info" >
                                Nota anexada
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    data.nota === 3 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-success" >
                                Nota faturada
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    data && data.documents && data.documents.id ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-primary" >
                                Documentos anexados
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    data?.task > 0 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-light-dark" >
                                Receita recorrente
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    data?.group > 0 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-light-dark" >
                                Receita parcelada
                            </div>
                        </div>
                        :
                        <></>
                }

                { data.type_model === 1 && data.user?.status === 0 &&
                    <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                        <div className="badge badge-danger" >
                            Paciente inativo
                        </div>
                    </div>
                }

                { data.type_model === 2 && data.company_user?.status === 0 &&
                    <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:10}}>
                        <div className="badge badge-danger" >
                            Clínica desativada
                        </div>
                    </div>
                }

                {
                    data.responsible?.id  && data.permission === 'receive' ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{maxWidth:300,textAlign:"justify",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-light-dark">
                                Recebeu de {getName(data.responsible?.responsible.name)}
                            </div>
                        </div>
                        :
                        <></>
                }

                <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:10}}>
                    <div className="badge badge-light-dark" style={{textAlign:"left",background:data.status.color,color:'#fff'}}>
                        {data.status.description}
                        {data.payment_date ? <span> em {formatDateNotHour(DateTime.fromSQL(data.payment_date))}</span> : <></> }
                    </div>
                </div>
                <h4 style={{ fontWeight:"400",marginTop:20}}>Valor</h4>
                <hr style={{ border: 'none', borderTop: '1px solid #aaa', marginTop: '0' }} />
                <div className="text-gray-700 fw-semibold fs-3">
                    {
                        calcularMoney(data.total).
                        toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                        })
                    }
                </div>
                {
                    invoice.url_pdf ?
                        <>
                            <br/>
                            <h4 style={{ fontWeight:"400",marginTop:20}}>Nota fiscal</h4>
                            <hr style={{ border: 'none', borderTop: '1px solid #aaa', marginTop: '0' }} />
                            <div style={{display:"flex",justifyContent:"space-around"}}>
                                {invoice.url_pdf ? <a href={invoice.url_pdf} target="_new"><img src={pdf} style={{maxWidth: 40}} alt={""}/></a> : <></>}
                                {invoice.url_xml ? <a href={invoice.url_xml} target="_new"><img src={xml} style={{maxWidth: 40}} alt={""}/></a> : <></>}

                            </div>

                        </>
                        :
                        <></>
                }
                <br/>
                {
                    data.calls?.length > 0 &&
                    <>
                        <h4 style={{ fontWeight:"400",marginTop:20}}>Atendimentos vinculados</h4>
                        <hr style={{ border: 'none', borderTop: '1px solid #aaa', marginTop: '0' }} />
                        <div className="table-responsive">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                    <td>Profissional</td>
                                    <td>Tratamento</td>
                                    <td>Data</td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>
                                    {calls?.map((value,index) => <ListCall  key={index} value={value} setCall={setCall} setOpen={setOpen} />)}
                                </tbody>
                            </table>
                        </div>
                        <Confirm textNotification={"Ao desvincular o atendimento da receita, o valor da receita não será atualizado."} typeNotification="danger" title="Deseja desvincular o atendimento?" open={open} onClick={() => destroy(call.id)} closeModal={() => setOpen(false)} />
                    </>
                }
            </div>
        </div>
    );
}

export default FinanceShowModal
