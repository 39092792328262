import  React,{ useState,useEffect} from 'react';
import { connect } from 'react-redux';
import Finance from '../../components/finance/Finance';
import {destroyFinance, destroyFinanceTask, getFinance, getTypePayment} from '../../store/actions/financeActions';
import {closeAny, openAny, openModal} from '../../store/actions/uiActions';
import {DateTime} from "luxon";

function PayController({auth,get,items,loading,openModal,destroyFinance,name,title,destroyFinanceTask,getTypePayment,typePayments,typeModel,isMobile,layoutRef,setHeaderNavigation,openAny,closeAny}) {

	const [dateStart,setDateStart] = useState(DateTime.local().startOf('month').toFormat('yyyy-MM-dd'));
    const [dateEnd,setDateEnd] = useState(DateTime.local().endOf('month').toFormat('yyyy-MM-dd'));
	const [typeDate,setTypeDate] = useState(1);
	const [typePayment, setTypePayment] = useState(0);
	const [bankAccountId, setBankAccountId] = useState(0);

	useEffect(() =>{
		getTypePayment(2);
        get(2,dateStart,dateEnd,null,typeDate,typePayment,typeModel,0);

    },[]);

	const start = (data) =>{
		closeAny()
        get(2,data.dateStart,data.dateEnd,null,data.typeDate,data.typePayment,typeModel,0,data.bankAccountId);

    }

	const download = () =>{
		get(2,dateStart,dateEnd,null,typeDate,typePayment,typeModel,0);
	}

    return (
		<Finance
			setHeaderNavigation={setHeaderNavigation}
			layoutRef={layoutRef}
			isMobile={isMobile}
			download={download}
			typePayments={typePayments}
			typePayment={typePayment}
			setTypePayment={setTypePayment}
			typeDate={typeDate}
			setTypeDate={setTypeDate}
			start={start} 
			dateStart={dateStart} 
			setDateStart={setDateStart} 
			dateEnd={dateEnd} 
			setDateEnd={setDateEnd} 
			name={name}  
			destroy={destroyFinance} 
			items={items} 
			loading={loading} 
			auth={auth} 
			title={title ? title : "Contas a pagar"} 
			openModal={openModal} 
			permission="pay"
			destroyFinanceTask={destroyFinanceTask}
			typeModel={typeModel}
			openAny={openAny}
			setBankAccountId={setBankAccountId}
			bankAccountId={bankAccountId}
		/>);
}

const mapStateToProps = state => ({
    auth: state.auth,
    items: state.finance.pay,
    loading: state.finance.loading,
	typePayments:state.finance?.typePaymentsPay
}); 

const mapDispatchToProps = dispatch => ({
	get:(type,dateStart,dateEnd,patient,typeDate,typePayment,typeModel,nota,bankAccountId) => dispatch(getFinance(type,dateStart,dateEnd,patient,typeDate,typePayment,typeModel,nota,bankAccountId)),
    openModal:(modal,payload) => dispatch(openModal(modal,payload)),
    destroyFinance:(data) => dispatch(destroyFinance(data)),
	destroyFinanceTask:(data) => dispatch(destroyFinanceTask(data)),
	getTypePayment:(type) => dispatch(getTypePayment(type)),
	openAny:(children,title,size) => dispatch(openAny(children,title,size)),
	closeAny:() => dispatch(closeAny())
});

export default connect(mapStateToProps,mapDispatchToProps)(PayController);