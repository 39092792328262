import {Field} from "../../components/Field";
import React, {useEffect, useState} from "react";
import api from "../../api";
import {debounce} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {getName} from "../../utils";

export default function SearchList({setRoom,room,setProfessional,setPatient,id,onSubmit,dayWeekItem,handleChangeDaWeek,professionais,status,setReplacement,replacement,professionalItem,handleChangeProfessional,treatmentItem,handleChangeSpecialization,setStartDate,startDate,endDate,setEndDate,statusCalendar,setStatuss,typeschedules,setSchedules,schedules,treatment,setTreatmentOption,treatmentOption}){

    const isBigScreen = useMediaQuery({ query: "(min-width: 1200px)" });
    const [open, setOpen] = useState(isBigScreen);

    const dispatch = useDispatch();
    const rooms = useSelector(state => state.companies.rooms);

    useEffect(() => {
        dispatch({type:'GET_ROOMS'})
    }, []);


    let token = useSelector(state => state.auth.token);

    const handleInputChangeProfessional = debounce(( (value, callback) => {
        api.post(`/user/search/type/1?page=1&pageSize=10`,{ name: value }, {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }).then((response) => {
            const options = response.data.data.map((result) => ({
                value: result.id,
                label: result.name,
            }));
            callback(options);
        });
    }), 1000);

    const handleInputChangePatient = debounce(( (value, callback) => {
        api.post(`/user/search/type/3?page=1&pageSize=10`,{ name: value }, {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }).then((response) => {
            const options = response.data.data.map((result) => ({
                value: result.id,
                label: result.name,
            }));
            callback(options);
        });
    }), 1000);

    let professionalOptions = [];
    let specalization = [];
    let specalizationOptions = [];

    if (professionais && professionais.length > 0) {

        let professionalArray = professionais.filter((pri, i) => {
            return !professionais.some(
                (pri2, j) => j > i && pri.professional.id === pri2.professional.id,
            );
        });

        professionalOptions.push({label: "Todos", value: 0});
        professionalArray.forEach(element => {
            professionalOptions.push({label: getName(element.professional.name), value: element.professional.id});
        });

        specalization = professionais.filter((pri, i) => {
            return !professionais.some(
                (pri2, j) => j > i && pri.company_specialization.specialization.id === pri2.company_specialization.specialization.id
            );
        });

        specalizationOptions.push({label: 'Todos', value: 0})
        specalization.forEach(element => {

            specalizationOptions.push({
                label: (element.company_specialization.specialization.profession && element.company_specialization.specialization.profession.description) + " " + (element.company_specialization.specialization.description ? element.company_specialization.specialization.description : ''),
                value: element.company_specialization.id
            })
        });


    }

    let itenss = [];

    itenss = status.filter(function (element) {
        return element.lack !== 2;
    });

    let arrayStatus = [];
    itenss.forEach((data) => {
        arrayStatus.push({"label": data.description, "value": data.id});
    });

    const treatmentOptions = [];

    if (treatment && treatment.length > 0) {
        treatment.filter(spe => spe.status === 1).forEach(element => {
            treatmentOptions.push({
                label: ((element && element.specialization.profession) ? element.specialization.profession.description : '') + " " + (element.specialization.description ? element.specialization.description : '') + " (" + element.time + " minutos)",
                value: element.id
            })
        });
    }

    return (
        <div className="card" style={{position: "sticky", top: 20, zIndex: 1}}>
            <div className="card-header" style={{minHeight: 15}}>
                <div className="card-title" style={{width: '100%'}}>
                    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
                        <div style={{flex: 1}}>
                            Filtro
                        </div>
                        <div>
                            {
                                !isBigScreen ?
                                    <button className="btn btn-sm btn-secondary" style={{width: 35, height: 25, padding: 0}} onClick={() => setOpen(!open)}>
                                        {
                                            open ?
                                                <span className="svg-icon svg-icon-3" style={{margin: 0}}><svg
                                                    width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"> <path
                                                    d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                                    fill="currentColor"/></svg></span>
                                                :
                                                <span className="svg-icon svg-icon-3" style={{margin: 0}}><svg
                                                    width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"><path
                                                    d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                                    fill="currentColor"></path></svg></span>
                                        }
                                    </button>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body" style={{display: open ? "block" : 'none'}}>
                {
                    id ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="badge badge-light-primary" style={{width: '100%'}}>Lista de
                                    atendimentos baseada na <br/> pesquisa dos próximos 12 meses.
                                </div>
                            </div>
                            <div className='col-md-12' style={{marginTop: 20}}>
                                <label className="fw-bold text-muted">Dia da semana:</label>
                                <Field type="select" options={[{value: -1, label: 'Todos'}, {
                                    value: 0,
                                    label: 'Domingo'
                                }, {value: 1, label: 'Segunda-feira'}, {
                                    value: 2,
                                    label: 'Terça-feira'
                                }, {value: 3, label: 'Quarta-feira'}, {
                                    value: 4,
                                    label: 'Quinta-feira'
                                }, {value: 5, label: 'Sexta-feira'}, {value: 6, label: 'Sábado'}]}
                                       value={dayWeekItem} onChange={handleChangeDaWeek}/>
                            </div>
                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Profissional:</label>
                                <Field type="select" options={professionalOptions} value={professionalItem} onChange={handleChangeProfessional}/>
                            </div>
                            <div className='col-md-12' style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Tratamento:</label>
                                <Field type="select" options={specalizationOptions} value={treatmentItem} onChange={handleChangeSpecialization}/>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Profissional:</label>
                                <Field isMulti={true} type="selectAsync" key={2}
                                       onInputChange={handleInputChangeProfessional} onChange={setProfessional}/>
                            </div>
                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Paciente:</label><br/>
                                <Field isMulti={true} type="selectAsync" key={1}
                                       onInputChange={handleInputChangePatient} onChange={setPatient}/>
                            </div>
                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Início:</label>
                                <Field type="date" value={startDate}
                                       onChange={(value) => setStartDate(value + " 00:00:00")}/>
                            </div>
                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Fim</label>
                                <Field type="date" value={endDate}
                                       onChange={(value) => setEndDate(value + " 23:59:00")}/>
                            </div>
                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Status:</label>
                                <Field
                                    type="select-multi"
                                    options={arrayStatus}
                                    value={statusCalendar}
                                    onChange={setStatuss}
                                />
                            </div>
                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Tratamento:</label>
                                <Field
                                    type="select-multi"
                                    options={treatmentOptions}
                                    value={treatmentOption}
                                    onChange={setTreatmentOption}
                                />
                            </div>
                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Tipo de agendamento:</label>
                                <Field type="select" options={[{label: "Todos", value: 0}, ...typeschedules.filter(value => ![5,6,7].includes(value.value))]}
                                       value={schedules.length > 0 ? setSchedules[0] : 0} onChange={(value) => {
                                    setSchedules(value === 0 ? [] : [value])
                                }}/>
                            </div>

                            <div className="col-md-12" style={{marginTop: 5}}>
                                <label className="fw-bold text-muted">Sala:</label>
                                <Field type="select-multi" options={rooms} value={room}
                                       onChange={setRoom}/>
                            </div>
                            <div className="col-md-12" style={{marginTop: 8}}>
                                <Field type="checkbox" title={"Reposição"} value={replacement}
                                       onChange={setReplacement}/>
                            </div>

                            <div className="col-md-12" style={{marginTop: 22}}>
                                <button
                                    className="btn btn-success btn-sm btn-block"
                                    style={{width: "100%"}}
                                    type="button"
                                    onClick={() => onSubmit()}
                                >
                                    Aplicar
                                </button>
                            </div>
                        </div>
                }

                <div className="row">
                    <div className="col-md-12">
                        <label className="fw-bold" style={{marginTop: 20}}>Legenda:</label>
                        {
                            itenss.map((value, index) => {
                                return (
                                    <div style={{display: "flex", flexDirection: "row", paddingTop: 5}}
                                         key={index}>
                                        <div style={{
                                            backgroundColor: value.color,
                                            width: 15,
                                            height: 15,
                                            marginTop: 2,
                                            marginRight: 10,
                                            borderRadius: 50
                                        }}>

                                        </div>
                                        <div className="fw-bold text-muted">{value.description}</div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
