import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {openModal} from "../../store/actions/uiActions";
import ContentLoader from "react-content-loader";
import {getService,  updateService} from "../../store/actions/serviceActions";
import {calcularMoney, checkPermission} from "../../utils";

function ServiceList({value,openModal,updateService,auth}){
    const [novo,setNovo] = useState(false);

    useEffect(() => {
        function click(ev) {
            if(ev.srcElement.closest('#service') == null){
                setNovo(false);
            }
        }

        if(novo){
            window.addEventListener('click',click,false);
            return () => {
                window.removeEventListener('click',click,false);
            };
        }

    }, [novo]);

    return(
        <tr>
            <td style={{textAlign:'left'}}>{value.name}</td>
            <td style={{textAlign:'left'}}>{calcularMoney(value.total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
            <td style={{textAlign:'left'}}>{value.type_commission === 1 ? "Comissão fixa" : value.type_commission === 2 ? "Comissão por porcentagem" : ""}</td>
            <td style={{textAlign:'left'}}>{
                value.type_commission === 1 ?
                    calcularMoney(value.commisission_fixed).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                    :
                    calcularMoney((value.total*value.commisission_percentage)/100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+" ("+value.commisission_percentage+"%)"
            }</td>
            <td style={{textAlign:'left'}}>{value.status === 1 ? "Ativo" : "Inativo"}</td>

            <td>
                <button  className="btn btn-sm btn-icon  btn-active-color-primary" style={{float:'right'}} onClick={()=>setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
                </button>
                <div className={"dropdown-menu "+(novo ? "show" : '')} style={{marginTop:40,right:33}} id="service" >
                    {checkPermission(auth.permissions,'service.update') ? <a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{ openModal('services',value); setNovo(false) }}>Editar</a> : <></>}
                    {checkPermission(auth.permissions,'service.destroy') ? <a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{  updateService({...value,status:value.status === 1 ? 2 : 1  },value.id); setNovo(false) }}>{value.status === 1 ? "Desativar" : "Ativar"}</a>: <></>}
                </div>
            </td>
        </tr>
    )
}

function ServicesController({openModal,getService,itens,loading,updateService,auth}) {
    const [tab,setTab] = useState(1);
    useEffect(() =>{
        getService();
    },[getService]);



    return(
        <div className="card  mb-5 mb-xl-10">
            <div className="card-header cursor-pointer">
                <div className="card-title align-items-start flex-column">
                    <span className="fw-bolder m-0">Serviços</span>
                </div>
                <div className="card-toolbar">
                    {checkPermission(auth.permissions,'service.store') ?  <button className="btn btn-sm btn-light-info" onClick={() => openModal('services',{}) }>Adicionar</button> : <></>}
                </div>
            </div>
            <div className="card-body">
                <div className='table-responsive'>
                    <div style={{display: "flex", flexDirection: "row", marginBottom: 20}}>
                        {
                            [{label:"Ativo",value:1},{label:"Inativo",value:2}].map((value, index) => {
                                return (<div key={index} onClick={() => setTab(value.value)} style={{
                                    flex: "none",
                                    padding: "5px 10px",
                                    background: value.value === tab ? "#8bec8b" : '#f1f1f1',
                                    borderRadius: 10,
                                    marginRight: 10,
                                    color: value.value === tab ? "#fff" : '#000',
                                    cursor: "pointer"
                                }}>{value.label}</div>)
                            })
                        }
                    </div>
                </div>

                <div className='table-responsive'>
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                            <th style={{textAlign: 'left'}}>Nome</th>
                            <th style={{textAlign: 'left'}}>Valor</th>
                            <th style={{textAlign: 'left'}}>Tipo de comissão</th>
                            <th style={{textAlign: 'left'}}>Comissão</th>
                            <th style={{textAlign: 'left'}}>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        {
                            loading === true ?
                                <tbody>
                                {
                                    [1, 2, 3].map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 70">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 35">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 35">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 50">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left', width: 100}}>
                                                    <ContentLoader viewBox="0 0 380 50">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                                :
                                <tbody>
                                {
                                    itens && itens.filter(value => value.status === tab).length > 0 ?
                                        itens.filter(value => value.status === tab).map((value, index) => {
                                            return (
                                                <ServiceList auth={auth} value={value} key={index} openModal={openModal}
                                                             updateService={updateService}/>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="6">
                                                <div style={{textAlign: "center", paddingTop: 10}}>Não foi encontrado
                                                    nenhum serviço.
                                                </div>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStateToprops = state => ({
    itens: state.service.itens,
    loading: state.service.loading,
    auth: state.auth
})

const mapDispatchToprops = dispatch => ({
    openModal: (modal, data) => dispatch(openModal(modal, data)),
    getService: () => dispatch(getService()),
    updateService: (data, id) => dispatch(updateService(data, id))
})

export default connect(mapStateToprops, mapDispatchToprops)(ServicesController)
