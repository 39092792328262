import React, { useEffect,useState } from "react";
import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import { Button } from "../Button";
import {debounce} from "lodash";
import api from "../../api";
import {useSelector} from "react-redux";

function UserPrivateModal({loading,data,update,getUserPrivateStatus,userPrivateStatus,treatment,getTreatment,store}) {
    const [statusSelected,setStatusSelected] = useState(0)
    const [leaderSelected,setLeaderSelect] = useState(0);
    const [professionaisLoading,setProfessionaisLoading] = useState(false)
    const [professionais,setProfessionais] = useState([])

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            status: data && data.status ? data.status?.id : null,
            patient:  data && data.patient ?  data.patient : null,
            leader: data && data.leader ? data.leader : 0,
            report_date: data && data.report_date ? data.report_date : null,
            specialization: data && data.specialization ?  data.specialization?.id : null
        }
    });

    useEffect(()=>{
        getUserPrivateStatus();
        getTreatment();
        if(data.status?.id){
            setStatusSelected(data.status.id)
        }
    }, [])

    const onSubmit = (request) =>{
        if(data.id){
            update(request,data.id);
        }else{
            store(request)
        }
    }

    const treatmentOptions = [];

    if (treatment && treatment.length > 0) {
        treatment.filter(spe => spe.status === 1).forEach(element => {
            treatmentOptions.push({
                label: ((element && element.specialization.profession) ? element.specialization.profession.description : '') + " " + (element.specialization.description ? element.specialization.description : '') + " (" + element.time + " minutos)",
                value: element.id
            })
        });
    }

    let token = useSelector(state => state.auth.token);

    const handleInputChangePatient = debounce(((value, callback) => {
        setProfessionaisLoading(true)
        api.post(`/user/search/type/1?page=1&pageSize=10`, {name: value}, {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }).then((response) => {
            const options = response.data.data.map((result) => ({
                value: result.id,
                label: result.name,
            }));
            setProfessionais(options);
        }).finally(() => setProfessionaisLoading(false));
    }), 1000);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-3" style={{marginTop:10}} >
                    <label>Profissional:</label><br/>
                    {
                        data.professional?.id ?
                            <span>{data.professional.name}</span>
                            :
                            <>
                                <Controller
                                    name={"professional"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field : { onChange, value }}) =>
                                        <Field
                                            type="autocomplete"
                                            loading={professionaisLoading}
                                            options={professionais}
                                            onInputChange={handleInputChangePatient}
                                            value={value}
                                            onChange={onChange}
                                        />
                                }
                                />
                                {errors.professional && <p style={{color:'red',marginTop:10}}>Necessário preencher o profissional.</p>}
                            </>
                    }

                </div>
                <div className="col-lg-3" style={{marginTop:10}} >
                    <label>Tratamento:</label><br/>
                    {
                        data.company_specialization?.id ?
                            <span>{data && data.company_specialization && data.company_specialization.specialization && data.company_specialization.specialization.profession && data.company_specialization.specialization.profession.description } {data && data.company_specialization && data.company_specialization.specialization && data.company_specialization.specialization.description} </span>
                            :
                            <>
                                <Controller
                                    name={"specialization"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field : { onChange, value }}) =>  <Field
                                        type="select"
                                        options={treatmentOptions}
                                        value={value}
                                        onChange={onChange}
                                    />}
                                />
                                {errors.specialization && <p style={{color:'red',marginTop:10}}>Necessário preencher o tratamento.</p>}
                            </>
                    }

                </div>
                <div className="col-lg-3" style={{marginTop:10}}>
                    <label>Status:</label>
                    <Controller
                        name="status"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }})  => <Field type="select" options={userPrivateStatus} value={value} onChange={(v) => {onChange(v);setStatusSelected(v)}} />}
                    />
                    {errors.status && <p style={{color:'red',marginTop:10}}>Necessário preencher o status.</p>}
                </div>
                <div className="col-lg-3" style={{marginTop:10}}>
                    <label>Tipo de profissional:</label>
                    <Controller
                        name={"leader"}
                        control={control}
                        render={({ field : { onChange, value }}) => <Field type="select" options={[{value:0,label:'Terapeuta'},{value:1,label:'Lider'},{value:2,label:'Coodernador(a)'}]} value={value} onChange={(v) => {onChange(v);setLeaderSelect(v)}}  />}
                    />
                </div>
                <div className="col-lg-3" style={{marginTop:10}}>
                    <label>Envio do relatório:</label>
                    <Controller
                        name={"report_date"}
                        control={control}
                        render={({ field : { onChange, value }}) => <Field type="date" value={value} onChange={onChange} />}
                    />
                </div>
                {(statusSelected === 3 || statusSelected === 5) && <div className="col-lg-12" style={{paddingBottom:20}}>
                    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed  p-6" style={{marginTop:20}}>
                        <span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                  <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                  <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                </svg>
                            </span>
                            <div className="d-flex flex-stack flex-grow-1 ">
                                <div className=" fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">Atenção</h4>
                                    <div className="fs-6 text-gray-700 ">Ao escolher este status, todos os atendimentos futuros serão excluídos .</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(leaderSelected === 2 ) && <div className="col-lg-12" style={{paddingBottom:20}}>
                    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed  p-6" style={{marginTop:20}}>
                        <span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                  <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                  <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        <div className="d-flex flex-stack flex-grow-1 ">
                            <div className=" fw-semibold">
                                <h4 className="text-gray-900 fw-bold">Atenção</h4>
                                <div className="fs-6 text-gray-700 ">Ao definir este profissional como coordenador(a), ele terá acesso aos atendimentos de todos os terapeutas vinculados a esse paciente.</div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>
            </div>
        </form>
    );
}

export default UserPrivateModal
