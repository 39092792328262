import React, { useEffect, useState } from "react";
import { Field } from "../../components/Field";
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import { getGroups, saveGroups } from "../../store/actions/groupsActions";
import Spinner from "react-activity/dist/Spinner";
import { checkPermission } from "../../utils";
import Confirm from "../../components/Confirm";
import {openAny, openModal} from "../../store/actions/uiActions";
import History from "./History";
import BlockedContent from "./BlockedContent";


function FieldView({disabled, fi,value,type,treatment,patient,groupsCopy,value_link,openAny,user,professional}){
    const [confirm,setConfirm] = useState(false);

    return (
        <div style={{display:'flex',marginBottom:5}}>
            <label style={{flex: 1, marginTop: 5,fontWeight:'bold'}}>
                {fi.title}:
                {fi.help_description && <span
                    style={{marginTop: 10, flex: 1, flexGrow: "inherit", cursor: 'pointer', marginLeft: 5}}
                    className="svg-icon svg-icon-muted svg-icon-1hx"
                    title={fi.help_description}
                    onClick={() => openAny(<div dangerouslySetInnerHTML={{__html: fi.help_description}}></div>, fi.title)}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                        <path
                            d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                            fill="currentColor"
                        />
                    </svg>
                </span>}
            </label>
            <Confirm
                title="Quer copiar o texto do último atendimento?"
                open={confirm}
                closeModal={() => setConfirm(false)}
                onClick={() => groupsCopy(patient, fi.id, type, value.id, value_link, value.connection, value.table, value.id_link, treatment)}
            />
            {
                ((fi.type === 'text' || fi.type === 'textArea')) ?
                    <>

                    {
                        fi.history &&
                        <button
                            onClick={() => {
                                openAny(<History group={fi.group} field={fi.id} value={value_link} />, 'Histórico de atualizações','lg')
                            }}
                            title="Histórico de atualizações"
                            className="btn btn-primary btn-sm"
                            style={{width: 30, height: 25, padding: 5, paddingLeft: 7, paddingTop: 3, marginRight:fi.copy === 1 ? 10 : 0}}
                        >
                            <span className="svg-icon svg-icon-muted">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        opacity="0.3"
                                        d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                        </button>
                    }
                    {!disabled && user.id === professional && fi.copy === 1 && <button
                            onClick={() => {
                                setConfirm(true)
                            }}
                            title="Copiar texto do último atendimento para esse paciente e tratamento"
                            className="btn btn-primary btn-sm"
                            style={{width: 30, height: 25, padding: 5, paddingLeft: 7, paddingTop: 3}}
                        >
                            <span className="svg-icon svg-icon-muted">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.5"
                                          d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                          fill="currentColor"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                          fill="currentColor"/>
                                </svg>
                            </span>
                        </button>}

                    </>
                    :
                    <></>
            }
        </div>
    );
}

function FieldList({disabled, fi, save, setDisabledEvolution, groupsCopy, type, value_link, patient, value, treatment, company, professional, openAny, openModal, user}) {

    useEffect(() => {
        setDisabledEvolution(fi.value ? fi.value : '');
    }, [fi.value]);

    return (
        <div key={fi.id} className={"col-md-" + (fi.size ? fi.size : 3)} style={{marginBottom: 10}}>
            {
                fi.type === 'title' ?
                    <div style={fi.style ? fi.style : {fontWeight: 'bold'}}><br/>{fi.title}</div>
                    :
                    <>
                        {
                            fi.type !== "checkbox" ?
                                <FieldView
                                    openModal={openModal}
                                    fi={fi}
                                    type={type}
                                    groupsCopy={groupsCopy}
                                    value_link={value_link}
                                    patient={patient}
                                    value={value}
                                    treatment={treatment}
                                    openAny={openAny}
                                    professional={professional}
                                    user={user}
                                    disabled={disabled}
                                />
                            :
                            <></>
                        }
                        {
                            [195].includes(company) ?
                                <Field
                                    type={fi.type}
                                    value={fi.value}
                                    rows="8"
                                    name={fi.name}
                                    title={fi.title}
                                    options={fi.options}
                                    disabled={disabled}
                                    onChange={(text) => {
                                        setDisabledEvolution(text);
                                        if(!disabled)
                                            save(fi.id, text, value.table, value.connection, value.id_link, value_link, company, professional,type,value.id)
                                    }}
                                />
                                :
                                professional !== user.id  || disabled ?
                                    <Field
                                        disabled="true"
                                        type={fi.type}
                                        value={fi.value}
                                        rows="8"
                                        name={fi.name}
                                        title={fi.title}
                                        options={fi.options} />
                                    :
                                    <Field
                                        type={fi.type}
                                        value={fi.value}
                                        rows="8"
                                        name={fi.name}
                                        title={fi.title}
                                        options={fi.options}
                                        onChange={(text) => {
                                            setDisabledEvolution(text);
                                            save(fi.id, text, value.table, value.connection, value.id_link, value_link, company, professional,type,value.id)
                                        }}
                                    />
                        }
                    </>
            }
        </div>
    );
}

function Group({value, disabled, isMobile, value_link, type, treatment, groups, loading, getGroups, saveGroups, loadingAction, professional, company, user, groupsCopy, patient, setDisabledEvolution, openAny, openModal }) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lottie/loading_blue.json'),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const save =  (id,value,table,connection,id_link,value_link,company,professional,type,id_group) => {
        saveGroups(id,value,table,connection,id_link,value_link,company,professional,type,id_group)
    }

    useEffect(()=>{
        getGroups(type,treatment,value_link,patient)
    },[]);

    const permissions = [
        {
            'type':1,
            'permission':'avaliation.modify'
        },
        {
            'type':2,
            'permission':'evolution.modify'
        },
        {
            'type':3,
            'permission':'anamnese.modify'
        },
        {
            'type':4,
            'permission':'return.modify'
        },
        {
            'type':5,
            'permission':'plan.list'
        },
        {
            'type':6,
            'permission':'teste.modify'
        },
        {
            'type':7,
            'permission':'teste.modify'
        },
    ]

    const permission = permissions.filter((p) => p.type === type )

    if(!checkPermission(user.permissions,permission[0]?.permission)){
        return null;
    }

    let style = {overflow:'auto',maxHeight:400,paddingTop:10}
    
    if(isMobile){
        style = {overflow:'auto',  padding:"0px 5px 10px 5px"}
    }

    const isBlocked = value.treatment?.specialization?.profession?.block === 1;
    const isAuthorized = user.id === value.professional?.id;

    if (user.company.treatmentIsBlocked && isBlocked && !isAuthorized) {
        return (
            <div style={{padding:10}}>
                <BlockedContent text={value.treatment?.specialization?.profession?.reason_blocking} />
            </div>
        )
    }

    return (
        <>
            {
                loading ?
                    <div style={{marginTop:50,marginBottom:50,textAlign:'center'}}>
                        <Lottie
                            options={defaultOptions}
                            height={40}
                            width={200}
                        />
                    </div>
                :
                    groups.length > 0 ?
                        groups.map((value,index) =>{

                            return (
                                <div key={index} className="card mb-5">
                                    <div className="card-header cursor-pointer" style={{minHeight:50}}>

                                        <div className="card-title m-0" style={{flexDirection:'row',flex:1}}>
                                            <h3 className="fw-bolder m-0" style={{flex:1,fontSize:15}}>{value.description} </h3>
                                            <div>
                                            {
                                                loadingAction && loadingAction[value.table] === true ?
                                                    <Spinner />
                                                :
                                                    <></>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" style={style}>
                                        {
                                            value.fields && value.fields.length > 0 ?
                                                <div className="row">
                                                    {
                                                        value.fields.map((fi,index)=>{
                                                            return (
                                                              <FieldList disabled={disabled}  user={user} openModal={openModal} openAny={openAny} key={index} index={index} setDisabledEvolution={setDisabledEvolution} fi={fi} save={save} groupsCopy={groupsCopy} type={type} value_link={value_link} patient={patient} value={value} treatment={treatment} company={company} professional={professional}/>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            :
                                                <div style={{marginTop:50,marginBottom:50,textAlign:'center'}}>Este formulário não apresenta campos para serem preenchidos.</div>
                                        }
                                    </div>
                                </div>
                            );
                        })
                    :
                        <div style={{marginTop:50,marginBottom:50,textAlign:'center'}}>Não foram encontrados formulários configurados.</div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    groups:state.groups.itens,
    loading:state.groups.loading,
    loadingAction:state.groups.loadingAction
})

const mapDispatchToProps = dispatch => ({
    getGroups:(typeSchedule,treatment,call,patient) => dispatch(getGroups(typeSchedule,treatment,call,patient)),
    saveGroups:(id,value,table,connection,id_link,value_link,company,professional,type,id_group) => dispatch(saveGroups(id,value,table,connection,id_link,value_link,company,professional,type,id_group)),
    openAny:(children,title,size) => dispatch(openAny(children,title,size)),
    openModal:(modal,payload,callback) => dispatch(openModal(modal,payload,callback))
})

export default connect(mapStateToProps,mapDispatchToProps)(Group)
