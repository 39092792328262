const InvoiceBadge = ({ value }) => {
    const invoices = value?.finance_document?.filter(value => value.status !== 12);

    const invoice =  invoices?.length > 0 ?   invoices[invoices?.length - 1] : {};

    const statusMap = {
        1: { color: 'badge-primary', name: 'NFS-e criada' },
        2: { color: 'badge-primary', name: 'NFS-e enfileirada' },
        4: { color: 'badge-success', name: 'NFS-e autorizada' },
        6: { color: 'badge-danger', name: 'NFS-e rejeitada' },
        8: { color: 'badge-dark', name: 'NFS-e cancelada' },
        13: { color: 'badge-light-danger', name: 'Cancelando NFS-e' },
    };

    if (!statusMap[invoice?.status]) return null;
    const { color, name } = statusMap[invoice?.status];

    return <div className={`badge ${color}`} style={{ height: 22 }}>{name}</div>;
};

export default InvoiceBadge