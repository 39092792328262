import React, {useEffect} from 'react';
import { Route, Switch,Redirect } from 'react-router-dom';
import Login from '../containers/login';
import Home from '../containers/home';
import { useSelector,useDispatch } from 'react-redux';
import Layout from '../components/layout';
import Patient from '../containers/patients/patient';
import UserController from '../containers/professionals/UserController';
import PatientController from '../containers/patients/PatientController';
import User from '../containers/professionals/user';
import { checkPermission } from '../utils';
import Profile from '../containers/profile';
import ReceiveController from '../containers/finance/ReceiveController';
import Active from '../containers/account/Active';
import SendInvite from '../containers/account/SendInvite';
import TreatmentController from '../containers/treament/TreatmentController';
import Create from '../containers/account/Create';
import ReportsFinance from '../containers/finance/ReportsFinance';
import PaymentsController from '../containers/finance/reports/PaymentsController';
import PayController from '../containers/finance/PayController';
import ListView from "../containers/calendar/ListView";
import CalendarView from "../containers/calendar/CalendarView";
import Img404 from '../assets/img/404.png';
import ServicesController from "../containers/services/ServicesController";
import Confirm from "../containers/confirm";
import ReportsController from "../containers/reports/ReportsController";
import RankingReportsController from "../containers/reports/RankingReportsController";
import Finance from "../containers/finance";
import Companies from "../containers/companies";
import CalendarViewV2 from "../containers/calendar/CalendarViewV2";
import Crm from "../containers/patients";
import ManagerialIndicators from "../containers/calendar/managerialIndicators";
import Company from "../containers/companies/company";
import OperatorsController from "../containers/operators/OperatorsController";
import QueueController from "../containers/patients/QueueController";
import BankAccountsController from "../containers/finance/BankAccountsController";
import Config from "../containers/config";
import ReceiveForCallsController from "../containers/finance/ReceiveForCallsController";
import Uncheck from "../containers/uncheck";

function PrivateRoute({isMobile, calendarRef, children, ...rest }) {

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth)
  const patient = useSelector((state) => state.patients.item)
  const professionalCalendar = useSelector((state) => state.calendar.professional);

  useEffect(()=>{
      dispatch({type:'SET_START_DATE','startDate':''});
      dispatch({type:'SET_END_DATE','endDate':''});
      dispatch({type:'SET_PROFESSIONAL','professional':''});
      dispatch({type:'SET_PATIENT','patient':''});
      dispatch({type:'SET_STATUSS','status':''});
  },[dispatch])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.token ? (
          <Layout isMobile={isMobile} calendarRef={calendarRef} children={children} auth={auth} dispatch={dispatch} patient={patient} professionalCalendar={professionalCalendar} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}




export default function Routes({calendarRef, isMobile}) {

  const auth = useSelector((state) => state.auth);

  const navigation = [
    {
      path:"/",
      component:  auth.company && auth.company?.id === 169 ? <Finance /> : <Home/>
    },
    {
      path:"/schedule/list",
      permission:'calls.list',
      component: <ListView destroyMult={true} />
    },
    {
      path:"/schedule/my",
      permission:'mycalendar.list',
      component: (![23].includes(auth.company?.id)) ? <CalendarViewV2 myScheduler={true}  /> : <CalendarView myScheduler={true} />

      //component: ([1,2,195,213,89,98,33,104,202,185,24,117,45,43,48].includes(auth.company?.id) ||  auth.company?.id >= 221) ? <CalendarViewV2 myScheduler={true}  /> : <CalendarView myScheduler={true} />
    },
    {
      path:"/schedule/calendar",
      permission:'calls.list',
      component: (![23].includes(auth.company?.id)) ? <CalendarViewV2   /> : <CalendarView />
      //component:  ([1,2,195,213,89,98,33,104,202,185,24,117,45,43,48].includes(auth.company?.id) ||  auth.company?.id >= 221) ? <CalendarViewV2 /> : <CalendarView />
    },
    {
      path:"/schedule/dashboard",
      permission:'managerial.dashboard',
      component:  <ManagerialIndicators />
    },
    {
      path:"/reports/:page",
      //permission:'report.show',
      component: <ReportsController />
    },
    {
      path:"/report/ranking",
      permission:'report.ranking.dependencies',
      component: <RankingReportsController />
    },
    {
      path:"/crm",
      permission:'patients.crm',
      component: <Crm/>
    },
    {
      path:"/patients",
      permission:'patients.list',
      component: <PatientController/>
    },
    {
      path:"/queue",
      permission:'queueWait.list',
      component: <QueueController />
    },
    {
      path:"/patient/:id",
      permission:'patients.show',
      component: <Patient/>
    },
    {
      path:"/company/user",
      permission:'professionals.list',
      component: <UserController/>
    },
    {
      path:"/user/:id",
      permission:'professionals.show',
      component: <User/>
    },
    {
      path:"/company/roles",
      permission:'roles.list',
      component: <Profile/>
    },
    {
      path:"/finance/receive",
      permission:'receive.list',
      component: <ReceiveController typeModel={auth.company.id === 169 ? 2 : 1}/>
    },
    {
      path:"/finance/receive/call",
      permission:'receive.list',
      component: <ReceiveForCallsController />
    },
    {
      path:"/finance/pay",
      permission:'receive.list',
      component: <PayController typeModel={auth.company.id === 169 ? 2 : 1}/>
    },
    {
      path:"/finance/reports",
      permission:'finance.report',
      component: <ReportsFinance/>
    },
    {
      path:"/finance/reports/payments",
      permission:'received.report',
      component: <PaymentsController/>
    },
    {
      path:"/company/treatment",
      permission:'treatment.list',
      component: <TreatmentController/>
    },
    {
      path:"/company/services",
      permission:'service.list',
      component: <ServicesController/>
    },
    {
      path:"/finance",
      permission:'finance.dashboard',
      component: <Finance />
    },
    {
      path:"/finance/bankAccounts",
      permission:'bankAccount.list',
      component: <BankAccountsController/>
    },
    {
      path:"/companies",
      companies:[169],
      component: <Companies />
    },
    {
      path:"/companies/:id",
      companies:[169],
      component: <Company />
    },
    {
      path:"/company/operators",
      permission:'operators.list',
      component: <OperatorsController/>
    },
    {
      path:"/company",
      permission:["roles.list","professionals.list","treatment.list",'service.list','operators.list'],
      component: <Config  />
    }
  ]

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/account/active/:token" component={Active} />
      <Route exact path="/account/invite" component={SendInvite} />
      <Route exact path="/confirm/:hash" component={Confirm} />
      <Route exact path="/uncheck/:hash" component={Uncheck} />
      <Route exact path="/account/create" component={Create} />
      {
        navigation.map((value,index) => {
          if(value.companies && value.companies.length > 0 && !value.companies.includes(auth.company?.id)){
            return null;
          }

          const hasFinancialAccess = !value.permission ?
              true : (Array.isArray(value.permission) ? auth.permissions.some(permission => value.permission?.includes(permission)) : checkPermission(auth.permissions,value.permission))

          if(!hasFinancialAccess){
            return null ;
          }

          return (
              <PrivateRoute isMobile={isMobile} calendarRef={calendarRef} key={index} exact path={value.path} >
                {value.component}
              </PrivateRoute>
          )
        })
      }
      <Route path="*">
        <div style={{textAlign:'center',paddingTop:200,backgroundColor:'#fff',height:'100%'}}>
          <br/><br/>
          <br/><br/>
          <h1>Parece que não há nada aqui.</h1>
          <br/><br/>
          <br/><br/>
          <img src={Img404} style={{width:400}} alt="img" />
        </div>
      </Route>
    </Switch>
  )
}
