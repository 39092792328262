import React from "react";


export default function Noticie({title,description,type}){
    let bg = 'bg-light-warning';
    let border = 'border-warning';
    let icon = 'svg-icon-warning';

    switch (type){
        case 'warning':
            bg = 'bg-light-warning';
            border = 'border-warning';
            icon = 'svg-icon-warning';
            break;
        case 'danger':
            bg = 'bg-light-danger';
            border = 'border-danger';
            icon = 'svg-icon-danger';
            break;
        case 'primary':
            bg = 'bg-light-primary';
            border = 'border-primary';
            icon = 'svg-icon-primary';
            break;
        case 'info':
            bg = 'bg-light-info';
            border = 'border-info';
            icon = 'svg-icon-info';
            break;
    }

    return(
        <div style={{marginBottom:10,padding:5}} className={" notice d-flex rounded border border-dashed "+(bg+" "+border)} >
            <span className={"svg-icon svg-icon-2tx me-4 "+icon}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                    <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                    <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                </svg>
            </span>
            <div className="d-flex flex-stack flex-grow-1 ">
                <div className=" fw-semibold">
                    <h4 className="text-gray-900 fw-bold">{title}</h4>
                    <div className="fs-6 text-gray-700 " dangerouslySetInnerHTML={{__html:description}}></div>
                </div>
            </div>
        </div>
    )
}