import React, {useEffect, useState} from "react";
import {DateTime} from "luxon";
import {connect, useDispatch, useSelector} from "react-redux";
import ContentLoader from "react-content-loader";
import {useMediaQuery} from "react-responsive";
import {Link} from "react-router-dom";
import {debounce} from "lodash";
import toast from "react-hot-toast";
import Img5 from "../../assets/img/5.png";
import {
    checkPermission,
    formatDate2,
    formatDateNotHour,
    formatHour,
    getName,
    getWeekName
} from "../../utils";
import Confirm from "../../components/Confirm";
import {Field} from "../../components/Field";
import {getAutocompleteProfessional, getProfessionals} from "../../store/actions/professionalsActions";
import {
    destroyCall,
    multiplesDestroy,
    searchCalls,
    setEndDate,
    setPatient,
    setPatientSelected,
    setProfessional,
    setProfessionalSelected,
    setStartDate,
    setStatuss
} from "../../store/actions/calendarActions";
import {openModal} from "../../store/actions/uiActions";
import {getStatus, getTypeSchedules} from "../../store/actions/companiesActions";
import {getAutocompletePatient} from "../../store/actions/patientsActions";
import {BadgeTypeScheduler} from "../../components/BadgeTypeScheduler";
import {Menu} from "../../components/Menu";
import api from "../../api";
import Excel from "../../assets/img/file-excel-regular.svg";
import Task from "../../assets/img/task.svg";
import SearchList from "./SearchList";
import {hasInterest, hasUnchecked} from "../../components/Helper";
import {getTreatment} from "../../store/actions/treatmentActions";

export function MenuView({type, value, user}) {
    const [novo, setNovo] = useState(false);

    const dispatch = useDispatch();

    const openCall = (modal,item,typeModal) => dispatch({type:'OPEN_CALL',modal,item,typeModal});

    useEffect(() => {
        function click(ev) {
            if (ev.srcElement.closest("#novoPlan") == null) {
                setNovo(false);
            }
        }

        if (novo) {
            window.addEventListener("click", click, false);
            return () => {
                window.removeEventListener("click", click, false);
            };
        }
    }, [novo]);

    let options = [];

    if (value.status.id !== 10) {
        options.push({
            description: value.type_schedule.id === 5 ? "Editar" : 'Visualizar',
            permission: value.type_schedule.id === 5 ? 'plan.list' : 'calls.show',
            onClick: () => openCall("session", {...value, edit: true, type: type},"secondary")
        });

        if( !value?.signed_document  && value?.rescheduled !== 1 && !hasInterest(value.type_schedule.id)){

            if( !value.finance ){
                options.push({
                    description: 'Editar',
                    permission: 'calls.update',
                    onClick: () => openCall("atendimento", {...value, edit: true, type: type})
                });
            }


            if (value.status.id === 1 || value.status.id === 2 || value.status.id === 3) {
                options.push({
                    description: 'Desmarcar',
                    permission: 'status.markoff',
                    onClick: () => openCall("markOffCallsModal", {
                        ...value,
                        type: type,
                        service: value.service.id ? value.service.id : 0
                    },"primary")
                });
            }

            if (value.rescheduled !== 1 && (hasUnchecked(value.status.id))) {
                options.push({
                    description: 'Remarcar',
                    permission: 'status.reschedule',
                    onClick: () => openCall("remarcar", {
                        ...value,
                        type: type,
                        service: value.service.id ? value.service.id : 0
                    },"primary")
                });
            }

            if (value.status.id === 1) {
                options.push({
                    description: 'Confirmar',
                    permission: 'calls.update',
                    onClick: () => openCall("confirmCalls", value)
                });
            }
        }
    }

    if( !value?.signed_document  ){
        if( !value.finance ){
            options.push({
                description: 'Excluir',
                permission: value.type_schedule.id === 5 ? 'plan.destroy' : 'calls.destroy',
                onClick: () => openCall("confirmDestroyModal", {
                    ...value,
                    type: type
                },'secondary')
            });
        }
    }

    if (!checkPermission(user.permissions, "calls.show") && !checkPermission(user.permissions, "calls.update") && !checkPermission(user.permissions, "calls.markoff") && !checkPermission(user.permissions, "calls.finish") && !checkPermission(user.permissions, "calls.tomeet") && !checkPermission(user.permissions, "calls.arrived")) return null;

    return (
        <Menu
            open={novo}
            setOpen={setNovo}
            auth={user}
            options={options}
        />
    );
}

function CalendarList({isCheck, value, user, openModal, destroyCall, onChange, destroyMult}) {

    return (
        <div className="col-md-12" style={{marginTop: 5}}>
            <div className="card" style={{backgroundColor: value.status.id === 10 ? "#ffc1c1" : "#fff"}}>
                <div
                    className="card-body"
                    style={{
                        borderLeftWidth: 10,
                        borderLeftColor: value.status.id !== 10 ? value.status.color : "transparent",
                        borderLeftStyle: "solid",
                        paddingLeft: 10,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        flexDirection: "row", display: "flex", paddingTop: 0,  paddingBottom: 0,alignItems: "center"}}
                >
                    <div
                        title={value.status.id !== 10 ? value.status.description : ''}
                        style={{

                        }}
                    >
                        {
                            value.status.id !== 10 && destroyMult && checkPermission(user.permissions, "calls.destroy") ?
                                <Field value={isCheck.includes(value.tid) }  type="checkbox" onChange={ () => onChange(value.tid) } style={{width: 20, height: 20}} />
                            :
                                <></>
                        }
                    </div>
                    {
                        value.status.id !== 10 ?
                            <div style={{
                                textAlign: "center",
                                paddingRight: 20,
                                marginRight: 20,
                                borderRightWidth: 1,
                                borderRightColor: "#00afef",
                                borderRightStyle: "dashed",
                                width: 110,
                            }}>
                                <div>{formatDateNotHour(DateTime.fromSQL(value.start))}</div>
                                <div style={{fontSize: 11}}>{formatHour(DateTime.fromSQL(value.start))} - {formatHour(DateTime.fromSQL(value.end))}</div>
                                <div className="badge badge-light-primary" style={{ marginTop: 2,fontSize: 10,marginBottom:5}}> {getWeekName(DateTime.fromSQL(value.start))}  </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        value.status.id !== 10 ?
                            <>
                                <div
                                    className="text-gray-700 fw-bold fs-6 me-2"
                                    style={{textAlign:'left',width:160}}
                                >
                                    <div >
                                        <Link
                                            className="btn btn-link"
                                            style={{textAlign:"left",padding:0}}
                                            title={value.patient.name}
                                            to={"/patient/" + value.patient.id}>
                                            {value.patient ? getName(value.patient.name) : ""}
                                        </Link>
                                    </div>
                                    <small className="fw-bold text-muted"> {value.treatment.specialization && value.treatment.specialization.profession && value.treatment.specialization.profession.description} {value.treatment.specialization && value.treatment.specialization.description} </small>
                                </div>
                                <div style={{flex: 1}}>
                                    <small className="fw-bold text-muted">Profissional</small>
                                    <div className="" style={{marginBottom: 4}}>
                                        <small>{getName(value.professional.name)}</small>
                                    </div>
                                </div>
                                <div style={{flex: 1}}>
                                    {
                                        value.status.id !== 10 && value.room.id ?
                                            <>
                                                <small className="fw-bold text-muted">Sala</small>
                                                <div style={{margiBottom: 4}}>
                                                    <small>{value.room.description}</small>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        placeContent: "center space-evenly",
                                        flexWrap: "nowrap",
                                        alignItems: "flex-start",
                                        justifyContent: "space-around",
                                        alignContent: "center",
                                        flexDirection: "row",
                                        gap:3,

                                    }}
                                >

                                    <BadgeTypeScheduler data={value.type_schedule}/>
                                    {value.type_address === 4 ? <div className="badge badge-info" >Teleatendimento</div> : <></>}
                                    {value.replacement ? <div className="badge badge-light-warning" >Reposição</div> : <></>}
                                    {value.rescheduled ? <div className="badge badge-primary" style={{backgroundColor: '#ffc107'}}>Remarcado</div> : <></>}
                                    {value?.signed_document ? <div className="badge badge-success" >Atendimento assinado</div> : <></>}
                                </div>
                            </>
                            :
                            <div style={{flex: 1, marginTop: 18, marginBottom: 10, textAlign: "center"}}>
                                <div className="" style={{marginBottom: 4}}>
                                    <h6>
                                        Horário bloqueado para o profissional {getName(value.professional.name)}
                                        <br/>
                                        <small className="fw-bold text-white">{formatDate2(DateTime.fromSQL(value.start), DateTime.fromSQL(value.end))}</small>
                                    </h6>
                                </div>
                            </div>
                    }
                    <div style={{width:50,textAlign:"center"}}>
                        {
                            value.task > 0 ?
                                <span className="svg-icon svg-icon-muted"   title={"Esse atendimento está vinculado a um cadastro recorrente."}>
                                    <img src={Task} width="14"  alt={"Icone d"}/>
                                </span>
                                :
                                <div ></div>
                        }
                    </div>
                    <div >
                        <MenuView type="1" destroyCall={destroyCall} openModal={openModal} user={user} value={value}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CalendarMobileList({isCheck, value, user, openModal, destroyCall, onChange, destroyMult}) {

    return (
        <div className="col-md-12" style={{marginTop: 5,zIndex:-1}}>
            <div className="card" style={{background: value.status.id === 10 ? "#ffc1c1" : "#fff"}}>
                <div className="card-body" style={{
                    flexDirection: "row",
                    display: "flex",
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingRight: 10
                }}>
                    <div style={{
                        borderLeftWidth: 10,
                        borderLeftColor: value.status.id !== 10 ? value.status.color : "transparent",
                        borderLeftStyle: "solid",
                        paddingTop: 14,
                        paddingLeft: 10,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8
                    }}>
                        {
                            destroyMult && checkPermission(user.permissions, "calls.destroy") ?
                                <Field
                                    value={
                                        isCheck.includes(value.tid)
                                    }
                                    type="checkbox"
                                    onChange={
                                        () => onChange(value.tid)
                                    }
                                    style={{width: 20, height: 20}}
                                />
                                :
                                <></>
                        }
                    </div>
                    {
                        value.status.id !== 10 ?
                            <div style={{
                                textAlign: "center",
                                paddingTop: 5,
                                paddingBottom: 5,
                                paddingRight: 10,
                                marginRight: 5,
                                borderRightWidth: 1,
                                borderRightColor: "#00afef",
                                borderRightStyle: "dashed"
                            }}>
                                <div>{formatDateNotHour(DateTime.fromSQL(value.start))}</div>
                                <div
                                    style={{fontSize: 11}}>{formatHour(DateTime.fromSQL(value.start))} {value.status.id !== 10 && "-"} {value.status.id !== 10 && formatHour(DateTime.fromSQL(value.end))}</div>
                                <div className="badge badge-light-primary" style={{
                                    marginTop: 2,
                                    fontSize: 10
                                }}> {getWeekName(DateTime.fromSQL(value.start))}  </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        value.status.id !== 10 ?
                            <div className="text-gray-700 fw-bold fs-6 me-2"
                                 style={{paddingTop: 5, marginBottom: 4, flex: 1}}>
                                <div style={{marginLeft: 10}}>
                                    <Link style={{padding: 0}} className="btn btn-link" title={value.patient.name} to={"/patient/" + value.patient.id}>   {value.patient ? getName(value.patient.name) : ""}</Link><br/>
                                </div>
                                <small style={{marginLeft: 10, fontSize: 9}} className="fw-bold text-muted"> Prof.: {getName(value.professional.name)}</small>
                                <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                                    <BadgeTypeScheduler data={value.type_schedule}/>
                                    {value.replacement ? <div className="badge badge-light-warning" style={{marginLeft: 10}}>Reposição</div> : <></>}
                                </div>
                            </div>
                            :
                            <div style={{flex: 1, marginTop: 18, marginBottom: 10, textAlign: "center"}}>
                                <div className="" style={{marginBottom: 4}}>
                                    <h6>Horário bloqueado <br/> profissional {getName(value.professional.name)}</h6>
                                </div>
                            </div>
                    }
                    <div style={{paddingTop: 23, paddingRight: 15}}>
                        {
                            value.task > 0 ?
                                <span className="svg-icon svg-icon-muted" style={{marginLeft: 5}}  title={"Esse atendimento está vinculado a um cadastro recorrente."}>
                                    <img src={Task} width="18" style={{color: '#50CD89'}} />
                                </span>
                                :
                                <></>
                        }
                    </div>
                    <div style={{marginTop: 15}}>
                        <MenuView type="1" destroyCall={destroyCall} openModal={openModal} user={user} value={value}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ListView({destroyMult, typeschedules, getTypeSchedules, professional, patient, setPatient, statusCalendar, setStatuss, endDate, setEndDate, startDate, setStartDate, multiplesDestroy, destroyCall, openModal, user, loading, searchCalls, itens, getStatus, status, id, professionais, setProfessional,getTreatment,treatment}) {

    const [confirm, setConfirm] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [dayWeekItem, setDayWeekItem] = useState(-1);
    const [treatmentItem, setTreatmentItem] = useState(0);
    const [treatmentOption, setTreatmentOption] = useState([]);
    const [professionalItem, setProfessionalItem] = useState(0);
    const [calls, setCalls] = useState([]);
    const [novo, setNovo] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [replacement, setReplacement] = useState(false);
    const [room, setRoom] = useState([]);
    const isBigScreen = useMediaQuery({query: "(min-width: 500px)"});
    const dispatch = useDispatch();

    useEffect(() => {
        function click(ev) {
            if (ev.srcElement.closest("#novoPlan") == null) {
                setNovo(false);
            }
        }

        if (novo) {
            window.addEventListener("click", click, false);
            return () => {
                window.removeEventListener("click", click, false);
            };
        }
    }, [novo]);

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);

        setIsCheck(
            calls.map(li => li.tid)
        );

        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    let token = useSelector(state => state.auth.token);

    const download = (data) => {
        let response = api.post('/call/list/report/excel/payment', data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });

        toast.promise(response, {
            loading: 'Gerando excel da lista de atendimentos.',
            success: 'Relatório com a lista de atendimentos gerado.'
        }).then((response) => {

            if (response.status === 200) {
                window.open(response.data.url, '_blank')
            }
        });
    }

    const onSubmit = () => {
        searchCalls({
            professional: professional,
            patient: patient,
            status: Array.isArray(statusCalendar) ?  statusCalendar?.map(value => value.value) : [],
            dateStart: startDate,
            dateEnd: endDate,
            type_schedule: schedules,
            replacement: replacement,
            room:room?.map(value => value.value),
            treatment: Array.isArray(treatmentOption) ?  treatmentOption?.map(value => value.value) : [],
        });
    };

    const handleDateChange = debounce((arg) => {
        if (id) {
            setStartDate(DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss"));
            setEndDate(DateTime.local().plus({months: 12}).toFormat("yyyy-MM-dd HH:mm:ss"));
            setPatient([id])
        } else {
            setStartDate(DateTime.local().toFormat("yyyy-MM-dd") + " 00:00:00");
            setEndDate(DateTime.local().toFormat("yyyy-MM-dd") + " 23:59:00");
        }
    }, 100);

    useEffect(() => {
        handleDateChange();
        getStatus();
        getTypeSchedules();
        getTreatment();

        searchCalls({
            professional: id ? [] : [],
            patient: id ? [id] : [],
            status: id ? [] : [],
            dateStart: id ? DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss") : DateTime.local().toFormat("yyyy-MM-dd") + " 00:00:00",
            dateEnd: id ? DateTime.local().plus({months: 12}).toFormat("yyyy-MM-dd HH:mm:ss") : DateTime.local().toFormat("yyyy-MM-dd") + " 23:59:00",
        });

        return () => {
            dispatch({type:'SET_PATIENT','patient':''});
        };
    }, []);

    useEffect(() => {
        setCalls(itens.filter(value => ![5,6,7].includes(value.type_schedule.id)));
        setIsCheck([]);
        setIsCheckAll(false);
    }, [itens]);

    const handleChangeDaWeek = (value) => {
        setDayWeekItem(value);

        if (value === -1) {
            setCalls(itens.filter(value => ![5,6,7].includes(value.type_schedule.id)));
        } else {
            let newValue = itens.filter(value => ![5,6,7].includes(value.type_schedule.id)).filter((at) => {
                return DateTime.fromSQL(at.start).weekday === value
            });
            setCalls(newValue);
        }
    }

    const handleChangeSpecialization = (value) => {
        setTreatmentItem(value)

        if (value === 0) {
            setCalls(itens.filter(value => ![5,6,7].includes(value.type_schedule.id)));
        } else {
            let newValue = itens.filter(value => ![5,6,7].includes(value.type_schedule.id)).filter((at) => at.treatment.id === value);
            setCalls(newValue);
        }
    }

    const handleChangeProfessional = (value) => {
        setProfessionalItem(value);

        if (value === 0) {
            setCalls(itens.filter(value => ![5,6,7].includes(value.type_schedule.id)));
        } else {
            let newValue = itens.filter(value => ![5,6,7].includes(value.type_schedule.id)).filter((at) => at.professional.id === value);
            setCalls(newValue);
        }
    }

    return (
        <div className="row">
            <div className="col-md-3">
                <SearchList treatmentOption={treatmentOption} setTreatmentOption={setTreatmentOption} treatment={treatment} setRoom={setRoom} room={room} setPatient={setPatient} setProfessional={setProfessional} dayWeekItem={dayWeekItem} handleChangeDaWeek={handleChangeDaWeek} schedules={schedules} setSchedules={setSchedules} typeschedules={typeschedules} setStatuss={setStatuss} statusCalendar={statusCalendar} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} startDate={startDate} handleChangeProfessional={handleChangeProfessional} handleChangeSpecialization={handleChangeSpecialization} onSubmit={onSubmit} status={status} setReplacement={setReplacement} replacement={replacement} id={id} professionais={professionais} professionalItem={professionalItem} setProfessionalItem={setProfessionalItem} treatmentItem={treatmentItem} setTreatmentItem={setTreatmentItem}/>
            </div>
            <div className="col-md-9">
                <div className="row">
                    {
                        destroyMult && loading === false && calls && calls.length > 0 ?
                            <div className="col-md-1">
                                <div style={{marginLeft: 20, marginTop: 15}}>
                                    <Field
                                        type="checkbox"
                                        onChange={() => handleSelectAll()}
                                        style={{width: 20, height: 20}}
                                    />
                                </div>
                            </div>
                            :
                            <div className="col-md-1"></div>
                    }
                    {
                        isCheck.length > 0 && (calls && calls.length > 0) ?
                            <div className="col-md-3">
                                <div
                                    style={{
                                        marginTop:7,
                                        display:'flex',
                                        position: 'absolute'
                                    }}
                                >
                                    <Menu
                                        open={novo}
                                        setOpen={setNovo}
                                        auth={user}
                                        className={"btn btn-secondary btn-sm"}
                                        description="Opções"
                                        style={{ top: 40}}
                                        options={[
                                            {
                                                description: 'Excluir em massa',
                                                permission: 'calls.list.destroy',
                                                onClick: () => setConfirm(true)
                                            },
                                            {
                                                description: 'Desmarcar em massa',
                                                permission: 'calls.list.markoff',
                                                onClick: () =>
                                                {
                                                    let deleteCall = itens.filter((at) => isCheck.includes(at.tid));
                                                    openModal("desmarcar", deleteCall.map((value) => {

                                                        return {
                                                            ...value,
                                                            "treatment": value.treatment.id,
                                                            "room": value.room.id ?? 0,
                                                            "professional": value.professional.id,
                                                            "patient": value.patient.id,
                                                            "status": value.status.id,
                                                            "type_schedule": value.type_schedule.id,
                                                            "service": value.service.id ?? 0
                                                        };
                                                    }))
                                                }
                                            }
                                        ]}
                                    />
                                </div>
                                <Confirm
                                    title={"Excluir atendimentos selecionados?"}
                                    typeNotification="danger"
                                    textNotification={"Ao efetuar a confirmação da exclusão dos atendimentos selecionados é importante notar que casa haja atendimentos recorrentes futuros, estes permanecerão cadastrados no sistema."}
                                    open={confirm}
                                    closeModal={() => setConfirm(false)}
                                    onClick={() => {
                                        let deleteCall = itens.filter((at) => isCheck.includes(at.tid));
                                        multiplesDestroy(deleteCall.map((value) => {
                                            return {
                                                ...value,
                                                "treatment": value.treatment.id,
                                                "room": value.room.id,
                                                "professional": value.professional.id,
                                                "patient": value.patient.id,
                                                "status": value.status.id,
                                                "type_schedule": value.type_schedule.id,
                                                "service": value.service.id
                                            };
                                        }), 0, 0, 0, 0);
                                    }}
                                />
                            </div>
                            :
                            <div className="col-md-3"></div>
                    }
                    <div className={id ? "col-md-8" : "col-md-7"} style={{paddingTop: 15, textAlign: 'right'}}>
                        {
                            calls.filter(at => at.status.id !== 10).length > 0 ?
                                <div className="badge badge-lg badge-primary" style={{textAlign: 'right'}}>
                                    <span id="kt_file_manager_items_counter">
                                        {calls.filter(at => at.status.id !== 10).length > 1 ? calls.filter(at => at.status.id !== 10).length + " atendimentos" : ""}
                                        {calls.filter(at => at.status.id !== 10).filter(at => at.status.id !== 10).length === 1 ? calls.filter(at => at.status.id !== 10).length + " atendimento" : ""}
                                    </span>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    {
                        id ?
                            <></>
                            :
                            <div className="col-md-1" style={{marginTop: 15}}>
                                { checkPermission(user.permissions, "excel.call.download") &&
                                 <button
                                    className="btn btn-link btn-sm"
                                    style={{float: 'right', padding: 0, marginRight: 5}}
                                    onClick={() =>
                                        download({
                                            professional: professional,
                                            patient: patient,
                                            status: Array.isArray(statusCalendar) ?  statusCalendar?.map(value => value.value) : [],
                                            dateStart: startDate,
                                            dateEnd: endDate,
                                            type_schedule: schedules,
                                            replacement: replacement,
                                            noLock: true,
                                            treatment: Array.isArray(treatmentOption) ?  treatmentOption?.map(value => value.value) : [],
                                        })
                                    }
                                >
                                    <img src={Excel} width="18" style={{color: '#50CD89'}} alt={"Icone do excel"}/>
                                </button>}
                            </div>
                    }

                    {
                        loading ?
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((value, index) => {
                                return (
                                    <div className="col-md-12" style={{marginTop: 5,zIndex:-1}} key={index}>
                                        <div className="card">
                                            <div className="card-body"
                                                 style={{
                                                     flexDirection: "row",
                                                     display: "flex",
                                                     height: 40,
                                                     paddingTop: 15
                                                 }}>
                                                <ContentLoader
                                                    speed={2}
                                                    width={700}
                                                    height={30}
                                                    viewBox="0 0 700 30"
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#ecebeb"
                                                >
                                                    <rect x="4" y="0" rx="3" ry="3" width="173" height="11"/>
                                                    <rect x="196" y="0" rx="3" ry="3" width="173" height="11"/>
                                                    <rect x="385" y="0" rx="3" ry="3" width="173" height="11"/>
                                                </ContentLoader>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            :
                            calls && calls.length > 0 ?
                                calls.map((value, index) => {

                                    return (
                                        isBigScreen ?
                                            <CalendarList
                                                destroyMult={destroyMult}
                                                onChange={
                                                    (value) => {
                                                        if (isCheck.indexOf(value) !== -1) {
                                                            setIsCheck(oldArray => oldArray.filter(item => item !== value));
                                                        } else {
                                                            setIsCheck(oldArray => [...oldArray, value]);
                                                        }
                                                    }
                                                }
                                                isCheck={isCheck}
                                                destroyCall={destroyCall}
                                                openModal={openModal}
                                                user={user}

                                                value={value}
                                                key={index}
                                            />
                                            :
                                            <CalendarMobileList
                                                destroyMult={destroyMult}
                                                onChange={
                                                    (value) => {
                                                        if (isCheck.indexOf(value) !== -1) {
                                                            setIsCheck(oldArray => oldArray.filter(item => item !== value));
                                                        } else {
                                                            setIsCheck(oldArray => [...oldArray, value]);
                                                        }
                                                    }
                                                }
                                                isCheck={isCheck}
                                                destroyCall={destroyCall}
                                                openModal={openModal}
                                                user={user}

                                                value={value}
                                                key={index}
                                            />
                                    );
                                })
                                :
                                <div className="d-flex flex-column flex-center" style={{paddingTop: 100}}>
                                    <img src={Img5} style={{maxWidth: 330}} alt={""}/>
                                    <div className="text-dark mb-4" style={{fontSize: 18, textAlign: "center"}}>
                                        Não encontramos nenhum registro de atendimentos.
                                    </div>
                                </div>
                    }

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.auth,
    itens: state.calendar.itens,
    loading: state.calendar.loading,
    professionals: state.professionals.itens,
    status: state.companies.status,
    patientsAutocomplete: state.patients.autocomplete,
    patientsLoadingAutocomplete: state.patients.loadingAutocomplete,
    professionalsAutocomplete: state.professionals.autocomplete,
    professionalsLoadingAutocomplete: state.professionals.loadingAutocomplete,
    professional: state.calendar.professional,
    patient: state.calendar.patient,
    statusCalendar: state.calendar.statusCalendar,
    startDate: state.calendar.startDate,
    endDate: state.calendar.endDate,
    professionalSelected: state.calendar.professionalSelected,
    patientSelected: state.calendar.patientSelected,
    typeschedules: state.companies.typeschedules,
    treatment: state.treatment.itens,
});

const mapDispatchToProps = dispatch => ({
    getProfessionals: (typeUser, page, perPage) => dispatch(getProfessionals(typeUser, page, perPage)),
    openModal: (modal, payload) => dispatch(openModal(modal, payload)),
    getStatus: () => dispatch(getStatus()),
    searchCalls: (data) => dispatch(searchCalls(data)),
    destroyCall: (data) => dispatch(destroyCall(data)),
    getAutocompleteProfessional: (typeUser, page, perPage, name) => dispatch(getAutocompleteProfessional(typeUser, page, perPage, name)),
    getAutocompletePatient: (typeUser, page, perPage, name) => dispatch(getAutocompletePatient(typeUser, page, perPage, name)),
    multiplesDestroy: (calls, user, dayWeek, professional, treatment) => dispatch(multiplesDestroy(calls, user, dayWeek, professional, treatment)),
    setProfessional: (profissional) => dispatch(setProfessional(profissional)),
    setStartDate: (startDate) => dispatch(setStartDate(startDate)),
    setEndDate: (endDate) => dispatch(setEndDate(endDate)),
    setPatient: (patient) => dispatch(setPatient(patient)),
    setStatuss: (status) => dispatch(setStatuss(status)),
    setProfessionalSelected: (data) => dispatch(setProfessionalSelected(data)),
    setPatientSelected: (data) => dispatch(setPatientSelected(data)),
    getTypeSchedules: () => dispatch(getTypeSchedules()),
    getTreatment: () => dispatch(getTreatment())
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
