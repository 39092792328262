import React, { useEffect, useState } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import { DateTime } from "luxon";
import ContentLoader from "react-content-loader";
import {createID, searchCalls} from "../../store/actions/calendarActions";
import {openAny, openModal} from "../../store/actions/uiActions";
import {getTreatment} from "../../store/actions/treatmentActions";
import {getStatus} from "../../store/actions/companiesActions";
import {calcularMoney, formatDateNotHour, formatHour} from "../../utils";
import { BadgeTypeScheduler } from "../../components/BadgeTypeScheduler";
import { Field } from "../../components/Field";
import SearchReceiveForCalls from "./SearchReceiveForCalls";
import Img5 from "../../assets/img/5.png";
import api from "../../api";

function List({value,isCheck,setIsCheck,createID}){
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        if(!value.id){
            setLoading(true)
            createID(value)
            setLoading(false)
        }
    },[value])


    return (
        <tr key={value.id}>
            <td>
                {value.id && !value.finance ? <Field
                    value={isCheck.includes(value.id)}
                    type="checkbox"
                    onChange={() => {
                        if (isCheck.indexOf(value.id) !== -1) {
                            setIsCheck(oldArray => oldArray.filter(item => item !== value.id));
                        } else {
                            setIsCheck(oldArray => [...oldArray, value.id]);
                        }
                    }}
                    style={{ width: 20, height: 20 }}
                />: (loading ? "" : <span><b>Vinculado</b></span>)}
            </td>
            <td style={{ textAlign: "left" }}><span>{value.patient?.name}</span></td>
            <td style={{ textAlign: "left" }}><span>{value.professional?.name}</span></td>
            <td style={{ textAlign: "left" }}><span>{value.treatment.specialization.profession && value.treatment.specialization.profession.description} {value.treatment.specialization.description }  ({value.treatment.time} minutos)</span></td>
            <td style={{ textAlign: "left" }}>
                <span>{formatDateNotHour(DateTime.fromSQL(value.start))} {formatHour(DateTime.fromSQL(value.start))} - {formatHour(DateTime.fromSQL(value.end))}</span>
            </td>
            <td>
                <div style={{display: "flex",

                    placeContent: "center space-evenly",
                    flexWrap: "wrap",
                    alignitems: "center",
                    justifyContent: "space-around",
                    alignContent: "center",
                    flexDirection: "column",
                    gap:5}}>{renderBadges(value)} {/* Renderiza as badges dinamicamente */}</div>
            </td>
            <td style={{ textAlign: "left" }}><span>{value.service?.name || 'Sem serviço'}</span></td>
            <td style={{ textAlign: "left" }}><span>{
                value?.service?.id ?
                    (value.service.total/100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    :
                    <>R$ 0,00</>
            }</span></td>
        </tr>
    )
}

const renderBadges = (value) => {
    const badges = [];

    if (value?.type_schedule) {
        badges.push(<BadgeTypeScheduler key="type_schedule" data={value.type_schedule} />);
    }

    if (value?.type_address === 4) {
        badges.push(<div key="teleatendimento" className="badge badge-info">Teleatendimento</div>);
    }

    if (value?.replacement) {
        badges.push(<div key="replacement" className="badge badge-light-warning">Reposição</div>);
    }

    if (value?.rescheduled) {
        badges.push(<div key="rescheduled" className="badge badge-primary" style={{ backgroundColor: '#ffc107' }}>Remarcado</div>);
    }

    if (value?.signed_document) {
        badges.push(<div key="signed_document" className="badge badge-success">Atendimento assinado</div>);
    }

    if (value?.status) {
        badges.push(<div key="signed_document" className="badge badge-success" style={{background: value.status.color,overflowWrap: 'break-word',
            whiteSpace: 'normal'}}>{value.status.description}</div>);
    }

    return badges;
};

function ReceiveForCallsController({ searchCalls, items, openModal, openAny, createID, isMobile, layoutRef, loading, treatment, getTreatment, getStatus, status}) {

    const [isCheck, setIsCheck] = useState([]);
    const [serviceView, setServiceView] = useState([]);
    const [description, setDescription] = useState('');
    const [servicesTotal, setServicesTotal] = useState(0);
    const [serviceFinance, setServiceFinance] = useState(0);
    const [company, setCompany] = useState({});
    const dispatch = useDispatch();
    const [loadingView,setLoadingView] = useState(true);

    useEffect(() => {
        dispatch({type:'CALENDAR_START'})
        dispatch({type:'CALENDAR_SUCCESS',itens:[]})

        setTimeout(function (){
            setLoadingView(false);
            dispatch({type:'CALENDAR_START'});
            dispatch({type:'CALENDAR_SUCCESS',itens:[]})
        }, 10000)

        getTreatment();
        getStatus();
    },[])

    useEffect(() => {
        if (items?.length > 0) {
            setIsCheck(items.filter(value => !value.finance).map(li => li.id));
        }
    }, [items]);

    useEffect(() => {
        if (isMobile) {
            layoutRef.current.classList.remove('container-xxl');
        }
    }, [isMobile]);

    const onSubmit = (request) => {
        searchCalls({
            professional: request.professional > 0 ? [request.professional] : [],
            patient: request.patient > 0 ? [request.patient] : [],
            status: Array.isArray(request.status) ?  request.status?.map(value => value.value) : [],
            dateStart: request.start_date,
            dateEnd: request.end_date,
            type_schedule: [],
            room: [],
            treatment: request.treatment > 0 ? [request.treatment] : []
        });
    }

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if(auth.company.spedy_api_key){
            setCompany(auth.company)
        }else{
            api.get('/company/'+auth.company.id,{
                headers: {
                    'Authorization': 'Bearer ' + auth.token,
                }
            }).then(data => setCompany(data.data))
        }
    }, [auth]);

    const getService = async (specialization) => {
        try{
            const service = await api.post('/invoice/service',{
                'specialization':specialization
            },{
                headers: {
                    'Authorization': 'Bearer ' + auth.token,
                },
            })

            return service.data;
        }catch (e){
            return {}
        }
    }

    useEffect(async () => {
        if (items?.length && isCheck?.length) {
            const itemsSelect = items.filter(item => isCheck.includes(item.id))

            const services = itemsSelect.filter(item => isCheck.includes(item.id))
                .filter(value => value.service?.id > 0)
                .reduce((res, at) => {
                    if (typeof res[at.service.id] === "undefined") res[at.service.id] = [];
                    res[at.service.id].push(at);
                    return res;
                }, {});

            let total = 0;
            const serviceViews = Object.values(services).map(value => {
                const unidade = calcularMoney(value[0].service.total);
                const quantidade = value.length;
                const totalValue = unidade * quantidade;
                total += totalValue;

                return {
                    'title': "<b>" + quantidade + "x </b>" + value[0].service.name,
                    'unidade': unidade.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                    }),
                    'total': totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                };
            });

            const groupedByProfessional = itemsSelect.reduce((acc, appt) => {
                const professional = appt.professional;
                const userProfession = professional.user_profession?.find(up =>
                    up.profession.id === appt.treatment.specialization.profession.id
                );

                const document = userProfession?.document || null;
                const key = JSON.stringify({ name: professional.name, document });

                if (!acc[key]) acc[key] = {};

                const formattedDate = DateTime.fromSQL(appt.start_date).toFormat("dd/MM/yyyy");
                const monthYear = DateTime.fromSQL(appt.start_date).setLocale('pt-BR').toFormat("MMMM 'de' yyyy");

                if (!acc[key][monthYear]) acc[key][monthYear] = {};
                acc[key][monthYear][formattedDate] = (acc[key][monthYear][formattedDate] || 0) + 1;

                return acc;
            }, {});

            let formattedText = Object.entries(groupedByProfessional).map(([key, monthsObj]) => {
                const { name: professionalName, document } = JSON.parse(key);
                const documentText = document ? ` (${document})` : '';

                const monthlyTexts = Object.entries(monthsObj).map(([monthYear, datesObj]) => {
                    const totalAtendimentos = Object.values(datesObj).reduce((sum, count) => sum + count, 0);
                    const atendimentoLabel = totalAtendimentos === 1 ? '1 atendimento realizado no dia' : `${totalAtendimentos} atendimentos realizados nos dias`;

                    // Ordena as datas dentro do mês
                    const sortedDates = Object.keys(datesObj)
                        .map(date => ({
                            dateObj: DateTime.fromFormat(date, "dd/MM/yyyy"),
                            count: datesObj[date]
                        }))
                        .sort((a, b) => a.dateObj.toMillis() - b.dateObj.toMillis());

                    const daysFormatted = sortedDates.map(({ dateObj }) => dateObj.toFormat("dd")).join(", ");

                    // Verifica se há dias com mais de um atendimento
                    const multipleAttendances = sortedDates
                        .filter(({ count }) => count > 1)
                        .map(({ dateObj, count }) => `${count} atendimentos no dia ${dateObj.toFormat("dd")}`)
                        .join(" e ");

                    return `${atendimentoLabel} ${daysFormatted} de ${monthYear}.` + (multipleAttendances ? ` Sendo ${multipleAttendances} de ${monthYear}.` : '');
                });

                return `Pelo(a) profissional ${professionalName}${documentText}:\n${monthlyTexts.join("\n")}`;
            }).join("\n\n");
            setServiceView(serviceViews);
            setDescription(formattedText)

            setServicesTotal(total);

            const service = await getService(itemsSelect[0]?.treatment?.id);

            setServiceFinance(service?.companyService)

        }
    }, [isCheck, items]);

    const Filter = <SearchReceiveForCalls onSubmit={onSubmit} treatment={treatment} status={status}  />

    return (
        <div className="card">
            <div className="card-header">
                <div className="card-title">
                    Receita por atendimentos
                </div>
                <div className="card-toolbar">
                    <div
                        className="btn btn btn-light btn-sm btn-sm"
                        onClick={() => openAny(Filter, 'Filtro', 'lg')}
                    >
                        <span className="svg-icon svg-icon-black ">
                            <svg
                                width="20"
                                height="24"
                                viewBox="0 0 20 20"
                                fill="#000"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="#000"
                                />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="#000"
                                />
                            </svg>
                        </span>
                        Filtro
                    </div>
                </div>
            </div>
            <div className="card-body">
                {
                    loading || loadingView ?
                        <table>
                            {

                                [1,2,3].map((value,index) =>{
                                    return(
                                        <tr key={index}>
                                            <td style={{textAlign:'left'}}>
                                                <ContentLoader viewBox="0 0 380 50">
                                                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                </ContentLoader>
                                            </td>
                                            <td style={{textAlign:'left',width:100}}>
                                                <ContentLoader viewBox="0 0 380 50">
                                                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                </ContentLoader>
                                            </td>
                                            <td style={{textAlign:'left'}}>
                                                <ContentLoader viewBox="0 0 380 70">
                                                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                </ContentLoader>
                                            </td>
                                            <td style={{textAlign:'left'}}>
                                                <ContentLoader viewBox="0 0 380 35">
                                                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                </ContentLoader>
                                            </td>
                                            <td style={{textAlign:'left'}}>
                                                <ContentLoader viewBox="0 0 380 50">
                                                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                </ContentLoader>
                                            </td>
                                            <td style={{textAlign:'left',width:100}}>
                                                <ContentLoader viewBox="0 0 380 50">
                                                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                </ContentLoader>
                                            </td>
                                            <td style={{textAlign:'left'}}>
                                                <ContentLoader viewBox="0 0 380 70">
                                                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                </ContentLoader>
                                            </td>
                                            <td style={{textAlign:'left'}}>
                                                <ContentLoader viewBox="0 0 380 35">
                                                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                </ContentLoader>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                        :
                        items.length > 0 ?
                            <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                    <thead>
                                    <tr className="fw-bolder text-muted">
                                        <th style={{ textAlign: "left" }}>

                                        </th>
                                        <th style={{ textAlign: "left" }}>Paciente</th>
                                        <th style={{ textAlign: "left" }}>Terapeuta</th>
                                        <th style={{ textAlign: "left" }}>Tratamento</th>
                                        <th style={{ textAlign: "left" }}>Data</th>
                                        <th style={{
                                            display: "flex",
                                            flex: 1,
                                            placeContent: "center space-evenly",
                                            flexWrap: "nowrap",
                                            alignItems: "flex-start",
                                            justifyContent: "space-around",
                                            alignContent: "center",
                                            flexDirection: "row",
                                            gap: 3,
                                            textAlign: "left"
                                        }}>Informações</th>
                                        <th style={{ textAlign: "left" }}>Serviço</th>
                                        <th style={{ textAlign: "left" }}>Valor</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        items?.map((value,index) => {
                                            return (
                                                <List key={index} createID={createID} value={value} isCheck={isCheck} setIsCheck={setIsCheck} />
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="d-flex flex-column flex-center" style={{paddingTop: 50}}>
                                <img src={Img5} style={{maxWidth: 330}} alt={""}/>
                                <div className="text-dark mb-4" style={{fontSize: 18, textAlign: "center"}}>
                                    Utilize o filtro para buscar os atendimentos
                                </div>
                            </div>
                }

                {
                    !loadingView && !loading && items.length > 0 && serviceView.length > 0 ?
                        <>
                            <table className="table  table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <tr>
                                    <th style={{ textAlign: 'right' }}><span>Quantos serviços</span></th>
                                    <th style={{ textAlign: 'right' }}><span>Unidade</span></th>
                                    <th style={{ textAlign: 'right' }}><span>Total</span></th>
                                </tr>
                                {
                                    serviceView.map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ textAlign: 'right' }} ><span dangerouslySetInnerHTML={{ __html: value.title }}></span></td>
                                                <td style={{ textAlign: 'right' }}><span>{value.unidade}</span></td>
                                                <td style={{ textAlign: 'right' }}><span>{value.total}</span> </td>
                                            </tr>
                                        );
                                    })
                                }
                                <tr style={{ borderTop: '1px dashed #000' }}>
                                    <td style={{ textAlign: 'right' }}></td>
                                    <td style={{ textAlign: 'right' }}><b>Total</b></td>
                                    <td style={{ textAlign: 'right' }}><b>{servicesTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b></td>
                                </tr>
                            </table>
                        </>
                    :
                    <></>
                }
                {
                    !loadingView && !loading && items.length > 0 ?
                        <div style={{display:"flex",justifyContent: isMobile ?  "space-between" : "flex-end" , flexDirection:  isMobile ?  "row-reverse" : "row"}}>
                            {company.spedy_api_key && <div  style={{marginRight:10}}>
                                {description && <button className="btn btn-info btn-sm" onClick={() => openModal('finance',{
                                    total: servicesTotal*100,
                                    permission:'receive',
                                    document_type:1,
                                    user: items[0].patient,
                                    typeModel:1,
                                    document_service:serviceFinance,
                                    document_description: description,
                                    calls:items.filter(item => isCheck.includes(item.id)).map(item => item.id),
                                    viewCall:true,
                                    viewInvoice:true
                                })}>Criar receita e nota fiscal</button>}
                            </div>}
                            <div >
                                {description && <button className="btn btn-success btn-sm" onClick={() => openModal('finance',{
                                    total: servicesTotal*100,
                                    permission:'receive',
                                    user: items[0].patient,
                                    typeModel:1,
                                    document_service:serviceFinance,
                                    document_description: description,
                                    calls:items.filter(item => isCheck.includes(item.id)).map(item => item.id),
                                    viewCall:true
                                })}>Criar receita</button> }
                            </div>
                        </div>
                        :
                        <></>
                }

            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    items: state.calendar.itens,
    loading: state.calendar.loading,
    treatment: state.treatment.itens,
    status: state.companies.status,
});

const mapDispatchToProps = dispatch => ({
    searchCalls: (data) => dispatch(searchCalls(data)),
    openModal:(modal,payload) => dispatch(openModal(modal,payload)),
    openAny:(children,title,size) => dispatch(openAny(children,title,size)),
    createID:(item) => dispatch(createID(item)),
    getTreatment: () => dispatch(getTreatment()),
    getStatus: () => dispatch(getStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveForCallsController);