import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import React,{ useEffect } from "react";
import { Button } from "../Button";
import {ddi} from "../../utils";

function ResponsibleModal({loading,store,auth,data,update,getTypeResponsible,typeResponsible}) {

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            name: data ? data.name : null,
            sex: data ? data.sex : null,
            birth_date: data ? data.birth_date : null,
            cellphone: data && data.cellphone ?  data.cellphone  : null,
            email:data ? data.email : null,
            cpf: data ? data.cpf : null,
            rg:data ? data.rg : null,
            profession:data ? data.profession : null,
            parentesco: data && data.type ? data.type : null,
            main: data && data.main ? data.main : null,
            school: data ? data.school : null,
            school_observation: data ? data.school_observation : null,
            id:data ? data.id : 0,
            invoice: data && data.invoice ? data.invoice : null,
            ddi: data.ddi ? data.ddi : '55',
            marital_status: data.marital_status ? data.marital_status : null
        }
    });

    const maritalStatus = [
        {
            label:'Solteiro(a)',
            value: 1
        },
        {
            label:'Casado(a)',
            value: 2
        },
        {
            label:'Viúvo(a)',
            value: 3
        },
        {
            label:'Divorciado(a)',
            value: 4
        },
        {
            label:'Separado(a)',
            value: 5
        }
    ];

    useEffect(()=>{
        getTypeResponsible();
    },[getTypeResponsible])

    const onSubmit = (request)=>{
        request['patient'] = data.patient;
        request['type'] = 4;


        if(data && data.id){
            update(request,data.id,"responsible");
        }else{
            request['company'] =  auth.company.id ? auth.company.id : null;
            store(request,"responsible")
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Nome:</label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange}   />}
                    />
                    {errors.name && <p style={{color:'red',marginTop:10}}>Necessário preencher o nome.</p>}
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Sexo:</label>
                    <Controller
                        name="sex"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field  type="select" options={[{'label':'Masculino',"value":1},{'label':'Feminino','value':2}]} value={value}  onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Data de Nascimento:</label>
                    <Controller
                        name="birth_date"
                        control={control}
                        rules={{ required: false }}
                        render={({ field : { onChange,  value }}) => <Field type="dateNative" value={value}  onChange={onChange}   />}
                    />
                    {errors.birth_date && <p style={{color:'red',marginTop:10}}>Necessário preencher a data de nascimento.</p>}
                </div>
                <div className="col-lg-5">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Celular:</label>
                    <div style={{display:'flex'}}>
                        <div style={{marginRight:5,width:200}}>
                            <Controller
                                name="ddi"
                                control={control}
                                render={({ field : { onChange, value }}) => <Field options={ddi} type="select" value={value}  onChange={onChange}   />}
                            />
                        </div>
                        <Controller
                            name="cellphone"
                            control={control}
                            render={({ field : { onChange, value }}) => <Field  type="cellphone" value={value}  onChange={onChange}   />}
                        />
                    </div>
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>CPF:</label>
                    <Controller
                        name="cpf"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="cpf" value={value}  onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Email:</label>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="email" value={value}  onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>RG:</label>
                    <Controller
                        name="rg"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="text" value={value}  onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Estado civil:</label>
                    <Controller
                        name="marital_status"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="select"
                                value={value}
                                options={maritalStatus}
                                onChange={onChange}
                            />
                        }
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Profissão:</label>
                    <Controller
                        name="profession"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="text" value={value}  onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Parentesco:</label>
                    <Controller
                        name="parentesco"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field type="select" value={value}  options={typeResponsible} onChange={onChange}   />}
                    />
                    {errors.parentesco && <p style={{color:'red',marginTop:10}}>Necessário preencher o parentesco.</p>}
                </div>

                <div className="col-lg-3" style={{paddingTop:27}}>
                    <Controller
                        name="main"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="checkbox" title="Principal" value={value} name="main" onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-3" style={{paddingTop:27}}>
                    <Controller
                        name="invoice"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="checkbox" title="Financeiro" value={value} name="invoice" onChange={onChange}   />}
                    />
                </div>
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-10"></div>
                <div className="col-md-2">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>
            </div>
        </form>
    );
}

export default ResponsibleModal
