import {DateTime} from "luxon";
import React, {useState, useEffect, useMemo} from 'react';
import FinanceMobile from "./FinanceMobile";
import FinanceDesktop from "./FinanceDesktop";
import Confirm from "../Confirm";
import Filter from "./Filter";


function ModalConfirm({setConfirmOne,destroy,value,permission,setConfirmTask,destroyFinanceTask,confirmTask,confirmOne}) {
    return (
        <>
            <Confirm
                title={"Deseja realmente excluir essa " + (permission === "pay" ? "conta" : "receita") + "?"}
                open={confirmOne}
                closeModal={() => setConfirmOne(false)}
                onClick={() => destroy(value)}
            />
            <Confirm
                title={"Deseja realmente excluir a " + (permission === "pay" ? "conta" : "receita") + "?"}
                open={confirmTask}
                buttons={[
                    {
                        name: 'Excluir somente essa', onClick: () => {
                            destroy(value)
                        }, class: 'btn-primary'
                    },
                    {
                        name: 'Excluir também as do futuro', onClick: () => {
                            destroyFinanceTask(value)
                        }, class: 'btn-secondary'
                    }
                ]}
                closeModal={() => setConfirmTask(false)}
                onClick={() => destroy(value)}
            />

        </>
    )
}


function Finance({openAny,bankAccountId,setBankAccountId,typePayment,setTypePayment,setNota,nota,typeDate,start,setTypeDate,items,isMobile, layoutRef, patient, name, permission, typeModel, openModal, setHeaderNavigation, auth, loading, title, destroyFinanceTask, report,destroy,dateStart,dateEnd,typePayments,setDateEnd,setDateStart}) {
    let valueTotal = 0;
    let valueAvencer = 0;
    let valueVencem = 0;
    let valueRecebidos = 0;
    let valueVencidos = 0;
    let valuePagos = 0;
    const [value, setValue] = useState({});
    const [confirmOne, setConfirmOne] = useState(false);
    const [confirmTask, setConfirmTask] = useState(false);
    const [search, setSearch] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeFilter, setActiveFilter] = useState(null); // Armazena o filtro ativo (função)

    useEffect(() => {
        let filteredItems = items;

        if (activeFilter) {
            // Reaplica o filtro ativo
            filteredItems = filteredItems.filter(activeFilter);
        }

        if (searchTerm) {
            // Aplica o termo de busca ao resultado filtrado
            filteredItems = filteredItems.filter((item) =>
                (permission === 'receive' &&
                    (typeModel === 1
                        ? item.user.name?.toLowerCase().includes(searchTerm)
                        : item.company_user?.description?.toLowerCase().includes(searchTerm))) ||
                (item.description && item.description?.toLowerCase().includes(searchTerm))
            );
        }

        setSearch(filteredItems);
    }, [items, activeFilter, searchTerm]);

    const applyFilter = (filterFn) => {
        setActiveFilter(() => filterFn); // Atualiza o filtro ativo
        const filteredItems = items.filter(filterFn); // Filtra com base no novo filtro

        setSearch(filteredItems);
    };

    const handlerVencidos = () => applyFilter((it) => it.status?.id === 3);

    const handlerPagos = () => applyFilter((it) => it.status?.id === 4);

    const handlerRecebidos = () => applyFilter((it) => it.status?.id === 2);

    const handlerTodos = () => applyFilter(() => true); // Mostra todos os itens

    const handlerVencem = () =>
        applyFilter((it) => {
            const now = DateTime.now();
            const dueDate = DateTime.fromISO(it.due_date);
            return it.status?.id === 1 && now.hasSame(dueDate, 'day');
        });

    const handlerAvencer = () => applyFilter((it) => it.status?.id === 1);

// Busca por termo
    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term); // Atualiza o termo de busca
    };

    useEffect(() => {
        setSearch(items);
    }, []);

    useEffect(() => {
        if (isMobile) {
            layoutRef.current.classList.remove('container-xxl');
            setHeaderNavigation([
                {
                    description: "Adicionar " + (permission === 'pay' ? "conta" : "receita"),
                    seletor: () => {
                        openModal('finance', {
                            user: {id: patient, name: name},
                            permission: permission,
                            typeModel: typeModel
                        })
                    },
                    permission: permission + '.store'
                }
            ])
        }
    }, [isMobile, layoutRef, patient, name, permission, typeModel, openModal, setHeaderNavigation]);

    if (items && items.length > 0) {

        items.forEach(element => {
            valueTotal = (parseFloat(valueTotal) + parseFloat(element.total))
        });


        let avencer = items.filter((it) => it.status && it.status.id === 1);

        avencer.forEach(element => {
            valueAvencer = (parseFloat(valueAvencer) + parseFloat(element.total))
        });

        let vencem = items.filter((it) => {
            let now = DateTime.now();
            let dueDate = DateTime.fromISO(it.due_date);

            // Verifica se o status é 1 e se a data de vencimento é no mesmo dia que hoje
            return it.status.id === 1 &&
                now.hasSame(dueDate, 'day');
        });

        vencem.forEach(element => {
            valueVencem = (parseFloat(valueVencem) + parseFloat(element.total))
        });

        let recebidos = items.filter((it) => it.status.id === 2);

        recebidos.forEach(element => {
            valueRecebidos = (parseFloat(valueRecebidos) + parseFloat(element.total))
        });

        let pagos = items.filter((it) => it.status.id === 4);

        pagos.forEach(element => {
            valuePagos = (parseFloat(valuePagos) + parseFloat(element.total))
        });

        let vencidos = items.filter((it) => it.status.id === 3);

        vencidos.forEach(element => {
            valueVencidos = (parseFloat(valueVencidos) + parseFloat(element.total))
        });
    }

    const FilterView = useMemo(() => {

        if(typePayments?.length === 0) return null;

        return <Filter
            permission={permission}
            typePayments={typePayments}
            setTypeDate={setTypeDate}
            dateEnd={dateEnd}
            dateStart={dateStart}
            setDateEnd={setDateEnd}
            setDateStart={setDateStart}
            start={start}
            typeDate={typeDate}
            nota={nota}
            setNota={setNota}
            setTypePayment={setTypePayment}
            typePayment={typePayment}
            setBankAccountId={setBankAccountId}
            bankAccountId={bankAccountId}
        />
    },[typePayments])

    if(isMobile){
        return (
            <>
                <ModalConfirm
                    destroyFinanceTask={destroyFinanceTask}
                    setConfirmOne={setConfirmOne}
                    confirmOne={confirmOne}
                    destroy={destroy}
                    value={value}
                    permission={permission}
                    setConfirmTask={setConfirmTask}
                    confirmTask={confirmTask}
                />
                <FinanceMobile
                    handlerTodos={handlerTodos}
                    handlerVencidos={handlerVencidos}
                    handlerPagos={handlerPagos}
                    handlerRecebidos={handlerRecebidos}
                    handlerVencem={handlerVencem}
                    handlerAvencer={handlerAvencer}
                    valueTotal={valueTotal}
                    valueAvencer={valueAvencer}
                    valueVencem={valueVencem}
                    valueRecebidos={valueRecebidos}
                    valueVencidos={valueVencidos}
                    valuePagos={valuePagos}
                    title={title}
                    patient={patient}
                    permission={permission}
                    typeModel={typeModel}
                    auth={auth}
                    loading={loading}
                    openModal={openModal}
                    name={name}
                    report={report}
                    setValue={setValue}
                    value={value}
                    setSearch={setSearch}
                    search={search}
                    handleSearch={handleSearch}
                    searchTerm={searchTerm}
                    setConfirmTask={setConfirmTask}
                    setConfirmOne={setConfirmOne}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    destroy={destroy}
                    filter={FilterView}
                    openAny={openAny}
                />
            </>
        );
    }

    return (
        <>
            <ModalConfirm
                destroyFinanceTask={destroyFinanceTask}
                setConfirmOne={setConfirmOne}
                confirmOne={confirmOne}
                destroy={destroy}
                value={value}
                permission={permission}
                setConfirmTask={setConfirmTask}
                confirmTask={confirmTask}

            />
            <FinanceDesktop
                handlerPagos={handlerPagos}
                handlerVencidos={handlerVencidos}
                handlerTodos={handlerTodos}
                handlerRecebidos={handlerRecebidos}
                handlerVencem={handlerVencem}
                handlerAvencer={handlerAvencer}
                valueTotal={valueTotal}
                valueAvencer={valueAvencer}
                valueVencem={valueVencem}
                valueRecebidos={valueRecebidos}
                valueVencidos={valueVencidos}
                valuePagos={valuePagos}
                title={title}
                patient={patient}
                permission={permission}
                typeModel={typeModel}
                auth={auth}
                loading={loading}
                openModal={openModal}
                name={name}
                report={report}
                setValue={setValue}
                value={value}
                setSearch={setSearch}
                search={search}
                handleSearch={handleSearch}
                searchTerm={searchTerm}
                setConfirmTask={setConfirmTask}
                setConfirmOne={setConfirmOne}
                dateStart={dateStart}
                destroy={destroy}
                filter={FilterView}
                openAny={openAny}
                typePayments={typePayments}
            />
        </>
    );
}

export default Finance;
