import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import React, { useEffect } from "react";
import { Button } from "../Button";
import Spinner from "react-activity/dist/Spinner";

function AddressModal({data,storeAddress,patient,loading,updateAddress,getForCep,typeAddress,getTypeAddress,searchLoading}) {

    const {control, handleSubmit, formState: { errors },setValue } = useForm({
        defaultValues:{
            zipcode:data ? data.zipcode : '',
            state:data ? data.state : '',
            city:data ? data.city : '',
            neighborhood:data ? data.neighborhood : '',
            street:data ? data.street : '',
            number:data ? data.number : '',
            complement:data ? data.complement : '',
            user:patient.id,
            type:data && data.type ? data.type.id : null,
            invoice: data && data.invoice ? data.invoice : null,
            ibge: data && data.ibge ? data.ibge : null
        }
    });

    useEffect(()=>{
        getTypeAddress()
    },[getTypeAddress])

    const onSubmit = (request)=>{
        if(data && data.id){
            updateAddress(data.id,request)
        }else{
            storeAddress(request);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>CEP:</label>
                    <Controller
                        name="zipcode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field maxLength="9" type="text" value={value}  onChange={onChange} onBlur={(value) => getForCep(value,setValue)}  />}
                    />
                    {errors.zipcode && <p style={{color:'red',marginTop:10}}>Necessário preencher o cep.</p>}
                </div>
                <div className="col-lg-6" style={{paddingTop:37}}>
                    { searchLoading  && <Spinner /> }
                </div>
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Estado:</label>
                    <Controller
                        name="state"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field  maxLength="2" type="text" value={value}  onChange={onChange}  />}
                    />
                    {errors.state && <p style={{color:'red',marginTop:10}}>Necessário preencher o estado.</p>}

                </div>
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Cidade:</label>
                    <Controller
                        name="city"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field type="text" value={value}  onChange={onChange}  />}
                    />
                    {errors.state && <p style={{color:'red',marginTop:10}}>Necessário preencher a cidade.</p>}
                </div>
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Bairro:</label>
                    <Controller
                        name="neighborhood"
                        control={control}                        
                        render={({ field : { onChange, onBlur, value }}) => <Field type="text" value={value}  onChange={onChange}  />}
                    />
                </div>
                <div className="col-lg-8">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Rua:</label>
                    <Controller
                        name="street"
                        control={control}                       
                        render={({ field : { onChange, onBlur, value }}) => <Field type="text" value={value}  onChange={onChange}  />}
                    />
                </div>
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Número:</label>
                    <Controller
                        name="number"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field type="number" value={value}  onChange={onChange}  />}
                    />
                    {errors.number && <p style={{color:'red',marginTop:10}}>Necessário preencher o número.</p>}
                </div>
                <div className="col-lg-9">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Complemento:</label>
                    <Controller
                        name="complement"
                        control={control}                        
                        render={({ field : { onChange, onBlur, value }}) => <Field type="text" value={value}  onChange={onChange}  />}
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Tipo:</label>
                    <Controller
                        name="type"
                        control={control}        
                        rules={{ required: true }}                
                        render={({ field : { onChange, onBlur, value }}) => <Field type="select" value={value} options={typeAddress}  onChange={onChange}  />}
                    />
                    {errors.type && <p style={{color:'red',marginTop:10}}>Necessário preencher o tipo.</p>}
                </div>
                <div className="col-lg-4" style={{paddingTop:27}}>
                    <Controller
                        name="invoice"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="checkbox" title="Endereco de cobrança" value={value} name="invoice" onChange={onChange}   />}
                    />
                </div>
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-10">

                </div>
                <div className="col-md-2">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>   
            </div>
        </form>
    );
}

export default AddressModal