import React, { useEffect,useState } from "react";
import {calcularMoney, checkPermission, formatDate, formatDateNotHour} from "../../utils";
import {DateTime} from "luxon";
import {Helper} from "../Helper";

function UserPrivateList({value,openModal,destroyPatientPrivate,auth,closeModal}){

    const [novo,setNovo] = useState();

    useEffect(() => {
        function click(ev) {
            if(ev.srcElement.closest('#novoResponsible') == null){
                setNovo(false);
            }
        }

        if(novo){
            window.addEventListener('click',click,false);
            return () => {
                window.removeEventListener('click',click,false);
            };
        }

    }, [novo]);

    const typeTask = (type) => {
        switch (type) {
            case 1:
                return "Todo dia";
            case 2:
                return "Toda semana";
            case 3:
                return "A cada 14 dias";
            case 4:
                return "Todo mês";
            case 5:
                return "Todo ano";
            default:
                return "";
        }
    }

    const day = {
        0:"domingo",
        1:"segunda-feira",
        2:"terça-feira",
        3:"quarta-feira",
        4:"quinta-feira",
        5:"sexta-feira",
        6:"sábado"
    }

    const atendimentos = () => {
        let tasks = value.task;

        return tasks.map((ta,index) => {
            return (
                <div key={index}><div className="badge badge-light-primary">{typeTask(ta.type)} { ta.type === 2 ? day[ta.weeks] : ''} <span>às {ta.hour}</span> </div><br/></div>
            )
        })
    }

    const servicesValue = () => {
        let tasks = value.task;

        return tasks.map((ta,index) => {

            return (
                <div key={index}>{ ta.service && ta.service.id ? calcularMoney(ta.service.total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'Não há serviço'}</div>
            )
        })
    }

    const servicesCommission = () => {
        let tasks = value.task;

        return tasks.map((ta,index) => {

            return (
                <div key={index}>
                    {   ta.service && ta.service.id  ?
                        ta.service.type_commission === 1 ?
                            calcularMoney(ta.service.commisission_fixed).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                            :
                            calcularMoney((ta.service.total*ta.service.commisission_percentage)/100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})+" ("+ta.service.commisission_percentage+"%)"
                        :

                        <div>Não há serviço</div>
                    }
                </div>
            )
        })
    }


    const types = ["Terapeuta","Lider","Coordenador(a)"]

    return(
        <tr key={value.id}>
            <td style={{textAlign:'left'}}>
                { value && value.company_specialization && value.company_specialization.specialization && value.company_specialization.specialization.profession && value.company_specialization.specialization.profession.description } { value && value.company_specialization && value.company_specialization.specialization && value.company_specialization.specialization.description } ({value.company_specialization.time} minutos)
                <div style={{marginLeft :10}} className="badge badge-light-success">{types[value.leader]}</div>
            </td>
            <td style={{textAlign:'left', color: value.status && value.status.color, fontWeight:'bold' }}>{ value.status && value.status.description }<br/> <small className="align-items-center text-gray-400"> { value.status_date ? formatDate(DateTime.fromSQL(value.status_date)) : <></> }</small></td>
            <td style={{textAlign:'left'}}> { value.task && value.task.length > 0 ? <div>{ atendimentos() }</div> : <>Não há recorrência</> } </td>
            { checkPermission(auth.permissions,'service.show') ? <td style={{textAlign:'left'}}> { value.task && value.task.length > 0 ? <div>{ servicesValue() }</div> : <>Não há serviço</> } </td> : <></>}
            { checkPermission(auth.permissions,'service.show') ? <td style={{textAlign:'left'}}> { value.task && value.task.length > 0 ? <div>{ servicesCommission() }</div> : <>Não há serviço</> } </td> : <></> }
            <td style={{textAlign:'left'}}> { value.report_date ? formatDateNotHour(DateTime.fromISO(value.report_date)) : <>Data não informada</> }</td>
            <td style={{textAlign:'left'}}> { value.next_report_date ? formatDateNotHour(DateTime.fromISO(value.next_report_date)) : <>Data não informada</>}</td>
            <td>
                {
                    !checkPermission(auth.permissions,'userprivate.update') &&  !checkPermission(auth.permissions,'userprivate.destroy') ?
                        <></>
                        :
                        <button  className="btn btn-sm btn-icon  btn-active-color-primary" style={{float:'right'}} onClick={()=> setNovo(true) }>
                                <span className="svg-icon svg-icon-muted svg-icon-2hx">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                                    </svg>
                                </span>
                        </button>
                }
                <div className={"dropdown-menu "+(novo ? "show" : '')} style={{marginTop:40,right:33}} id="novoResponsible" >
                    {checkPermission(auth.permissions,'userprivate.update') && <button className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{closeModal() ;openModal('userprivate',value);setNovo(false)}}>Editar</button>}
                    {checkPermission(auth.permissions,'userprivate.destroy') && <button className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{ closeModal() ;destroyPatientPrivate(value);setNovo(false);}}>Excluir</button>}
                </div>
            </td>
        </tr>
    )
}


function UserPrivateCallsModal({data,auth,openModal,destroyPatientPrivate,closeModal}) {

    return (
        <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">

                    <th style={{textAlign:'left'}}>Tratamento</th>
                    <th style={{textAlign:'left'}}>
                        Status
                    </th>
                    <th style={{textAlign:'left'}}>
                        Atendimento
                        <Helper  title="Este campo contém informações sobre o cadastro recorrente de atendimentos, incluindo o tipo de recorrência (diário, semanal ou quinzenal) e horário." />
                    </th>
                    { checkPermission(auth.permissions,'service.show') ? <th style={{textAlign:'left'}}>
                        Valor
                        <Helper  title="O valor se refere ao serviço vinculado ao atendimento recorrente." />
                    </th> : <></>}
                    { checkPermission(auth.permissions,'service.show') ? <th style={{textAlign:'left'}}>
                        Comissão
                        <Helper  title="A comissão se refere ao serviço vinculado ao atendimento recorrente." />
                    </th> : <></>}
                    <th style={{textAlign:'left'}}>E. Relatório</th>
                    <th style={{textAlign:'left'}}>Próximo E. Relatório</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    data?.map((value,index) =>{
                        return (
                            <UserPrivateList closeModal={closeModal} auth={auth} value={value} key={index} openModal={openModal} destroyPatientPrivate={destroyPatientPrivate} />
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    );
}

export default UserPrivateCallsModal;
