import {Controller, useForm} from "react-hook-form";
import {Field} from "../../components/Field";
import React, { useState} from "react";
import api from "../../api";
import {debounce} from "lodash";
import { useSelector} from "react-redux";
import {DateTime} from "luxon";

export default function SearchReceiveForCalls({onSubmit, treatment, status}) {
    const {control, handleSubmit,formState: {errors}, } = useForm({
        defaultValues: {
            professional: [],
            patient: [],
            status: 0,
            start_date: DateTime.local().toFormat("yyyy-MM-dd") + " 00:00:00",
            end_date: DateTime.local().toFormat("yyyy-MM-dd") + " 23:59:59"
        },
    });

    const [professionais,setProfessionais] = useState([]);
    const [professionaisLoading,setProfessionaisLoading] = useState(false);
    const [patients,setPatients] = useState([]);
    const [patientsLoading,setPatientsLoading] = useState(false);

    let token = useSelector(state => state.auth.token);

    const handleInputChangeProfessional = debounce(((value, callback) => {
        setProfessionaisLoading(true)
        api.post(`/user/search/type/1?page=1&pageSize=10`, {name: value}, {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }).then((response) => {
            const options = response.data.data.map((result) => ({
                value: result.id,
                label: result.name,
            }));
            setProfessionais(options);
        }).finally(() => setProfessionaisLoading(false));
    }), 1000);

    const handleInputChangePatient = debounce(((value, callback) => {
        setPatientsLoading(true)
        api.post(`/user/search/type/3?page=1&pageSize=10`, {name: value}, {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }).then((response) => {
            const options = response.data.data.map((result) => ({
                value: result.id,
                label: result.name,
            }));
            setPatients(options);
        }).finally(() => setPatientsLoading(false));
    }), 1000);

    let items = [];

    if (status && status.length > 0) {
        items = status.filter((element) => {
            return element.lack !== 2;
        });
    }

    let arrayStatus = [];
    items.forEach((data) => {
        arrayStatus.push({"label": data.description, "value": data.id});
    });

    const treatmentOptions = [];

    if (treatment && treatment.length > 0) {
        treatment.filter(spe => spe.status === 1).forEach(element => {
            treatmentOptions.push({
                label: ((element && element.specialization.profession) ? element.specialization.profession.description : '') + " " + (element.specialization.description ? element.specialization.description : '') + " (" + element.time + " minutos)",
                value: element.id
            })
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Paciente:</label>
                    <Controller
                        rules={{required:true}}
                        name="patient"
                        control={control}
                        render={({field: {onChange, value}}) => <Field
                            type="autocomplete"
                            loading={patientsLoading}
                            options={patients}
                            onInputChange={handleInputChangePatient}

                            onChange={onChange}
                        />}
                    />
                    {errors.patient &&
                        <p
                            style={{color: 'red', marginTop: 10}}
                        >
                            Necessário selecionar o paciente.
                        </p>
                    }
                </div>

                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Profissional:</label>
                    <Controller
                        name="professional"
                        rules={{required:true}}
                        control={control}
                        render={({field: {onChange, value}}) => <Field
                            type="autocomplete"
                            loading={professionaisLoading}
                            options={professionais}
                            onInputChange={handleInputChangeProfessional}

                            onChange={onChange}
                        />}
                    />
                    {errors.professional &&
                        <p
                            style={{color: 'red', marginTop: 10}}
                        >
                            Necessário selecionar o profissional.
                        </p>
                    }
                </div>

                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Tratamento:</label>
                    <Controller
                        name="treatment"
                        control={control}
                        rules={{ required: true }}
                        render={({field: {onChange, value}}) => <Field type="select" options={treatmentOptions} value={value}
                                                                       onChange={onChange}/>}
                    />
                    {errors.treatment && <p style={{color:'red',marginTop:10}}>Necessário preencher o tratamento.</p>}

                </div>

                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Data de Inicio:</label>
                    <Controller
                        name="start_date"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="date" value={value}
                                                                       onChange={onChange}/>}
                    />
                </div>
                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Data de fim:</label>
                    <Controller
                        name="end_date"
                        control={control}
                        rules={{ required: true }}
                        render={({field: {onChange, value}}) => <Field type="date" value={value}
                                                                       onChange={onChange}/>}
                    />
                    {errors.end_date && <p style={{color:'red',marginTop:10}}>Necessário preencher a data de fim.</p>}
                </div>
                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Status:</label>
                    <Controller
                        name="status"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="select-multi" options={arrayStatus} value={value}
                                                                       onChange={onChange}/>}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-10"></div>
                <div className="col-md-2" style={{marginTop: 22}}>
                    <button className="btn btn-success btn-sm btn-block" style={{width: "100%", marginTop: 3}}
                            type="submit">
                        Aplicar
                    </button>
                </div>
            </div>
        </form>
    );
}
