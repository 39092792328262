import React, {useEffect, useState} from "react";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {DateTime} from "luxon";
import ContentLoader from 'react-content-loader'
import {Field} from "../Field";
import {Button} from "../Button";
import {checkPermission, formatDateTOYYYYMMDD} from '../../utils';
import api from "../../api";
import Confirm from "../Confirm";

function FinanceModal({companyService,getCompanyService,responsibles, getResponsible, loading, data, updateFinance, getAutocompletePatient, patientsAutocomplete, patientsLoadingAutocomplete, getTypePayment, storeFinance, typePayments, getFinanceParcelas, parcelasLoading, parcelas, financeParcelasSuccess, auth, address,getAddress, typePaymentsPay}) {

    const [repetir, setRepetir] = useState(false);
    const [installments, setInstallments] = useState(0);
    const [money, setMoney] = useState(0);

    const [loadingCompany, setLoadingCompany] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState({});
    const [user, setUser] = useState(0);
    const [confirmTask, setConfirmTask] = useState(false);
    const [documentSend, setDocumentSend] = useState(0);
    const [documentType, setDocumentType] = useState(0);
    const dispatch = useDispatch();

    const {control, handleSubmit, formState: {errors}, setValue, setError, clearErrors} = useForm({
        defaultValues: {
            user: data.typeModel === 1 ? data.user ? data.user.id : '' : data.company_user ? data.company_user.id : '',
            due_date: data ? data.due_date : null,
            total: data ? data.total : 0,
            type_payment: data && data.type_payment ? data.type_payment.id : null,
            receipt_account: data && data.receipt_account ? data.receipt_account.id : null,
            description: data ? data.description : null,
            type: data.permission === 'pay' ? 2 : 1,
            type_charge: 1,
            type_recurrence: 1,
            status: data && data.status ? data.status.id : null,
            nota: data ? data.nota : null,
            type_model: data ? data.typeModel : null,
            data:data ? data.due_date : null,
            task: data ? data.task : null,
            bank_account_id: data? data.bank_account_id : null,
            payment_date: data ? data.payment_date : null,
            document_type: data ? data.document_type : 0,
            document_send:data? data.document_send : 0,
            document_send_day: data ? data.document_send_day : 0,
            document_service: data ? data.document_service : 0,
            document_description: data ? data.document_description : 0,
            responsible_id: data ? data.responsible_id : 0,
            document_address: data ? data.document_address : 0,
            document_send_option: data ? data.document_send_option : 0,
            calls: data.calls ?? []
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "parcelas"
    });

    useEffect(() => {
        getCompanyService();
        setDocumentSend(data.document_send ?? 0)
        setDocumentType(data.document_type ?? 0)

        if(auth.company.spedy_api_key){
            setCompany(auth.company)
        }else{
            api.get('/company/'+auth.company.id,{
                headers: {
                    'Authorization': 'Bearer ' + auth.token,
                }
            }).then(data => setCompany(data.data))
        }
    }, []);

    useEffect(() => {
        getTypePayment(data.permission === 'pay' ? 2 : 1);
        financeParcelasSuccess([]);
        dispatch({type:'GET_BANK_ACCOUNT'});
        if(data?.user?.id){
            setUser(data?.user?.id)
        }
    }, []);

    useEffect(() => {
        setRepetir(1)
        if(data.typeModel !== 2 && user) {
            getResponsible(user);
            getAddress(user);
        }
    },[user]);

    const responsibleOptions = responsibles?.map(value => ({label:value.responsible?.name,value:value.id}))

    const bankAccounts = useSelector(state => state.companyBankAccount.items);
    const bankAccountsOptions = bankAccounts?.map(value => ({label:value.bank?.bank+" (Agência: "+value.agency+" e Conta: "+value.account+")",value:value.id}))

    const onSubmit = (request,event) => {

        request[event.nativeEvent.submitter.name] = true;
        if (request.parcelas.length > 0 || request.type_charge === 1 || request.type_charge === 2) {
            if (data && data.id) {
                updateFinance(request, data.id)
            } else {
                storeFinance(request);
            }
        } else {
            setError("parcelas", {type: "focus"}, {shouldFocus: true});
        }
    }

    const handleInputChangePatient = (value) => {
        getAutocompletePatient("1,3", 1, 10, value);
    };

    const token = useSelector(state => state.auth.token);

    const handleInputChangeCompany = async (value) => {
        setLoadingCompany(true)
        const response = await api.post('/companies/search', {description: value}, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
        let company = [];

        response.data.forEach((value) => {
            company.push({value: value.id, label: value.description})
        })

        setLoadingCompany(false)
        setCompanies(company)
    };

    let arrayPatients = [];
    if (patientsAutocomplete && patientsAutocomplete.length > 0) {
        patientsAutocomplete.forEach(value => {
            arrayPatients.push({value: value.id, label: value.name})
        });
    }

    const options = [];

    const types = data.permission === 'pay' ? typePaymentsPay : typePayments;

    types?.forEach(element => {
        options.push({label: element.description, value: element.id})
    });

    const documentSendOptions = [
        {
            value:1,
            label:"Antes do vencimento",
        },
        {
            value:2,
            label:"Após o pagamento",
        }
    ]

    const documentSendOptions2 = [
        {
            value:0,
            label:"Não enviar",
        },
        {
            value:1,
            label:"E-mail",
        }
    ]

    if(repetir === 1){
        documentSendOptions.push({
            value:3,
            label:"Agora",
        })
    }

    let arrayCobranca = [
        {
            label: 'Cobrança única',
            value: 1,
            type: 'receive',
            viewCall:true
        },
        {
            label: 'Cobrança recorrente',
            value: 2,
            type: 'receive'
        },
        {
            label: 'Cobrança parcelada',
            value: 3,
            type: 'receive'
        },
        {
            label: 'Conta única',
            value: 1,
            type: 'pay'
        },
        {
            label: 'Conta recorrente',
            value: 2,
            type: 'pay'
        }
    ]

    let arrayCobrancaTipo = [
        {
            label: 'Mensal',
            value: 1
        },
        {
            label: 'Bimestral',
            value: 2
        },
        {
            label: 'Trimestral',
            value: 3
        },
        {
            label: 'Semestral',
            value: 4
        },
        {
            label: 'Anual',
            value: 5
        }
    ];

    if(data.viewCall){
        arrayCobranca = arrayCobranca.filter(value => value.viewCall === true)
    }

    useEffect(() => {
        if(data.viewCall){
            setValue('type_charge',1)
        }
    },[data.viewCall])

    const typeDocument = [];

    if(company.spedy_api_key && data.permission === 'receive'){
        typeDocument.push({
            value:1,
            label:"Nota fiscal",
        })
    }


    const handlerSetDate = (date) => {
        Array.from(Array(installments - 1).keys()).map((value, index) => {
            return setValue('parcelas.' + (index + 1) + '.date', formatDateTOYYYYMMDD(DateTime.fromSQL(date).plus({month: index + 1})))
        })
    }

    useEffect(() => {
        const addressInvoice = address.filter((value) => value.invoice === 1);

        if(addressInvoice.length > 0 && data.viewInvoice){
            setValue('document_address',addressInvoice[0]?.id)
        }
    },[address,data.viewInvoice])

    useEffect(() => {
        const responsiblesInvoice = responsibles.filter((value) => value.invoice === 1);

        if(responsiblesInvoice.length > 0  && data.viewInvoice){
            setValue('responsible_id',responsiblesInvoice[0]?.id)
        }
    },[responsibles,data.viewInvoice])

    const optionsAddress = address?.map(value => ({
        value: value.id,
        label: value.street + ", " + value.number + " - " + value.neighborhood + ", " + value.city + " - " + value.state + ", " + value.zipcode
    }))

    const Parcelas = (() => {
        return (
            <div style={{flexDirection: 'column', padding: 20}}>
                {
                    parcelasLoading === true ?
                        Array.from(Array(installments).keys()).map((value, index) => {
                            return (
                                <div style={{flexDirection: 'row', display: 'flex', flex: 1, width: '100%'}}
                                     key={index}>
                                    <div style={{textAlign: 'left', width: '100%'}}>
                                        <ContentLoader viewBox="0 0 400 60">
                                            <rect x="0" y="0" rx="4" ry="4" width="380" height="40"/>
                                        </ContentLoader>
                                    </div>
                                    <div style={{textAlign: 'left', width: '100%'}}>
                                        <ContentLoader viewBox="0 0 400 60">
                                            <rect x="0" y="0" rx="4" ry="4" width="380" height="40"/>
                                        </ContentLoader>
                                    </div>
                                    <div style={{textAlign: 'right', width: '100%'}}>
                                        <ContentLoader viewBox="0 0 400 60">
                                            <rect x="0" y="0" rx="4" ry="4" width="380" height="40"/>
                                        </ContentLoader>
                                    </div>

                                </div>
                            )
                        })
                        :
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                                <tr>
                                    <th style={{textAlign: 'left'}}>Nº da parcela</th>
                                    <th>Data de vencimento</th>
                                    <th style={{textAlign: 'right'}}>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                parcelas && parcelas.length > 0 ?
                                    fields.map((at, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{textAlign: 'left', display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{flex: 1}}>{index + 1}º Parcela</div>
                                                    <div>
                                                        {(errors.parcelas && errors.parcelas[index]) ?
                                                            <p style={{color: 'red', marginTop: 10}}>Necessário
                                                                preencher a data.</p> : index === 0 ?
                                                                <div className="badge badge-light-primary">Escolha a
                                                                    primeira data</div> : ""}
                                                    </div>
                                                </td>
                                                <td style={{width: 100, padding: 0}}>
                                                    <Controller
                                                        name={`parcelas.${index}.date`}
                                                        control={control}
                                                        rules={{required: true}}
                                                        render={({field: {onChange, value}}) =>
                                                            <Field
                                                                type="date"
                                                                disabledDay={(e) => disabledDay(e)}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    if (index === 0) {
                                                                        handlerSetDate(e)
                                                                    }
                                                                }}
                                                                value={value}
                                                            />
                                                        }
                                                    />
                                                </td>
                                                <td style={{textAlign: 'right'}}>
                                                    {parcelas.length > 0 ? parcelas[index] ? parcelas[index]['formatted'] : <></> : <></>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td></td>
                                    </tr>
                            }
                            </tbody>
                        </table>
                }

            </div>
        )
    })

    const disabledDay = (date) => {
        if (date.getDate().toString().padStart(2, "0") === 29) return true;
        if (date.getDate().toString().padStart(2, "0") === 30) return true;
        if (date.getDate().toString().padStart(2, "0") === 31) return true;
    }

    const today = DateTime.local().startOf('day');

    const isFutureDate = (date) => {
        const selectedDate = DateTime.fromJSDate(date).startOf('day');
        return selectedDate > today;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-3">
                    <label
                        className="fw-bold text-muted"
                        style={{marginTop: 10}}
                    >
                        { data.typeModel === 1 ? data.permission === 'pay' ? "Paciente/Profissional:" : "Paciente/Profissional*:" : data.permission === 'pay' ? "Profissional:" : "Cliente:" }
                    </label>
                    {
                        data.typeModel === 1 ?
                            data && data.user && data.user.id ?
                                <p>{data.user.name}</p>
                                :
                                <>
                                    <Controller
                                        name="user"
                                        control={control}
                                        rules={{required: data.permission === 'pay' ? false : true}}
                                        render={({field: {onChange, value}}) =>
                                            <Field
                                                type="autocomplete"
                                                loading={patientsLoadingAutocomplete}
                                                options={arrayPatients}
                                                onInputChange={handleInputChangePatient}
                                                value={value}
                                                onChange={(ev) => {onChange(ev);setUser(ev)}}
                                            />
                                        }
                                    />
                                    {errors.user &&
                                        <p
                                            style={{color: 'red', marginTop: 10}}
                                        >
                                            Necessário selecionar o {data.permission === 'pay' ? "beneficiário" : "contribuinte"}.
                                        </p>
                                    }
                                </>
                            :
                            <></>
                    }
                    {
                        data.typeModel === 2 ?
                            data && data.company_user && data.company_user.id ?
                                <p>{data.company_user?.description}</p>
                                :
                                <>
                                    <Controller
                                        name="user"
                                        control={control}
                                        rules={{required: data.permission === 'pay' ? false : true}}
                                        render={({field: {onChange, value}}) =>
                                            <Field
                                                type="autocomplete"
                                                loading={data.permission === 'pay' ? patientsLoadingAutocomplete : loadingCompany}
                                                options={data.permission === 'pay' ? arrayPatients : companies}
                                                onInputChange={data.permission === 'pay' ? handleInputChangePatient : handleInputChangeCompany}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        }
                                    />
                                    {errors.user &&
                                        <p style={{color: 'red', marginTop: 10}}>
                                            Necessário selecionar o {data.permission === 'pay' ? "beneficiário:" : "contribuinte*:"}.
                                        </p>
                                    }
                                </>
                            :
                            <></>
                    }
                </div>

                { !data.id && <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Tipo de cobrança:</label>
                    <Controller
                        name="type_charge"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="select"
                                value={value}
                                options={arrayCobranca.filter((cob) => cob.type === data.permission)}
                                onChange={(e) => {
                                     onChange(e);
                                     setRepetir(e)
                                }}
                            />
                        }
                    />
                </div>}
                {
                    repetir === 2 ?
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Tipo de recorrência:</label>
                            <Controller
                                name="type_recurrence"
                                control={control}
                                render={({field: {onChange, value}}) => <Field
                                    type="select"
                                    value={value}
                                    options={arrayCobrancaTipo}
                                    onChange={onChange}
                                />}
                            />
                        </div>
                        :
                        <></>
                }
                {
                    repetir !== 3 ?
                        data && data.status && data.status.id === 3 ?
                            <></>
                            :
                            <div className="col-lg-3">
                                <label className="fw-bold text-muted" style={{marginTop: 10}}>Vencimento*:</label>
                                <Controller
                                    name="due_date"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) =>
                                        <Field
                                            type="date"
                                            disabledDay={disabledDay}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    }
                                />
                                {errors.due_date && <p style={{color: 'red', marginTop: 10}}>Necessário preencher a data de vencimento.</p>}
                            </div>
                        :
                        <></>
                }
                {
                    repetir !== 3 ?
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Valor*:</label>
                            <Controller
                                name="total"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="money"
                                        value={value}
                                        onChange={onChange}
                                    />
                                }
                            />
                        </div>
                        :
                        <></>
                }
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>{data.permission === 'pay' ? "Tipo de conta" : "Forma de pagamento"}*:</label>
                    <Controller
                        name="type_payment"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="select"
                                value={value}
                                options={options}
                                onChange={onChange}
                            />
                        }
                    />
                    {errors.type_payment && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar a forma de pagamento.</p>}
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Conta bancária:</label>
                    <Controller
                        name="bank_account_id"
                        control={control}

                        render={({field: {onChange, value}}) =>
                            <Field
                                type="select"
                                options={bankAccountsOptions}
                                value={value}
                                onChange={onChange}
                            />
                        }
                    />
                </div>
                {
                    data?.payment_date && auth.admin ?
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Data de {data.permission === "pay" ? "pagamento" : "recebimento"}:</label>
                            <Controller
                                name="payment_date"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="date"
                                        disabledDay={isFutureDate}
                                        value={value}
                                        onChange={onChange}
                                    />
                                }
                            />
                            {errors.due_date && <p style={{color: 'red', marginTop: 10}}>Necessário preencher a data de vencimento.</p>}
                        </div>
                        :
                        <></>
                }
                {
                    repetir === 2 ?
                        <div className="col-lg-13" style={{paddingBottom: 20}}>
                            <div
                                className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6"
                                style={{marginTop: 20}}
                            >
							    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
								    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
										<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
										<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
								    </svg>
                                </span>
                                <div className="d-flex flex-stack flex-grow-1 ">
                                    <div className=" fw-semibold">
                                        <h4 className="text-gray-900 fw-bold">Cadastro recorrente de {data.permission === 'pay' ? 'contas' : 'receitas'}</h4>
                                        <div className="fs-6 text-gray-700 ">
                                            O cadastro recorrente é feito automaticamente até o último mês do ano, renovado automaticamente no primeiro mês do próximo ano.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    data.permission !== 'play' && (data && data.id) ?
                        <div className="col-lg-13" style={{paddingTop: 20}}>
                            <Controller
                                name="nota"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="radio"
                                        options={[{value: 1, label: 'Nota solicitada'}, {value: 2, label: 'Nota Anexada'}]}
                                        value={value} onChange={onChange}
                                    />
                                }
                            />
                        </div>
                        :
                        <></>
                }
            </div>
            {
                repetir === 3 ?
                    <div className="row" style={{paddingTop: 50}}>
                        <h3>
                            <div className="badge badge-light-primary">Simulação</div>
                        </h3>
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Quantidade de parcelas:</label>
                            <Controller
                                name="installments"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="number"
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            setInstallments(parseInt(e));
                                        }}
                                    />
                                }
                            />
                            {errors.installments &&
                                <p style={{color: 'red', marginTop: 10}}>Necessário preencher o número de parcelas.</p>}
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Valor total*:</label>
                            <Controller
                                name="total"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="money"
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            setMoney(e);
                                        }}
                                    />
                                }
                            />
                            {errors.total && <p style={{color: 'red', marginTop: 10}}>Necessário preencher o valor.</p>}

                        </div>
                        <div className="col-lg-3" style={{marginTop: 30}}>
                            <button
                                disabled={(parseFloat(money) <= 0 && parseInt(installments) <= 0)}
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                    if (parseFloat(money) > 0 && installments > 0) {
                                        clearErrors('parcelas');
                                        remove()
                                        getFinanceParcelas(money, installments)
                                        Array.from(Array(installments).keys()).map(() => {
                                            return append({date: ""})
                                        })
                                    }
                                }}>
                                Simular
                            </button>
                        </div>
                        <div className="col-md-12">
                            {errors.parcelas && <p style={{color: 'red', marginTop: 10}}> Necessário preencher o número de parcelas e o valor e apertar em simular .</p>}
                        </div>
                        <Parcelas/>
                    </div>
                    :
                    <></>
            }
            <div className="row">
                <div className="col-lg-12">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Descrição:</label>
                    <Controller
                        name="description"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="textArea"
                                rows="3"
                                onChange={onChange}
                                value={value}
                            />
                        }
                    />
                </div>
            </div>
            {
                (checkPermission(auth.permissions,'invoice.store') || checkPermission(auth.permissions,'invoice.update')) && typeDocument.length > 0 && data.permission !== 'play' ?
                    <div className="row">
                        <div className="col-lg-12">
                            <br/>
                            <h4  className="fw-bold text-muted">Documento</h4><hr/>
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Tipo de documento:</label>
                            <Controller
                                name="document_type"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="select"
                                        options={typeDocument}
                                        onChange={(value) => {onChange(value);setDocumentType(value)}}
                                        value={value}
                                    />
                                }
                            />
                        </div>
                        {
                            documentType > 0 ?
                                <div className="col-lg-3">
                                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Quando*:</label>
                                    <Controller
                                        name="document_send"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field: {onChange, value}}) =>
                                            <Field
                                                type="select"
                                                options={documentSendOptions}
                                                onChange={(value) => {onChange(value); setDocumentSend(value)}}
                                                value={value}
                                            />
                                        }
                                    />
                                    {errors.document_send ? <p style={{color:"red",marginTop:5}}>Necessário escolher o momento.</p> : <></>}
                                </div>
                            :
                                <></>
                        }
                        {
                            documentType > 0 ?
                                <div className="col-lg-3">
                                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Enviar por*:</label>
                                    <Controller
                                        name="document_send_option"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field: {onChange, value}}) =>
                                            <Field
                                                type="select"
                                                options={documentSendOptions2}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        }
                                    />
                                    {errors.document_send_option ? <p style={{color:"red",marginTop:5}}>Necessário escolher por onde vai enviar.</p> : <></>}
                                </div>
                                :
                                <></>
                        }
                        {
                            documentSend === 1 ?
                                <div className="col-lg-3">
                                    <label className="fw-bold text-muted" style={{ marginTop: 10 }}>Dias antes vencimento*:</label>
                                    <Controller
                                        name="document_send_day"
                                        control={control}
                                        rules={{required: true}}
                                        render={({ field: { onChange, value } }) =>
                                            <Field
                                                type="select"
                                                options={[
                                                    {
                                                        value:1,
                                                        label:"1 dia",
                                                    },
                                                    {
                                                        value:2,
                                                        label:"2 Dias",
                                                    },
                                                    {
                                                        value:5,
                                                        label:"5 Dias",
                                                    }
                                                ]}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        }
                                    />
                                    {errors.document_send_day ? <p style={{color:"red",marginTop:5}}>Necessário escolher a quantidade de dias antes do vencimento.</p> : <></>}
                                </div>
                                :
                                <></>
                        }
                        {
                            documentType === 1 && data.typeModel !== 2 ?
                                <div className="col-lg-3">
                                    <label className="fw-bold text-muted" style={{ marginTop: 10 }}>Faturar ou receber de*:</label>
                                    <Controller
                                        name="responsible_id"
                                        control={control}
                                        rules={{required: true}}
                                        render={({ field: { onChange, value } }) =>
                                            <Field
                                                type="select"
                                                options={[{value:0,label:"Pelo paciente"},...responsibleOptions]}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        }
                                    />
                                    {errors.responsible_id ? <p style={{color:"red",marginTop:5}}>Necessário escolher de quem recebeu.</p> : <></>}
                                </div>
                                :
                                <></>
                        }
                        {
                            documentType === 1 && data.typeModel ===1 ?
                                <div className="col-lg-3">
                                    <label className="fw-bold text-muted" style={{ marginTop: 10 }}>Endereço*:</label>
                                    <Controller
                                        name="document_address"
                                        control={control}
                                        rules={{required: true}}
                                        render={({ field: { onChange, value } }) =>
                                            <Field
                                                type="select"
                                                options={optionsAddress}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        }
                                    />
                                    {errors.document_address ? <p style={{color:"red",marginTop:5}}>Necessário preencher o endereço.</p> : <></>}
                                </div>
                                :
                                <></>
                        }
                        {
                            documentType === 1 ?
                                <div className="col-lg-3">
                                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Serviço*:</label>
                                    <Controller
                                        name="document_service"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field: {onChange, value}}) =>
                                            <Field
                                                type="select"
                                                options={companyService?.map((value) => ({label:"("+value.service?.service?.code+") "+value.service?.service?.description,value:value.id}))}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        }
                                    />
                                    {errors.document_service ? <p style={{color:"red",marginTop:5}}>Necessário escolher o serviço para gerar a nota fiscal</p> : <></>}
                                </div>
                                :
                                <></>
                        }

                        {
                            documentType === 1 ?
                                <div className="col-lg-12">
                                    <label className="fw-bold text-muted" style={{ marginTop: 10 }}>Descrição para nota:</label>
                                    <Controller
                                        name="document_description"
                                        control={control}
                                        render={({ field: { onChange, value } }) =>
                                            <Field
                                                type="textArea"
                                                rows={3}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        }
                                    />
                                </div>
                                :
                                <></>
                        }
                    </div>
                :
                <></>
            }
            <div className="row" style={{textAlign: 'left', marginTop: 20}}>
                <div className="col-md-9">
                    <Confirm
                        typeNotification="warning"
                        textNotification={"Esta " + (data.permission === 'pay' ? "conta" : "receita") + " faz parte de um cadastro recorrente, isto é, que se repete com alguma frequência previamente determinada. Se você optar por 'Editar também os futuros', tadas as " + (data.permission === 'pay' ? "contas" : "receitas") + " recorrentes serão modificadas."}
                        title={"Como deseja editar a " + (data.permission === 'pay' ? "conta?" : "receita?")}
                        open={confirmTask}
                        buttons={[{
                            name: 'Editar somente esse', type: 'submit', class: 'btn-primary', target: 'one'
                        }, {
                            name: 'Editar também os futuros', type: 'submit', class: 'btn-secondary', target: 'all'
                        }]}
                        closeModal={() => setConfirmTask(false)}
                        onClick={() => {
                        }}
                    />
                </div>
                <div className="col-md-3" style={{textAlign: 'right'}}>
                    {
                        data.task && data.status.id === 1?
                            <button
                                className="btn btn-primary btn-sm"
                                type="button"
                                onClick={() => {
                                    setConfirmTask(true)
                                }}
                            >
                                Salvar
                            </button>
                            :
                            <Button name={"one"} loading={loading} text="Salvar" type="submit"/>
                    }
                </div>
            </div>
        </form>
    );
}

export default FinanceModal
