import React, {useState} from 'react';
import {connect} from 'react-redux';
import Chart from "react-apexcharts";
import Box from "./charts/Box";
import {DateTime} from "luxon";
import {Field} from "../../components/Field";
import Pay from "./charts/Pay";
import Receive from "./charts/Receive";
import {formatNumber} from "../../utils";
import {Controller, useForm} from "react-hook-form";
import PayRadar from "./charts/PayRadar";
import ReceivePie from "./charts/ReceivePie";
import {closeAny, openAny} from "../../store/actions/uiActions";


const Filter = ({setYear, setType, type, year, closeAny, options}) => {


    const {control, handleSubmit} = useForm({
        defaultValues: {
            type: type,
            year: year
        }
    });

    const onSubmit = (request) => {
        setYear(request.year);
        setType(request.type);
        closeAny();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row" style={{marginBottom: 10}}>

                <div className="col-md-2">
                    <Controller
                        name="type"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="select" options={options}
                                                                       onChange={onChange} value={value}/>}
                    />
                    <br/>
                </div>
                <div className="col-md-2">
                    <Controller
                        name="year"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="select" options={[{
                            label: '2022',
                            value: '2022'
                        }, {label: '2023', value: '2023'}, {
                            label: '2024',
                            value: '2024'
                        }, {
                            label: '2025',
                            value: '2025'
                        }]} onChange={onChange} value={value}/>}
                    />

                    <br/>
                </div>
                <div className="col-md-1">
                    <button className='btn btn-success btn-sm' style={{width: '100%'}}>
                        <div style={{width: 18, margin: 'auto'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                    fill="#fff"/>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </form>
    )

}

function Finance({isMobile, openAny, closeAny}) {
    const [year, setYear] = useState(DateTime.local().toFormat('yyyy'));
    const [type, setType] = useState(1);


    const [receive, setReceive] = useState([]);
    const [pay, setPay] = useState([]);

    const options = [
        {label: 'Data de Vencimento', value: 1},
        {label: 'Data de Pagamento', value: 2}
    ]


    let weeks = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ];

    let state = {

        series: [{
            name: 'Receitas',
            data: receive
        }, {
            name: 'Contas',
            data: pay
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            colors: ["rgb(62,116,253)", "rgb(246,229,41)"],
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: weeks,
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        // Definir a formatação do número aqui
                        return "R$ " + formatNumber(value);
                    },
                },
            },
            fill: {
                opacity: 1
            },

        },
    };


    return (
        <>
            {
                isMobile && <div style={{flexDirection: 'row', display: 'flex', padding: "15px 0"}}>
                    <h1 style={{flex: 1}}>{year}</h1>
                    <div>
                        <div style={{cursor: 'pointer', marginRight: 10}} onClick={() => openAny(<Filter
                            setYear={setYear}
                            setType={setType}
                            year={year}
                            type={type}
                            closeAny={closeAny}
                            options={options}
                        />, "Filtro", "lg")}>
                            <span className="svg-icon svg-icon-black ">
                                 <svg width="20" height="24" viewBox="0 0 20 20" fill="#000"
                                      xmlns="http://www.w3.org/2000/svg">
                                     <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                           transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                                     <path
                                         d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                         fill="#000"/>
                                 </svg>
                            </span>
                        </div>

                    </div>
                </div>
            }
            {!isMobile && <Filter setYear={setYear} setType={setType} year={year} type={type} closeAny={closeAny}
                                  options={options}/>}
            <div className="row">
                <div className="col-md-4" style={{marginBottom: 20}}>
                    <div className="card">
                        <Receive year={year} type={type} setReceive={setReceive}/>
                    </div>
                </div>
                <div className="col-md-4" style={{marginBottom: 20}}>
                    <div className="card">
                        <Pay year={year} type={type} setPay={setPay}/>
                    </div>
                </div>
                <div className="col-md-4" style={{marginBottom: 20}}>
                    <div className="card">
                        <Box type={type} year={year}/>
                    </div>
                </div>
                <div className="col-md-12" style={{marginBottom: 20}}>
                    <div className="card">
                        <div className="card-body">
                            <Chart
                                options={state.options}
                                series={state.series}
                                type="bar"
                                height="400"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card" style={{marginBottom: 20}}>
                        <ReceivePie year={year} type={type}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card" style={{marginBottom: 20}}>
                        <PayRadar year={year} type={type}/>
                    </div>
                </div>

            </div>
        </>
    );
}

const mapStateToprops = state => ({
    auth: state.auth,

});

const mapDispatchToprops = dispatch => ({
    openAny: (children, title, size) => dispatch(openAny(children, title, size)),
    closeAny: () => dispatch(closeAny())
});

export default connect(mapStateToprops, mapDispatchToprops)(Finance);