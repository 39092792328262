import React from "react";
import {calcularMoney, checkPermission, formatDateNotHour, getName} from "../../utils";
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import {Avatar} from "../Avatar";
import InvoiceBadge from "./InvoiceBadge";
import {DateTime} from "luxon";
import Button from "./Button";

const Loading = () => {
    return(
        <tr>
            <td style={{textAlign: 'left'}}>
                <ContentLoader viewBox="0 0 380 70">
                    <rect x="80" y="17" rx="4" ry="4" width="60" height="60"/>
                </ContentLoader>
            </td>
            <td style={{textAlign: 'left'}}>
                <ContentLoader viewBox="0 0 380 70">
                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                </ContentLoader>
            </td>
            <td style={{textAlign: 'left'}}>
                <ContentLoader viewBox="0 0 380 35">
                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                </ContentLoader>
            </td>
            <td style={{textAlign: 'left'}}>
                <ContentLoader viewBox="0 0 380 50">
                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                </ContentLoader>
            </td>
            <td style={{textAlign: 'left'}}>
                <ContentLoader viewBox="0 0 380 70">
                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                </ContentLoader>
            </td>
            <td style={{textAlign: 'left'}}>
                <ContentLoader viewBox="0 0 380 70">
                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                </ContentLoader>
            </td>
            <td style={{textAlign: 'left'}}>
                <ContentLoader viewBox="0 0 380 70">
                    <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                </ContentLoader>
            </td>

        </tr>
    )
}

function FinanceList({ value, auth, openModal, destroy, permission, destroyFinanceTask, typeModel, setValue, setConfirmOne, setConfirmTask}) {

    return (
        <tr style={{cursor: 'pointer', backgroundColor: value.new && 'aliceblue'}}>
            <td style={{
                textAlign: 'left',
                borderLeftColor: value.status.color,
                borderLeftWidth: 5,
                paddingLeft: 30
            }}>
                {/*<Field type="checkbox" />*/}
            </td>
            {
                <td style={{textAlign: 'left'}}>
                    {
                        typeModel === 1 ?
                            <>
                                {
                                    value.user && value.user.type !== 1  ?
                                        <Link
                                            style={{textAlign: 'left',display:"flex",gap:5,alignItems: "center"}}
                                            to={"/patient/" + value.user?.id}
                                            className="btn btn-link"
                                        >
                                            <Avatar w="w-25px" h="h-25px" row={value.user}  /> <div>{value.user ? getName(value.user.name) : ''}</div>

                                        </Link>
                                        :
                                        <>{value.user ? value.user.name : ''}</>
                                }
                            </>
                            :
                            <div>
                                {
                                    value.company_user?.email && (value.company_user?.cpf || value.company_user?.cnpj) &&
                                    <span
                                        className="svg-icon svg-icon-success svg-icon-1hx"
                                        style={{marginRight: 10}} title={"Cadastro completo"}
                                    >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                    </span>
                                }
                                {permission === 'pay' ? value.user ? value.user.name : '' : value.company_user ? value.company_user.description : ''}
                            </div>
                    }
                </td>
            }
            <td style={{textAlign: 'left'}}>
                <div style={{display: 'flex',flexWrap: 'nowrap',flexDirection: 'column',alignContent: 'flex-start',alignItems: 'flex-start'}}>


                    {
                        value.type_payment ?
                            <div className="badge badge-light-primary"  style={{height:22,marginBottom:5}}>
                                {value.type_payment.description}
                            </div>
                            :
                            <></>
                    }

                    {
                        value.company_bank_account?.id ?
                            <div style={{display:"flex",flexDirection:"column",gap:5,alignItems: "flex-start",marginBottom:5}}>
                                <div className="badge badge-primary"  style={{height:22}} title={"Agência: "+value.company_bank_account?.agency+ ' Conta: '+value.company_bank_account?.account}>
                                    {value.company_bank_account?.bank?.bank}
                                </div>
                            </div>
                            :
                            <></>
                    }
                    <InvoiceBadge value={value} />
                    { value.responsible?.id && permission === 'receive' ? <div className="badge badge-light-dark" style={{marginTop:5}} >Recebeu de {getName(value.responsible?.responsible.name)}</div> : <></>}
                </div>
            </td>
            <td style={{textAlign: 'left'}}>
                {formatDateNotHour(DateTime.fromSQL(value.due_date))}
            </td>
            <td style={{textAlign: 'left', width: 300}}>{value.description}</td>
            <td style={{textAlign: 'left'}}>
                {
                    calcularMoney(value.total).
                    toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                    })
                }
            </td>
            <td style={{textAlign: 'left', color: value.status.color, fontWeight: 'bold',width:122}}>
                {value.status.description}
                {value.payment_date ? <small style={{fontWeight:400}}><br/>em {formatDateNotHour(DateTime.fromSQL(value.payment_date))}</small> : <></> }
            </td>
            <td>
                {
                    value.task > 0 ?
                        <span
                            className="svg-icon svg-icon-muted"
                            style={{marginLeft: 5}}
                            title={"" + (permission === 'pay' ? "Conta" : "Receita") + " recorrente"}
                        >
							<svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
								<path
                                    d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                                    fill="#00afef"
                                />
								<path
                                    opacity="0.3"
                                    d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                                    fill="#00afef"
                                />
							</svg>
						</span>
                        :
                        <></>
                }
                {
                    value.group > 0 ?
                        <span
                            className="svg-icon svg-icon-muted"
                            style={{marginLeft: 5}}
                            title={"" + (permission === 'pay' ? "Conta" : "Receita") + " parcelada"}
                        >
                           <svg
                               width="24"
                               height="24"
                               viewBox="0 0 24 24"
                               fill="none"
                               xmlns="http://www.w3.org/2000/svg"
                           >
                               <rect
                                   opacity="0.5"
                                   x="6"
                                   y="17.3137"
                                   width="16"
                                   height="2"
                                   rx="1"
                                   transform="rotate(-45 6 17.3137)"
                                   fill="currentColor"
                               />
                               <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
                           </svg>
                        </span>

                        :
                        <></>
                }

                {
                    value.nota === 1 ?
                        <span className="svg-icon svg-icon-muted" style={{marginLeft: 5}} title="Nota solicitada">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                <path
                                    d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
                                    fill="currentColor"/>
                                <path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
                                      fill="currentColor"/>
                            </svg>
                        </span>
                        :
                        <></>
                }
                {
                    typeModel !== 1 && value.company_user?.status === 0 &&
                    <span
                        className="svg-icon svg-icon-danger svg-icon-1hx"
                        style={{marginLeft: 5}}
                        title={"Clínica desativada"}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                            <path d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z" fill="currentColor" />
                        </svg>
                    </span>
                }


                { typeModel === 1 && value.user?.status === 0 &&
                    <span
                        className="svg-icon svg-icon-danger svg-icon-1hx"
                        style={{marginLeft: 5}}
                        title={(value.user?.type === 3 ? "Paciente" : "Profissional")+" inativo"}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                            <path d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z" fill="currentColor" />
                        </svg>
                    </span>
                }

                {
                    value.nota === 2 ?
                        <span className="svg-icon svg-icon-muted" style={{marginLeft: 5}} title="Nota anexada">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                <path opacity="0.3"
                                      d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z"
                                      fill="currentColor"/>
                                <path
                                    d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z"
                                    fill="currentColor"/>
                            </svg>
                        </span>
                        :

                        <></>
                }
                {
                    value && value.documents && value.documents.id ?
                        <span className="svg-icon svg-icon-muted " style={{marginLeft: 5}} title="Arquivos anexados">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3"
                                      d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                                      fill="currentColor"/>
                                <path
                                    d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                                    fill="currentColor"/>
                            </svg>
                        </span>
                        :
                        <></>
                }
            </td>
            <td>
                <Button
                    setValue={setValue}
                    setConfirmOne={setConfirmOne}
                    setConfirmTask={setConfirmTask}
                    auth={auth}
                    permission={permission}
                    typeModel={typeModel}
                    value={value}
                    destroy={destroy}
                    openModal={openModal}
                    destroyFinanceTask={destroyFinanceTask}
                />
            </td>
        </tr>
    )
}

const FinanceDesktop = ({
                            handlerPagos,
                            handlerVencidos,
                            handlerTodos,
                            handlerRecebidos,
                            handlerVencem,
                            handlerAvencer,
                            valueTotal,
                            valueAvencer,
                            valueVencem,
                            valueRecebidos,
                            valueVencidos,
                            valuePagos,
                            title,
                            patient,
                            permission,
                            typeModel,
                            auth,
                            loading,
                            openModal,
                            name,
                            destroyFinanceTask,
                            report,
                            setConfirmOne,
                            setConfirmTask,
                            setValue,
                            search,
                            handleSearch,
                            searchTerm,
                            destroy,
                            openAny,
                            filter,
                            typePayments
}) => {

    return(
        <>
            <div className="card  mb-5 mb-xl-10">
                <div className="card-header cursor-pointer">
                    <div className="card-title align-items-start flex-column">
                        <span className="fw-bolder m-0">{title}</span>
                    </div>
                    <div className="card-toolbar">
                        {checkPermission(auth.permissions, permission + '.store') && <button
                            onClick={() => openModal('finance', {
                                user: {id: patient, name: name},
                                permission: permission,
                                typeModel: typeModel
                            })}
                            className="btn btn-sm btn-light-primary"
                        >
                            Adicionar
                        </button>}
                    </div>
                </div>
                <div className="card-body py-3">
                    <ul className="list-group list-group-horizontal-xl" style={{marginBottom: 0, marginTop: 5}}>
                        {
                            checkPermission(auth.permissions, 'overdue.' + permission + '.list') &&
                            <li
                                onClick={() => handlerVencidos()}
                                className="list-group-item"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    paddingTop: 30,
                                    paddingBottom: 30,
                                    fontSize: 14,
                                    cursor: 'pointer'
                                }}
                            >
                                Vencidos
                                <br/>
                                {
                                    loading === true ?
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                        </ContentLoader>
                                        :
                                        <span style={{
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                            color: '#ff3d32'
                                        }}> {calcularMoney(valueVencidos).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })} </span>
                                }
                            </li>
                        }
                        {checkPermission(auth.permissions, 'expiretoday.' + permission + '.list') &&
                            <li onClick={() => handlerVencem()} className="list-group-item" style={{
                                flex: 1,
                                textAlign: 'center',
                                paddingTop: 30,
                                paddingBottom: 30,
                                fontSize: 14,
                                cursor: 'pointer'
                            }}>
                                Vencem hoje<br/>
                                {
                                    loading === true ?
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                        </ContentLoader>
                                        :
                                        <span style={{
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                            color: '#ffc700'
                                        }}>  {calcularMoney(valueVencem).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })} </span>
                                }
                            </li>}
                        {checkPermission(auth.permissions, 'towin.' + permission + '.list') &&
                            <li onClick={() => handlerAvencer()} className="list-group-item" style={{
                                flex: 1,
                                textAlign: 'center',
                                paddingTop: 30,
                                paddingBottom: 30,
                                fontSize: 14,
                                cursor: 'pointer'
                            }}>
                                A vencer<br/>
                                {
                                    loading === true ?
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                        </ContentLoader>
                                        :
                                        <span style={{
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                            color: '#2687e9'
                                        }}> {calcularMoney(valueAvencer).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })} </span>
                                }
                            </li>}
                        {
                            checkPermission(auth.permissions, permission === "pay" ? 'paid.pay.list' : 'received.receive.list') ?
                                permission === "pay" ?
                                    <li onClick={() => handlerPagos()} className="list-group-item" style={{
                                        flex: 1,
                                        textAlign: 'center',
                                        paddingTop: 30,
                                        paddingBottom: 30,
                                        fontSize: 14,
                                        cursor: 'pointer'
                                    }}>
                                        Pagos<br/>
                                        {
                                            loading === true ?
                                                <ContentLoader viewBox="0 0 380 70">
                                                    <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                                </ContentLoader>
                                                :
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                    color: '#26b547'
                                                }}> {calcularMoney(valuePagos).toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })} </span>
                                        }
                                    </li>
                                    :
                                    <li onClick={() => handlerRecebidos()} className="list-group-item" style={{
                                        flex: 1,
                                        textAlign: 'center',
                                        paddingTop: 30,
                                        paddingBottom: 30,
                                        fontSize: 14,
                                        cursor: 'pointer'
                                    }}>
                                        Recebidos<br/>
                                        {
                                            loading === true ?
                                                <ContentLoader viewBox="0 0 380 70">
                                                    <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                                </ContentLoader>
                                                :
                                                <span style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                    color: '#26b547'
                                                }}> {calcularMoney(valueRecebidos).toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })} </span>
                                        }
                                    </li>
                                :
                                <></>
                        }
                        {
                            checkPermission(auth.permissions, permission === "pay" ? 'total.pay.list' : 'total.receive.list') ?

                                <li onClick={() => handlerTodos()} className="list-group-item" style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    paddingTop: 30,
                                    paddingBottom: 30,
                                    fontSize: 14,
                                    cursor: 'pointer'
                                }}>
                                    Total<br/>
                                    {
                                        loading === true ?
                                            <ContentLoader viewBox="0 0 380 70">
                                                <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                            </ContentLoader>
                                            :
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                    color: '#000'
                                                }}
                                            >
                                                {
                                                    calcularMoney(valueTotal)
                                                        .toLocaleString('pt-br', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })
                                                }
                                            </span>
                                    }
                                </li>
                                :
                                <></>
                        }
                    </ul>

                    <div className="row">
                        <div className="col-md-3">
                            <br/>
                            <input
                                className="form-control"
                                value={searchTerm}
                                onChange={handleSearch}
                                style={{height: 38}}
                                placeholder="Pesquisar"
                            />
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">
                            {
                                !loading && typePayments?.length > 0 && <div
                                style={{float:"right",marginTop:15}}
                                className="btn btn-light btn-sm"
                                onClick={() => openAny(filter, 'Filtro', 'lg')}
                            >
                                <span className="svg-icon svg-icon-black ">
                                    <svg
                                        width="20"
                                        height="24"
                                        viewBox="0 0 20 20"
                                        fill="#000"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="17.0365"
                                            y="15.1223"
                                            width="8.15546"
                                            height="2"
                                            rx="1"
                                            transform="rotate(45 17.0365 15.1223)"
                                            fill="#000"
                                        />
                                        <path
                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                            fill="#000"
                                        />
                                    </svg>
                                </span>
                                Filtro
                            </div>}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table
                            className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                            style={{whiteSpace: 'pre-wrap'}}
                        >
                            <thead>
                                <tr className="fw-bolder text-muted">
                                    <th style={{paddingLeft: 32}}>{/*<Field type="checkbox" />*/}</th>
                                    {
                                        typeModel === 1 ?
                                            <th style={{textAlign: 'left'}}>Paciente/Profissional</th>
                                            :
                                            <th style={{textAlign: 'left'}}>{permission === 'pay' ? "Profissional" : "Clínica"}</th>
                                    }
                                    <th style={{textAlign: 'left'}}>Informações</th>
                                    <th style={{textAlign: 'left'}}>Vencimento</th>
                                    <th style={{textAlign: 'left'}}>Descrição</th>
                                    <th style={{textAlign: 'left'}}>Valor (R$)</th>
                                    <th style={{textAlign: 'left'}}>Situação</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            {
                                loading === true ?
                                    <tbody>
                                        {[1, 2, 3, 4, 5, 6].map((value, index) => <Loading key={index} />)}
                                    </tbody>
                                    :
                                    <tbody>
                                    {
                                        search.length > 0 ?
                                            search.map((value, index) => {
                                                return (
                                                    <FinanceList
                                                        key={index}
                                                        value={value}
                                                        auth={auth}
                                                        permission={permission}
                                                        typeModel={typeModel}
                                                        openModal={openModal}
                                                        destroyFinanceTask={destroyFinanceTask}
                                                        report={report}
                                                        setValue={setValue}
                                                        setConfirmOne={setConfirmOne}
                                                        setConfirmTask={setConfirmTask}
                                                    />
                                                )
                                            })
                                            :
                                                <tr>
                                                    <td colSpan="8">
                                                        <div style={{textAlign: 'center', paddingTop: 30}}>
                                                            Não foi encontrado nenhuma {permission === 'pay' ? "conta" : "receita"}.
                                                        </div>
                                                    </td>
                                                </tr>
                                    }
                                    </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinanceDesktop;


