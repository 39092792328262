import { connect } from 'react-redux';
import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {DateTime} from "luxon";
import { useParams } from 'react-router-dom';
import ContentLoader from "react-content-loader";
import logo1 from '../../assets/img/usemed.png'
import api from "../../api";
import {formatDateStartAndEnd} from "../../utils";

function Uncheck() {
    const [isUser, setIsUser] = useState(false);
    const [data,setData] = useState();
    const [loading,setLoading] = useState(false);
    const [open,setOpen] = useState(false);
    const { hash } = useParams();

    useEffect(async () =>{
        setLoading(true);
        let response = await api.get('/calls/confirm/'+hash, {});

        setData(response.data)
        setLoading(false);
    },[]);

    const status = (status,obs) => {
        let response = api.put('/calls/confirm/'+hash, {status:status,observation:obs});

        toast.promise(response,{
            loading: (status === 2 ? 'Confirmando' : 'Desmarcando')+ ' atendimento.',
            success: 'O atendimento foi '+(status === 2 ? 'confirmado' : 'desmarcado')+' com sucesso.'
        }).then(() => {
            setOpen(false)
            setData({...data,status:status});
        })
    }

    useEffect(async () => {
        if(isUser && !data?.read){
            const date = DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss');

            const response =  await api.post('/calls/confirm/status', {
                whatsapp_message_id:data.whatsapp_message_id,
                read:date
            });

            if(response.status === 200){
                data.read = date
            }
        }
    },[isUser,data])


    useEffect(() => {
        const handleUserInteraction = () => setIsUser(true);

        // Adiciona eventos para detectar interação do usuário
        window.addEventListener("mousemove", handleUserInteraction);
        window.addEventListener("keydown", handleUserInteraction);
        window.addEventListener("scroll", handleUserInteraction);

        // Remove os eventos quando o componente desmontar
        return () => {
            window.removeEventListener("mousemove", handleUserInteraction);
            window.removeEventListener("keydown", handleUserInteraction);
            window.removeEventListener("scroll", handleUserInteraction);
        };
    }, []);

    if(loading){
        return(
            <div className="d-flex flex-column flex-column-fluid" >
                <div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-10 py-lg-20" >
                    <div className="row justify-content-md-center" >
                        <div className="col-md-12" style={{marginTop:10}}>
                            <ContentLoader
                                speed={2}
                                width={300}
                                height={700}
                                viewBox="0 0 400 700"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <circle cx="210" cy="70" r="70" />
                                <circle cx="263" cy="66" r="8" />
                                <rect x="0" y="180" rx="0" ry="0" width="400" height="20" />
                                <rect x="0" y="240" rx="0" ry="0" width="400" height="20" />
                                <rect x="248" y="128" rx="0" ry="0" width="0" height="1" />
                                <rect x="247" y="126" rx="0" ry="0" width="1" height="8" />
                                <rect x="252" y="166" rx="0" ry="0" width="1" height="0" />
                            </ContentLoader>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex flex-column flex-column-fluid" >
            <div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-10 py-lg-20" >
                <a style={{cursor:'pointer'}} className="pt-lg-20 mb-12">
                    <img alt="logo" src={data && data.logo ? data.logo : logo1} className="h-70px" />
                </a>
                <h1 className="fw-bold fs-2qx text-gray-800 mb-7">Confirmar atendimento</h1>
                <div className="fs-3 fw-semibold text-muted mb-10">Gostaríamos de confirmar o agendamento para a sessão de <b>{data && data.call && data.call.treatment  && data.call.company_specialization.specialization ? data.call.company_specialization.specialization.profession.description : ''} {(data && data.call && data.call.company_specialization && data.call.company_specialization.specialization) ?  data.call.company_specialization.specialization.description : ''}</b> com o(a) terapeuta <b>{data && data.call && data.call.professional.name}</b>, <br/> marcada para o paciente <b>{data && data.call && data.call.patient.name}</b>, agendada para <b>{data && formatDateStartAndEnd(DateTime.fromSQL(data.call.start_date),DateTime.fromSQL(data.call.end_date))}</b>.</div>
            </div>
            <div>
                <div className="text-dark order-2 order-md-1" style={{textAlign:'center',paddingBottom:60}}>
                    <span className="text-gray-400 fw-semibold me-1" >Desenvolvido com <span style={{marginTop: -10,fontSize: 30,color: 'red', fontWeight: 'bold'}}>♡</span> por </span>
                    <a rel="noopener noreferrer" href="https://wayfly.com.br" target="_blank" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Wayfly</a>
                </div>
            </div>
        </div>
    );
}


export default connect(null,null)(Uncheck)
