import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import React, {useEffect, useMemo, useState} from "react";
import {DateTime} from "luxon";
import { Button } from "../Button";
import {ddi} from "../../utils";
import Noticie from "../Noticie";

function Form({data,birthDate,setBirthDate,control,errors,isTooYoung,ofAge,auth,userPrivate}){
    const [deceased,setDeceased] = useState(false)
    const maritalStatus = [
        {
            label:'Solteiro(a)',
            value: 1
        },
        {
            label:'Casado(a)',
            value: 2
        },
        {
            label:'Viúvo(a)',
            value: 3
        },
        {
            label:'Divorciado(a)',
            value: 4
        },
        {
            label:'Separado(a)',
            value: 5
        }
    ];

    const additionalFields = []
    additionalFields[195] = [
        {
            type:"select",
            label:"Origem",
            options:[
                {
                    value:'Alunos',
                    label:'Alunos'
                },
                {
                    value:'IPBM',
                    label:'IPBM'
                },
                {
                    value:'PSICO',
                    label:'PSICO'
                },
                {
                    value:'APAE CIDADE',
                    label:'APAE CIDADE'
                }
            ],
            name:'origem',
            size:3
        },
        {
            type:"checkbox",
            label:"Emprega APAE",
            name:'emprega_apae',
            size:3
        },
        {
            type: "select",
            label: "Transferido",
            options: [
                {
                    value:1,
                    label:'Sim'
                },
                {
                    value:0,
                    label:'Não'
                },
            ],
            name:'transfer_apae',
            size:3
        }
    ]

    return (
        <div className="row">
            <div className="col-lg-6">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Nome:</label>
                <Controller
                    name="name"
                    control={control}
                    rules={{required: true}}
                    render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                />
                {errors.name && <p style={{color: 'red', marginTop: 10}}>Necessário preencher o nome.</p>}
            </div>
            <div className="col-lg-3">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Sexo:</label>
                <Controller
                    name="sex"
                    control={control}
                    render={({field: {onChange, value}}) => <Field type="select" options={[{
                        'label': 'Masculino',
                        "value": 1
                    }, {'label': 'Feminino', 'value': 2}]} value={value} onChange={onChange}/>}
                />
            </div>
            <div className="col-lg-3">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Data de Nascimento:</label>
                <Controller
                    name="birth_date"
                    control={control}


                    rules={{
                        required: "A data de nascimento é obrigatória.", // Mensagem para campo vazio
                        validate: (value) => {
                            const today = DateTime.now();
                            const birthDate = DateTime.fromISO(value);

                            if (!birthDate.isValid) {
                                return "Data de nascimento inválida."; // Data mal formatada
                            }

                            if (birthDate > today) {
                                return "A data de nascimento não pode ser maior que hoje."; // Data no futuro
                            }

                            return true; // Validação passou
                        },
                    }}
                    render={({field: {onChange, value}}) => <Field type="dateNative" value={value} onChange={(date) => {
                        onChange(date);
                        isTooYoung(date);
                        setBirthDate(date)
                    }}/>}
                />
                {errors.birth_date &&
                    <p style={{color: 'red', marginTop: 10}}>{errors.birth_date.message}</p>}
            </div>
            <div className="col-lg-5">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Celular:</label>
                <div style={{display: 'flex'}}>
                    <div style={{marginRight: 5, width: 200}}>
                        <Controller
                            name="ddi"
                            control={control}
                            render={({field: {onChange, value}}) => <Field options={ddi} type="select" value={value}
                                                                           onChange={onChange}/>}
                        />
                    </div>
                    <Controller
                        name="cellphone"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="cellphone"
                                value={value}
                                onChange={onChange}
                            />
                        }
                    />
                </div>
            </div>
            <div className="col-lg-3">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>CPF:</label>
                <Controller
                    name="cpf"
                    control={control}
                    render={({field: {onChange, value}}) => <Field type="cpf" value={value} onChange={onChange}/>}
                />
            </div>
            <div className="col-lg-3">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Indicação:</label>
                <Controller
                    name="recommendation"
                    control={control}
                    render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                />
            </div>
            {
                data?.id ?
                    <div className="col-lg-4">
                        <label className="fw-bold text-muted" style={{marginTop: 23}}></label>
                        <Controller
                            name="deceased"
                            control={control}
                            render={({field: {onChange, value}}) =>
                                <Field
                                    type="checkbox"
                                    value={value}
                                    title="Falecido"
                                    onChange={(data) => {
                                        onChange(data);
                                        setDeceased(data);
                                    }}
                                />
                            }
                        />
                    </div>
                    :
                    <></>
            }
            <div className="col-lg-4">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Autorizado o uso da imagem:</label>
                <Controller
                    name="use_image"
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <Field
                            type="radio"
                            value={value}
                            options={[{label: 'Sim', value: 1}, {label: 'Não', value: 2}]} onChange={onChange}
                        />
                    }
                />
            </div>

            {
                birthDate ?
                    ofAge ?
                        <>
                            <div className="col-lg-3">
                                <label className="fw-bold text-muted" style={{marginTop: 10}}>Estado civil:</label>
                                <Controller
                                    name="marital_status"
                                    control={control}
                                    render={({field: {onChange, value}}) =>
                                        <Field
                                            type="select"
                                            value={value}
                                            options={maritalStatus}
                                            onChange={onChange}
                                        />
                                    }
                                />
                            </div>
                            <div className="col-lg-3">
                                <label className="fw-bold text-muted" style={{marginTop: 10}}>Email:</label>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({field: {onChange, value}}) => <Field type="email" value={value}
                                                                                   onChange={onChange}/>}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label className="fw-bold text-muted" style={{marginTop: 10}}>RG:</label>
                                <Controller
                                    name="rg"
                                    control={control}
                                    render={({field: {onChange, value}}) => <Field type="text" value={value}
                                                                                   onChange={onChange}/>}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label className="fw-bold text-muted" style={{marginTop: 10}}>Profissão:</label>
                                <Controller
                                    name="profession"
                                    control={control}
                                    render={({field: {onChange, value}}) => <Field type="text" value={value}
                                                                                   onChange={onChange}/>}
                                />
                            </div>
                        </>
                        :
                        <>

                        </>
                    :
                    <></>
            }
            {
                data?.id > 0 &&  userPrivate.length === 0 ?
                    <div className="col-lg-4">
                        <label className="fw-bold text-muted" style={{marginTop: 10}}>Status:</label>
                        <Controller
                            name="status"
                            control={control}
                            render={({field: {onChange, value}}) =>
                                <Field
                                    type="select"
                                    value={value}
                                    options={[{label: 'Ativo', value: 1}, {label: 'Inativo', value: 0}]} onChange={onChange}
                                />
                            }
                        />
                    </div>
                    :
                    <></>
            }

            {
                false ?
                    <div className="col-md-12" style={{paddingTop:10}}>
                        <Noticie type="danger" title={"Atenção"} description={"Quando o campo <b>Falecido</b> for selecionado, ao salvar, todos os atendimentos do paciente serão interrompidos e o paciente ficará inativo."}/>
                    </div>
                    :
                    <></>
            }
            <div className="col-lg-12">
                <br/>
                <br/>
                <h6>Informações escolares</h6>
                <hr/>
            </div>
            <div className="col-lg-3">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Nome da escola:</label>
                <Controller
                    name="school"
                    control={control}
                    render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                />
            </div>
            <div className="col-lg-12">
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Observações Escola:</label>
                <Controller
                    name="school_observation"
                    control={control}
                    render={({field: {onChange, value}}) => <Field type="textArea" value={value} onChange={onChange}/>}
                />
            </div>

            <div className="col-lg-12">
                <br/>
                <br/>
                <h6>Informaçōes do governo</h6>
                <hr/>
            </div>
            <div
                className="col-lg-3"

            >
                <label className="fw-bold text-muted" style={{marginTop: 10}}>Número do SUS:</label>
                <Controller
                    name="number_sus"
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <Field
                            type="text"
                            value={value}
                            onChange={onChange}
                        />
                    }
                />
            </div>
            <div className="col-lg-3" style={{marginTop: 5}}>
                <br/>
                <Controller
                    name="bf"
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <Field
                            type="checkbox"
                            title="Bolsa Família"
                            value={value}
                            onChange={onChange}
                        />
                }
                />
            </div>
            <div className="col-lg-6" style={{marginTop: 5}}>
                <br/>
                <Controller
                    name="bpc"
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <Field
                            type="checkbox"
                            title="Benefício de Prestação Continuada (BPC)"
                            value={value}
                            onChange={onChange}
                        />
                    }
                />
            </div>

            {
                additionalFields[auth.company.id]?.length > 0 ?
                    <div className="col-lg-12">
                        <br/>
                        <br/>
                        <h6>Informações adicionais</h6>
                        <hr/>
                        <div className="row">
                            {
                                additionalFields[auth.company.id]?.map((field, index) => {
                                    return (
                                        <div key={index} className={"col-md-" + field.size}>
                                            <label className="fw-bold text-muted" style={{marginTop: 10}}>
                                                {field.type !== 'checkbox' ? field.label + ":" : <></>}
                                            </label>
                                            <Controller
                                                name={field.name ?? ''}
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <Field
                                                        type={field.type}
                                                        title={field.label}
                                                        value={value}
                                                        options={field.options}
                                                        onChange={onChange}
                                                    />
                                                }
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    :
                    <></>
            }
        </div>
    )
}


function PatientModal({loading, store, auth, data, update, userPrivate}) {

    const [ofAge, setOfAge] = useState(false);
    const [birthDate, setBirthDate] = useState(null);
    const {control, handleSubmit, formState: {errors}, getValues} = useForm({
        defaultValues: useMemo(() => {
            return data;
        }, [data])
    });

    useEffect(() => {
        if (data && data.birth_date) {
            setBirthDate(true)
            isTooYoung(data.birth_date);
        }

    }, [])

    const onSubmit = (request) => {

        request['company'] = auth.company ? auth.company.id : null;
        request['type'] = 3;

        if(data && data.id){
            update(request,data.id,"patient");
        }else{
            store(request,"patient");
        }
    }

    const isTooYoung = date => {

        if(DateTime.fromFormat(date, 'yyyy-MM-dd').diffNow('years').years < -18){
            setOfAge(true);
        }else{
            setOfAge(false);
        }
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Form userPrivate={userPrivate} auth={auth} birthDate={birthDate}  setBirthDate={setBirthDate} errors={errors} control={control} isTooYoung={isTooYoung} ofAge={ofAge}  getValues={getValues} data={data} />
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-10"></div>
                <div className="col-md-2" style={{textAlign:'right'}}>
                    <Button loading={loading} text="Salvar" type="submit" />
              </div>
            </div>
        </form>
    );
}

export default PatientModal
