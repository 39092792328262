import {put, takeEvery, select} from 'redux-saga/effects';
import {DateTime} from "luxon";
import toast from 'react-hot-toast';
import api from '../../api';
import {
    calendarFail,
    calendarStart,
    calendarSuccess,
    getCalendarDashboardCallsDay,
    calendarDashboardCallsDayLoading,
    removeCallMult,
    calendarForPayLoading,
    calendarForPay,
    calendarAlterHome,
    calendarAlterSchedule,
    calendarDestroySchedule,
    calendarDestroyHome,
    setItem,
    calendarLoadingCreate,
    calendarStoreSchedule,
    calendarStoreHome,
    countPendenciesLoading,
    setCountPendencies,
    countPlanTherapeuticLoading,
    alterPendencies,
    destroyPendencies,
    pendenciesLoading,
    pendenciesSuccess,
    clearPendencies,
    setCountPlanTherapeutic,
    pendenciesTherapeuticSuccess,
    pendenciesTherapeuticLoading,
    alterPendenciesTherapeutic,
    destroyPendenciesTherapeutic,
    countCallsNotSignedLoading,
    setCountCallsNotSigned,
    callsNotSignedLoading,
    callsNotSignedSuccess
} from "../actions/calendarActions";
import {
    closeModal,
    closeModalSecondary,
    openModal,
    openModalSecondary
} from '../actions/uiActions';
import {
    getAnamnese,
    getEvolution,
    getTherapeutic,
    patientAlterAnamnese,
    patientAlterEvolution,
    patientAlterTherapeutic,
    patientDestroyAnamnese,
    patientDestroyEvolution,
    patientDestroyTherapeutic,
    patientStoreAnamnese,
    patientStoreEvolution,
    patientStoreTherapeutic
} from "../actions/patientsActions";
import {timeBlockLoadingRequest} from "../actions/timeBlockActions";
import {messenge} from "./utils";
import {getValue} from "../../utils";

export const getAuthToken = (state) => state.auth.token;
export const getCalendar = (state) => state.calendar;
export const getPatient = (state) => state.patients.item

function* openCall(action) {
    yield put(setItem(action.item, action.item.uuid));

    if(action.typeModal === 'secondary'){
        yield put(openModalSecondary(action.modal, action.item));
    }else{
        yield put(openModal(action.modal, action.item));
    }

    if (!action.item.id || action.item.id === "null") {
        yield createID(action)
    }
}

function* createID(data) {
    try {
        console.log(data)
        yield put(calendarLoadingCreate(true))
        let response = api.post('/call/createID', getValue(data.item), {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        response = yield response;

        if (response.status === 200) {
            yield put(calendarLoadingCreate(false))
            yield put(setItem(response.data, response.data.uuid))
            yield put(calendarAlterHome(response.data));
            yield put(calendarAlterSchedule(response.data));
            yield put(patientAlterEvolution(response.data));
            yield put(patientAlterAnamnese(response.data));
            yield put(patientAlterTherapeutic(response.data));

            switch (data.item.type_schedule.id){
                case 5:
                    yield put(patientStoreTherapeutic(response.data));
                    break;
                default:
                    break;
            }
        }
    } catch (e) {
        console.error(e)
    }
}

function* store(action) {

    try {
        let response = api.post('/call', {...action.data, calendarRef: ''}, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response, {
            loading: 'Cadastrando atendimento...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;

        if (response.status === 200) {
            yield put(closeModal());
            yield put(closeModalSecondary());

            if (response.data.all) {
                let calendar = yield select(getCalendar);
                if (calendar.startDate && calendar.endDate) {
                    yield search({
                        data: {
                            patient: calendar.patient,
                            professional: calendar.professional,
                            dateStart: calendar.startDate,
                            dateEnd: calendar.endDate
                        }
                    });
                }
            } else {
                yield put(calendarStoreSchedule(response.data.atendimento));
                yield put(calendarStoreHome(response.data.atendimento));
                yield put(patientStoreEvolution(response.data.atendimento));
                yield put(patientStoreAnamnese(response.data.atendimento));
                yield put(patientStoreTherapeutic(response.data.atendimento));
            }
        }
    } catch (e) {
        console.error(e);
    }
}

function* update(action) {
    try {

        let response = api.post('/call/update/'+action.data.id, {...action.data, calendarRef: ''}, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
            {
                loading: 'Editando o atendimento...',
                success: (data) => {
                    return data.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;

        if (response.status === 200) {

            if (response.data.all) {
                yield put(closeModal());
                yield put(closeModalSecondary());
                let calendar = yield select(getCalendar);

                if (parseInt(action.data.type) === 1) {
                    if (calendar.startDate && calendar.endDate) {
                        yield search({
                            data: {
                                patient: calendar.patient,
                                professional: calendar.professional,
                                dateStart: calendar.startDate,
                                dateEnd: calendar.endDate
                            }
                        });
                    }
                } else if (parseInt(action.data.type) === 2) {
                    yield getDataPatient(action);
                }else{
                    yield put(clearPendencies());
                    yield getPendencies({professional:calendar.professionalSearchHome});
                }
            } else {
                yield put(setItem(response.data.atendimento, response.data.atendimento.uuid))
                yield put(calendarAlterHome(response.data.atendimento));
                yield put(alterPendencies(response.data.atendimento));
                yield put(alterPendenciesTherapeutic(response.data.atendimento));

                yield put(calendarAlterSchedule(response.data.atendimento));
                yield put(patientAlterEvolution(response.data.atendimento));
                yield put(patientAlterAnamnese(response.data.atendimento));
                yield put(patientAlterTherapeutic(response.data.atendimento));
            }
        }
    } catch (e) {
        console.error(e);
    }
}

function* destroy(action) {

    try {
        let response = api.post('/call/destroy/' + action.data.id, action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
            {
                loading: 'Excluindo atendimento...',
                success: (success) => {
                    return success.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;

        if (response.status === 200) {
            yield put(closeModal());
            yield put(closeModalSecondary());

            if (response.data.all) {
                let calendar = yield select(getCalendar);
                if (parseInt(action.data.type) === 1) {
                    if (calendar.startDate && calendar.endDate) {
                        yield search({
                            data: {
                                patient: calendar.patient,
                                professional: calendar.professional,
                                dateStart: calendar.startDate,
                                dateEnd: calendar.endDate
                            }
                        });
                    }
                } else if (parseInt(action.data.type) === 2) {
                    yield getDataPatient({data:getValue(action.data)});
                }else{
                    yield put(clearPendencies());
                    yield getPendencies({professional:calendar.professionalSearchHome});
                }
            } else {
                yield put(calendarDestroySchedule(response.data.atendimento));

                yield put(calendarDestroyHome(response.data.atendimento));
                yield put(destroyPendencies(response.data.atendimento));
                yield put(destroyPendenciesTherapeutic(response.data.atendimento));

                yield put(patientDestroyEvolution(response.data.atendimento));
                yield put(patientDestroyAnamnese(response.data.atendimento));
                yield put(patientDestroyTherapeutic(response.data.atendimento));
            }
        }
    } catch (e) {
        console.error(e);
    }
}

function* newUpdateStatus(action) {
    try {
        let response = api.put('/call/status-update/' + action.data.id, {
            "status": action.data.status,
            "obs_desmarcar": action.data.obs_desmarcar
        }, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response, {
            loading: 'Atualizando status do atendimento.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        })

        response = yield response
        if (response.status === 200) {
            yield put(closeModal())
            yield put(setItem(response.data.atendimento, response.data.atendimento.uuid))
            if(response.data.atendimento.type_schedule.id === 5){
                yield put(alterPendenciesTherapeutic(response.data.atendimento));
                yield put(patientAlterTherapeutic(response.data.atendimento));
            }else{
                yield put(calendarAlterHome(response.data.atendimento));
                yield put(alterPendencies(response.data.atendimento));
                yield put(calendarAlterSchedule(response.data.atendimento));
                yield put(patientAlterEvolution(response.data.atendimento));
                yield put(patientAlterAnamnese(response.data.atendimento));
            }
        }
    } catch (e) {
        console.error(e)
    }
}

function* reschedule(action) {
    try {
        let response = api.put('/call/reschedule/' + action.data.id, {...action.data, typeAction: ""}, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response, {
            loading: 'Remarcando atendimento.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response
        if (response.status === 200) {
            yield put(closeModal());
            yield put(setItem(response.data.atendimento, response.data.atendimento.uuid))
            yield put(calendarAlterHome(response.data.atendimento));
            yield put(alterPendencies(response.data.atendimento));
            yield put(alterPendenciesTherapeutic(response.data.atendimento));

            yield put(calendarAlterSchedule(response.data.atendimento));
            yield put(patientAlterEvolution(response.data.atendimento));
            yield put(patientAlterAnamnese(response.data.atendimento));

            if (response.data.atendimentoNew) {
                yield put(calendarStoreSchedule(response.data.atendimentoNew));
                yield put(calendarStoreHome(response.data.atendimentoNew));
                yield put(patientStoreEvolution(response.data.atendimentoNew));
                yield put(patientStoreAnamnese(response.data.atendimentoNew));
            }
        }
    } catch (e) {
        console.error(e)
    }
}

function* markOff(action) {
    try {
        let calendar = yield select(getCalendar);

        let response = api.post('/call/markOff', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
            {
                loading: 'Desmarcando os atendimentos em massa.',
                success: 'Os atendimentos foram desmarcados em massa',
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response
        if (response.status === 200) {
            yield put(closeModal());
            if (calendar.startDate && calendar.endDate) {
                yield search({
                    data: {
                        patient: calendar.patient,
                        professional: calendar.professional,
                        dateStart: calendar.startDate,
                        dateEnd: calendar.endDate
                    }
                });
            }
        }
    } catch (e) {
        console.error(e);
    }
}

function* multiplesDestroy(action) {
    try {
        let response = api.post('/call/destroy-multiples', {calls: action.calls}, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response, {
            loading: 'Excluindo atendimentos selecionados.',
            success: 'Os atendimentos selecionados foram excluídos.',
            error: 'Não foi possível excluir os atendimentos selecionados.',
        });

        response = yield response
        if (response.status === 200) {
            yield put(removeCallMult(action.calls));
        }
    } catch (e) {
        console.error(e);
    }
}



function* getDataPatient(action) {
    let patient = yield select(getPatient);

    if (patient.id) {
        switch (action.data.type_schedule) {
            case 1:
            case 4:
            case 2:
                yield put(getEvolution({
                    patient: [patient.id],
                    professional: [],
                    dateStart: DateTime.fromISO(patient.created_at).minus({years: 10}).toFormat("yyyy-MM-dd HH:mm:ss"),
                    dateEnd: DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss"),
                    statusCalendar: [],
                    type_schedule: [1, 2, 4],
                    group: true,
                }))
                break;
            case 3:
                yield put(getAnamnese({
                    patient: [patient.id],
                    professional: [],
                    dateStart: DateTime.fromISO(patient.created_at).minus({years: 10}).toFormat("yyyy-MM-dd HH:mm:ss"),
                    dateEnd: DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss"),
                    statusCalendar: [],
                    type_schedule: [3],
                    group: true
                }))
                break;
            case 5:
                yield put(getTherapeutic({
                    patient: [patient.id],
                    professional: [],
                    dateStart: DateTime.fromISO(patient.created_at).minus({years: 10}).toFormat("yyyy-MM-dd HH:mm:ss"),
                    dateEnd: DateTime.local().plus({years: 1}).toFormat("yyyy-MM-dd HH:mm:ss"),
                    statusCalendar: [],
                    type_schedule: [5],
                    group: true
                }))
                break;
            default:
                break;
        }
    }
}

function* search(action) {
    try {
        yield put(calendarSuccess([]))
        yield put(calendarStart());
        const response = yield  api.post('/call/list', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if (response.status === 200) {
            yield put(calendarSuccess(response.data))
        }
    } catch (e) {
        console.error(e);
        yield put(calendarFail());
    }
}

function* storeBlock(action) {
    try {

        let calendar = yield select(getCalendar);
        yield put(timeBlockLoadingRequest(true));

        const response = yield  api.post('/timeblock', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if (response.status === 200) {
            yield messenge(response.data, 'success')
            yield put(timeBlockLoadingRequest(false));
            yield put(closeModal());
            if (calendar.startDate && calendar.endDate) {

                yield search({
                    data: {
                        patient: calendar.patient,
                        professional: calendar.professional,
                        dateStart: calendar.startDate,
                        dateEnd: calendar.endDate
                    }
                });
            }
        } else {

        }
    } catch (e) {
        console.error(e);
        yield put(timeBlockLoadingRequest(false));
    }
}

function* getCountPendencies(action) {
    try {
        yield put(countPendenciesLoading(true))

        const response = yield  api.post('/call/pendencies/count', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if (response.status === 200) {
            yield put(setCountPendencies(response.data))
        }

    } catch (e) {
        console.error(e);
    }finally {
        yield put(countPendenciesLoading(false))
    }
}

function* getCountPlanTherapeutic(action) {
    try {
        yield put(countPlanTherapeuticLoading(true))

        const response = yield  api.post('/call/plan-therapeutic/count', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if (response.status === 200) {
            yield put(setCountPlanTherapeutic(response.data))
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(countPlanTherapeuticLoading(false))
    }
}


function* getCountCallsNotSigned(action) {
    try {
        yield put(countCallsNotSignedLoading(true))

        const response = yield  api.post('/call/calls-not-signed/count', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if (response.status === 200) {
            yield put(setCountCallsNotSigned(response.data.count))
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(countCallsNotSignedLoading(false))
    }
}

function* getCallsNotSigned(action) {
    try {
        let calendar = yield select(getCalendar);
        yield put(callsNotSignedLoading(true))

        const response = yield  api.post(
            '/call/calls-not-signed/paginate?page=' + calendar.dashboard.callsNotSigned.page + "&pageSize=10",
            {
                professional: action.professional
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                }
            });

        if (response.status === 200) {
            yield put(callsNotSignedSuccess(response.data.data,response.data.last_page));
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(callsNotSignedLoading(false))
    }
}

function* getPendencies(action) {
    try {

        const calendar = yield select(getCalendar)
        yield put(pendenciesLoading(true));

        const response = yield  api.post(
            '/call/pendencies/paginate?page=' + calendar.dashboard.listPendencies.page + "&pageSize=10",
            {
                professional: action.professional
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                }
            });

        if (response.status === 200) {
            yield put(pendenciesSuccess(response.data.data,response.data.last_page));
        }

    } catch (e) {
        console.error(e);
    }finally {
        yield put(pendenciesLoading(false));
    }
}


function* getPendenciesTherapeutic(action) {
    try {
        const calendar = yield select(getCalendar)
        yield put(pendenciesTherapeuticLoading(true));

        const response = yield  api.post(
            '/call/plan-therapeutic/paginate?page=' + calendar.dashboard.planTherapeutic.page + "&pageSize=10",
            {
                professional: action.professional
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                }
            });

        if (response.status === 200) {
            yield put(pendenciesTherapeuticSuccess(response.data.data,response.data.last_page));
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(pendenciesTherapeuticLoading(false));
    }
}

function* dashboardListCallsDay(action) {

    try {
        yield put(calendarDashboardCallsDayLoading(true));

        const response = yield  api.post('/call/list', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if (response.status === 200) {
            yield put(getCalendarDashboardCallsDay(response.data))
        }

    } catch (e) {
        console.error(e);
        yield put(calendarDashboardCallsDayLoading(false));
    }
}

function* getCallsForPay(action) {

    try {
        yield put(calendarForPayLoading(true));

        const response = yield  api.post('/call/list', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if (response.status === 200) {
            yield put(calendarForPay(response.data))
            yield put(calendarForPayLoading(false));
        }

    } catch (e) {
        console.error(e);
        yield put(calendarForPayLoading(false));
    }
}


export function* calendarSaga() {
    yield takeEvery('SEARCH_CALLS', search);
    yield takeEvery('UPDATE_CALL', update);
    yield takeEvery('STORE_CALL', store);
    yield takeEvery('DESTROY_CALL', destroy);
    yield takeEvery('MARKOFF_CALL', markOff);
    yield takeEvery('RESCHEDULE', reschedule);
    yield takeEvery('NEW_UPDATE_STATUS', newUpdateStatus);
    yield takeEvery('DESTROY_CALL_MULTIPLES', multiplesDestroy);
    yield takeEvery('GET_CALLS_START_END', dashboardListCallsDay);
    yield takeEvery('GET_CALLS_FOR_PAY', getCallsForPay);
    yield takeEvery('STORE_TIMEBLOCK', storeBlock);
    yield takeEvery('OPEN_CALL', openCall);
    yield takeEvery('GET_PENDENCIES', getPendencies);
    yield takeEvery('GET_COUNT_PENDENCIES', getCountPendencies);
    yield takeEvery('GET_COUNT_PLAN_THERAPEUTIC', getCountPlanTherapeutic);
    yield takeEvery('GET_PENDENCIES_THERAPEUTIC', getPendenciesTherapeutic);
    yield takeEvery('GET_COUNT_CALLS_NOT_SIGNED', getCountCallsNotSigned);
    yield takeEvery('GET_CALLS_NOT_SIGNED', getCallsNotSigned);
    yield takeEvery('CALENDAR_CREATEID', createID);
}
