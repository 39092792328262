import React, {useEffect, useState} from "react";

const BlockedContent = ({text}) => {
    const [blurredText, setBlurredText] = useState("");

    useEffect(() => {
        setBlurredText(generateRandomText());
    }, []);

    function generateRandomText() {
        const words = [
            "Lorem", "ipsum", "dolor", "sit", "amet", "consectetur",
            "adipiscing", "elit", "sed", "do", "eiusmod", "tempor",
            "incididunt", "ut", "labore", "et", "dolore", "magna",
            "aliqua", "Ut", "enim", "ad", "minim", "veniam"
        ];
        return Array(200).fill(null).map(() => words[Math.floor(Math.random() * words.length)]).join(" ");
    }

    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            {/* Texto bloqueado (dinâmico e embaçado) */}
            <div style={{
                color: "transparent",
                textShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
                filter: "blur(4px)",
                userSelect: "none",
                pointerEvents: "none",
                position: "relative"
            }}>
                {blurredText}
            </div>

            {/* Motivo sobreposto ao conteúdo bloqueado */}
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                color: "white",
                padding: "10px 20px",
                borderRadius: "5px",
                fontWeight: "bold",
                textAlign:"justify"
            }}>
                🔒 {text}
            </div>
        </div>
    );
};


export default BlockedContent;