import {getUuid, updateObject} from '../../utils';

const initialState = {
    item:[],
    itens:[],
    status:[],
    hours:[],
    callForPatient:[],
    callForPatientTotal:0,
    callForPatientLoading:false,
    loading:false,
    loadingRequest:false,
    dashboard:{
        listAtendimentos:{
            loading:true,
            itens:[]
        },
        status:{
            loading:true,
            itens:{}
        },
        listPendencies:{
            loading:false,
            items:[],
            page: 1,
            lastPage: 1,
        },
        countPendencies:{
            loading:true,
            items:[]
        },
        countPlanTherapeutic:{
            loading:true,
            items:[]
        },
        planTherapeutic:{
            loading:false,
            items:[],
            page: 1,
            lastPage: 1
        },
        countCallsNotSigned:{
            loading:true,
            items:[]
        },
        callsNotSigned:{
            loading:false,
            items:[],
            page: 1,
            lastPage: 1
        },
        callsDay:{
            loading:true,
            itens:[]
        }
    },
    professionalSearchHome:null,
    professional:[],
    statusCalendar:[],
    startDate:'',
    endDate:'',
    patient:[],
    callsStatus:[],
    callsStatusLoading:false,
    callForPayLoading:false,
    callForPay:[],
    professionalSelected:{},
    patientSelected:{},
    loadingCreate:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CALENDAR_CLEAR':
            return initialState;
        case 'SET_PROFESSIONAL':
            return updateObject(state, { professional: action.professional });
        case 'SET_PATIENT':
            return updateObject(state, { patient: action.patient });
        case 'SET_START_DATE':
            return updateObject(state, { startDate: action.startDate });
        case 'SET_END_DATE':
            return updateObject(state, { endDate: action.endDate });
        case 'SET_STATUSS':
            return updateObject(state, { statusCalendar: action.status });
        case 'CALENDAR_START':
            return updateObject(state, { loading: true });
        case 'CALENDAR_SUCCESS':
            return updateObject(state, { loading: false, itens: action.itens });
        case 'CALENDAR_FAIL':
            return updateObject(state, { loading: false, itens: [] });
        case 'CALENDAR_START_REQUEST':
            return updateObject(state, { loadingRequest: true });
        case 'CALENDAR_FAIL_REQUEST':
            return updateObject(state, { loadingRequest: false });
        case 'CALENDAR_SET_ID_IN_ARRAY':{
            let array = [...state.itens];

            let index = array.findIndex((ar) => {
                return action.tid === ar.tid;
            });

            if (index > -1) {
                array[index]['aid'] = action.id
                array[index]['id'] = action.id
            }

            return updateObject(state, { itens: array });
        }
        case 'CALENDAR_SET_ITEM_ARRAY': {
            let array = [...state.itens];

            let index = array.findIndex((ar) => {
                return action.data.id === ar.id;
            });

            if (index > -1) {
                array[index] = {...action.data}
            }

            return updateObject(state, { itens: array });
        }
        case 'CALENDAR_SET_ITENS_ARRAY': {
            let array = [...state.itens];

            action.data.forEach((value) => {
                array.push(value)
            })

            array.sort(function(a, b) {
                return new Date(a.start) - new Date(b.start);
            });

            return updateObject(state, { loading: false, itens: array });
        }
        case 'CALENDAR_DESTROY_ITEM_ARRAY': {
            let newArray = [...state.itens];

            action.calls.forEach(value => {

                let index = newArray.findIndex((ar) => {
                    return ar.id === value
                });

                if (index > -1) {
                    newArray.splice(index, 1);
                }
            });

            return updateObject(state, { itens: newArray });
        }
        case 'CALENDAR_DESTROY_MULT': {
            let newArray = [...state.itens];

            action.calls.forEach(value => {
                let index = newArray.findIndex((ar) => {
                    return value.treatment === ar.treatment.id && value.professional === ar.professional.id && value.patient === ar.patient.id && value.start === ar.start
                });
                if (index > -1) {
                    newArray.splice(index, 1);
                }
            });

            return updateObject(state, { itens: newArray });
        }
        case 'CALENDAR_DESTROY_MULT_TASK': {
            let newArray = [...state.itens];

            let array = newArray.filter((at) => {
                return (
                  new Date(action.task.start) > new Date(at.start) && // verifica se a data de início da tarefa existente é posterior à data de início da tarefa a ser excluída
                  at.task !== action.task.task // verifica se é a mesma tarefa
                );
            });

            return updateObject(state, { itens: array });
        }
        case 'CALLS_FOR_PATIENT_LOADING':
            return updateObject(state, { callForPatientLoading: action.status });
        case 'CALLS_FOR_PATIENT_SUCCESS': {
            return updateObject(state, { callForPatient: action.data });
        }
        case 'CALLS_FOR_PATIENT_DELETE': {
            return state;
        }
        case 'CALENDAR_UPDATE_STATUS_ITEM':
            return updateObject(state, {
                item: {
                    ...state.item,
                    status: action.status
                }
            });
        case 'CALENDAR_DASHBOARD_LIST_ATENDIMENTOS':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    listAtendimentos: {
                        loading: false,
                        itens: action.itens
                    }
                }
            });
        case 'CALENDAR_DASHBOARD_LIST_ATENDIMENTOS_START':
            return updateObject(state, {

                dashboard: {
                    ...state.dashboard,
                    listAtendimentos: {
                        loading: true
                    }
                }
            });
        case 'CALENDAR_DASHBOARD_STATUS':
            return updateObject(state, {

                dashboard: {
                    ...state.dashboard,
                    status: {
                        loading: false,
                        itens: action.itens
                    }
                }
            });
        case 'CALENDAR_DASHBOARD_STATUS_START':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    status: {
                        loading: true,
                        itens: {}
                    }
                }
            });

        case 'PROFESSIONAL_SEARCH_HOME':


            return updateObject(state,{
                professionalSearchHome:action.professional
            })
        case 'PENDENCIES_SUCCESS':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    listPendencies: {
                        loading: false,
                        items: [...state.dashboard.listPendencies.items, ...action.items] ,
                        page: state.dashboard.listPendencies.page + 1,
                        lastPage: action.lastPage
                    }
                }
            });
        case 'PENDENCIES_LOADING':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    listPendencies: {
                        ...state.dashboard.listPendencies,
                        loading: action.status
                    }
                }
            });
        case 'ALTER_PENDENCIES':{
            let array = [...state.dashboard.listPendencies.items];

            let index = array.findIndex((ar) => {
                return ar.uuid === action.data.uuid;
            });

            if (index > -1) {
                array[index] = { ...action.data, "uuid": getUuid(action.data) };
            }

            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    listPendencies: {
                        ...state.dashboard.listPendencies,
                        items: array
                    }
                }
            });
        }
        case 'DESTROY_PENDENCIES': {
            let items = [...state.dashboard.listPendencies.items].filter(value => value.uuid !== action.data.uuid);

            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    listPendencies: {
                        ...state.dashboard.listPendencies,
                        items: items
                    }
                }
            });
        }
        case 'CLEAR_PENDENCIES':{
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    listPendencies: initialState.dashboard.listPendencies
                }
            });
        }

        case 'DESTROY_PENDENCIES_THERAPEUTIC': {
            let items = [...state.dashboard.planTherapeutic.items].filter(value => value.uuid !== action.data.uuid);

            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    planTherapeutic: {
                        ...state.dashboard.planTherapeutic,
                        items: items
                    }
                }
            });
        }

        case 'ALTER_PENDENCIES_THERAPEUTIC':{
            let array = [...state.dashboard.planTherapeutic.items];

            let index = array.findIndex((ar) => {

                return ar.uuid === action.data.uuid;
            });

            if (index > -1) {
                array[index] = {...action.data,"uuid":getUuid(action.data)};
            }

            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    planTherapeutic: {
                        ...state.dashboard.planTherapeutic,
                        items: array
                    }
                }
            });
        }

        case 'ALTER_PENDENCIES_CALLS_NOT_SIGNED':{
            let array = [...state.dashboard.callsNotSigned.items];

            let index = array.findIndex((ar) => {
                return ar.uuid === action.data.uuid;
            });

            if (index > -1) {
                array[index] = {...action.data,"uuid":getUuid(action.data)};
            }

            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsNotSigned: {
                        ...state.dashboard.callsNotSigned,
                        items: array
                    }
                }
            });
        }

        case 'PENDENCIES_THERAPEUTIC_LOADING':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    planTherapeutic: {
                        ...state.dashboard.planTherapeutic,
                        loading: action.status
                    }
                }
            });

        case 'PENDENCIES_THERAPEUTIC_SUCCESS':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    planTherapeutic: {
                        loading: false,
                        items: [...state.dashboard.planTherapeutic.items, ...action.items.map(value => ({...value,"uuid":getUuid(value)}))] ,
                        page: state.dashboard.planTherapeutic.page + 1,
                        lastPage: action.lastPage
                    }
                }
            });

        case 'CLEAR_PENDENCIES_THERAPEUTIC':{
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    planTherapeutic: initialState.dashboard.planTherapeutic
                }
            });
        }

        case 'CALENDAR_DASHBOARD_LIST_CALLSDAY':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsDay: {
                        itens: action.itens,
                        loading: false
                    }
                }
            });
        case 'CALENDAR_DASHBOARD_LIST_CALLSDAY_LOADING':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsDay: {
                        loading: action.status
                    }
                }
            });
        case 'SET_COUNT_PENDENCIES':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    countPendencies: {
                        items: action.data,
                        loading: false
                    }
                }
            });
        case 'COUNT_PENDENCIES_LOADING':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    countPendencies: {
                        ...state.dashboard.countPendencies,
                        loading: action.status
                    }
                }
            });
        case 'SET_COUNT_PLAN_THERAPEUTIC':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    countPlanTherapeutic: {
                        items: action.data,
                        loading: false
                    }
                }
            });
        case 'COUNT_PLAN_THERAPEUTIC_LOADING':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    countPlanTherapeutic: {
                        ...state.dashboard.countPlanTherapeutic,
                        loading: action.status
                    }
                }
            });
            case 'SET_COUNT_CALLS_NOT_SIGNED':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    countCallsNotSigned: {
                        items: action.data,
                        loading: false
                    }
                }
            });
        case 'COUNT_CALLS_NOT_SIGNED_LOADING':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    countCallsNotSigned: {
                        ...state.dashboard.countCallsNotSigned,
                        loading: action.status
                    }
                }
            });

        case 'CALLS_NOT_SIGNED_LOADING':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsNotSigned: {
                        ...state.dashboard.callsNotSigned,
                        loading: action.status
                    }
                }
            });

        case 'CALLS_NOT_SIGNED_SUCCESS':
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsNotSigned: {
                        loading: false,
                        items: [...state.dashboard.callsNotSigned.items, ...action.items.map(value => ({...value,"uuid":getUuid(value)}))] ,
                        page: state.dashboard.callsNotSigned.page + 1,
                        lastPage: action.lastPage
                    }
                }
            });

        case 'CLEAR_CALLS_NOT_SIGNED':{
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsNotSigned: initialState.dashboard.callsNotSigned
                }
            });
        }
        case 'CALENDAR_SET_ITEM_ARRAY_CALLSDAY': {
            let array = [...state.dashboard.callsDay.itens];

            let index = array.findIndex((ar) => {

                return action.data.id === ar.id && action.data.task === ar.task;
            });

            if (index > -1) {
                array[index] = action.data;
            }

            return updateObject(state, { dashboard: {
                    ...state.dashboard,
                    callsDay: {
                        itens: array,
                        loading: false
                    }
            } });
        }
        case 'CALENDAR_SET_CALL_STATUS':{
            return updateObject(state, { callsStatus: action.data });
        }
        case 'CALENDAR_SET_CALL_STATUS_LOADING':{
            return updateObject(state, { callsStatusLoading: action.status });
        }
        case 'CALENDAR_FOR_PAY_LOADING':
            return updateObject(state, { callForPayLoading: action.status });
        case 'CALENDAR_FOR_PAY': {
            return updateObject(state, { callForPay: action.data });
        }
        case 'CALENDAR_PROFESSIONAL_SELECTED':
            return updateObject(state, { professionalSelected: action.data });
        case 'CALENDAR_PATIENT_SELECTED':
            return updateObject(state, { patientSelected: action.data });


        case 'CALENDAR_ALTER_HOME':{
            let array = [...state.dashboard.callsDay.itens];

            let index = array.findIndex((ar) => {
                return ar.uuid === action.data.uuid;
            });

            if (index > -1) {
                array[index] = {...action.data,"uuid":getUuid(action.data)};
            }

            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsDay: {
                        itens: array,
                        loading: false
                    }
                }
            });
        }

        case 'CALENDAR_ALTER_SCHEDULE': {
            let array = [...state.itens];

            let index = array.findIndex((ar) => {
                return ar.uuid === action.data.uuid;
            });

            if (index > -1) {
                array[index] = {...action.data,"uuid":getUuid(action.data)};
            }

            return updateObject(state, { itens: array });
        }
        case 'CALENDAR_STORE_HOME': {
            let array = [...state.dashboard.callsDay.itens];

            array.push(action.data);

            array.sort(function(a, b) {
                return new Date(a.start) - new Date(b.start);
            });

            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsDay: {
                        itens: array,
                        loading: false
                    }
                }
            });
        }

        case 'CALENDAR_STORE_SCHEDULE': {
            let array = [...state.itens];
            array.push(action.data);
            array.sort(function(a, b) {
                return new Date(a.start) - new Date(b.start);
            });
            return updateObject(state, { itens: array });
        }

        case 'CALENDAR_DESTROY_SCHEDULE': {

            let items = [...state.itens].filter(value => value.uuid !== action.data.uuid);
            return updateObject(state, { itens: items });
        }

        case 'CALENDAR_DESTROY_HOME': {
            let items = [...state.dashboard.callsDay.itens].filter(value => value.uuid !== action.data.uuid);
            return updateObject(state, {
                dashboard: {
                    ...state.dashboard,
                    callsDay: {
                        itens: items,
                        loading: false
                    }
                }
            });
        }

        case 'CALENDAR_SET_ITEM': {
            let array = { ...state.item }
            const uuid = getUuid(action.item);
            array[action.uuid] = {...action.item,"uuid":uuid};
            array[uuid] = {...action.item,"uuid":uuid};
            return updateObject(state, { loading: false, item: array });
        }

        case 'CALENDAR_DESTROY_ITEM_ID': {
            let array = { ...state.item }

            if (array[action.uuid].hasOwnProperty('id')) {
                array[action.uuid]['id'] = null;
            }
            return updateObject(state, { loading: false, item: array });
        }

        case 'CALENDAR_CREATE':
            return updateObject(state, { loadingCreate: action.status });
        default:
            return state;
    }
}

export default reducer;
